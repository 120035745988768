import React, { useState, useRef, useEffect } from "react";
import Accelarate from "../../assets/Images/proofisionalityimages/Accelarate.gif";
import banner from "../../assets/Images/proofisionalityimages/banner.png";
import side1 from "../../assets/Images/proofisionalityimages/side1.png";
import side2 from "../../assets/Images/proofisionalityimages/side2.png";
import backgroundimage from "../../assets/Images/proofisionalityimages/backgroundimage.png";
import training1 from "../../assets/Images/TandP/trainingimg1.jpg";
import training2 from "../../assets/Images/TandP/trainingimg2.jpg";
import training3 from "../../assets/Images/TandP/tainingimg3.jpg";
import training4 from "../../assets/Images/TandP/trainingimg4.jpg";
import slide1 from "../../assets/Images/Homepageimages/slide7.png";
import slide2 from "../../assets/Images/Homepageimages/slide8.png";
import slide3 from "../../assets/Images/Homepageimages/slide9.png";
import slide4 from "../../assets/Images/Homepageimages/slide6.png";
import slide5 from "../../assets/Images/Homepageimages/slide10.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStar } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import vid2 from "../../assets/Images/ServiceOffer/vid-background2.avif";
import vid3 from "../../assets/Images/ServiceOffer/vid-background3.avif";
import vid4 from "../../assets/Images/ServiceOffer/vid-background4.avif";
import { Link } from "react-router-dom";
import pop1 from "../../assets/Images/proofisionalityimages/pop1.png";
import pop2 from "../../assets/Images/proofisionalityimages/pop2.png";
import pop3 from "../../assets/Images/proofisionalityimages/pop3.png";
import pop4 from "../../assets/Images/proofisionalityimages/pop4.png";
import pop5 from "../../assets/Images/proofisionalityimages/pop5.png";
import pop6 from "../../assets/Images/proofisionalityimages/pop6.png";
import pop7 from "../../assets/Images/proofisionalityimages/pop7.png";
import pop8 from "../../assets/Images/proofisionalityimages/pop8.png";
import pop9 from "../../assets/Images/proofisionalityimages/pop9.png";
import AIAcceleratePdf from "../../assets/Images/proofisionalityimages/AI Accelerate-Gravitonweb 1.pdf";
import TypeIt from "typeit";
import NavigationBar from "../../common/NavigationBar";
import { RxCross2 } from "react-icons/rx";
import Certificate from "../../assets/Images/proofisionalityimages/AIaccelarateCertificate.png";
import Certificate3 from "../../assets/Images/proofisionalityimages/accelaratecertifi.svg";
import { server } from "../../env/env";

const TrainingAndPlacement = () => {

  const WebtechSlideSettings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  const isSmallScreen1 = window.innerWidth <= 768;

  if (isSmallScreen1) {
    WebtechSlideSettings1.slidesToShow = 1;
  }
  // pop

  const [currentModal, setCurrentModal] = useState(null);

  const openModal = (modalNumber) => {
    setCurrentModal(modalNumber);
  };

  const closeModal = () => {
    setCurrentModal(null);
  };

  //banner-section-animatation
  const headingRef = useRef();

  useEffect(() => {
    new TypeIt(headingRef.current, {
      strings: [
        "Free Certificate",
        '<span class="text-[#05BFDB]">AI Accelerate</span> Workshops',
      ],
      speed: 50,
      cursor: true,
      html: true,
      loop: true,
    }).go();
  }, []);


  // form-api
  const [values1, setValue1] = useState({
    name: "",
    email: "",
    phone: "",
    textarea: "",
  });

  function nexthandleChange(event) {
    const newObj = { ...values1, [event.target.name]: event.target.value };
    setValue1(newObj);
  }

  const nexthandleSubmit = (event) => {
    event.preventDefault();

    var formdata = new FormData();
    formdata.append("firstname", values1.name);
    formdata.append("email", values1.email);
    formdata.append("phone", values1.phone);
    formdata.append("message", values1.textarea);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(server.prod + "main/AiAccelerateContactData/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert("success Fully Regsiterd ");
      })
      .catch((error) => console.log("error", error));

    setValue1({
      name: "",
      email: "",
      phone: "",
      textarea: "",
    });
  };


  return (
    <>
      {/* banner start */}

      <div className="relative">
        <img
          src={banner}
          alt="Banner"
          className="w-full  h-[800px] sm:h-[750px] md:h-[700px] lg:h-[700px] xl:h-[100vh] object-cover"
        />

        <div className="absolute inset-0 grid grid-cols-1 md:grid-cols-2  mx-5 md:mx-10 lg:mx-20">
          <NavigationBar />
          <div className="text-white mt-32 xs:mt-28 sm:mt-28 md:mt-0 md:flex md:flex-col md:justify-center md:h-full ">
            <img
              src={Accelarate}
              alt="Banner"
              className="w-3/5 sm:w-2/4 md:w-[70%] lg:w-[60%] h-auto rounded-full transition-transform transition-shadow duration-300 ease-in-out hover:translate-x-2 hover:shadow-md mt-[-28px]"
              mx-auto
            />
          </div>

          <div className="text-white md:flex md:flex-col md:justify-center md:h-full">
            <div>
              <h1
                className="text-2xl md:text-3xl lg:text-5xl font-bold"
                ref={headingRef}
              ></h1>
            </div>

            <p className=" font-bold text-lg mt-5">
              Our AI Accelerator program is a transformative learning experience
              that equips participantswith the Knowledge expertise required to
              trive in the AI driven landscape
            </p>

            <div className="mt-8">
              <a
                href="https://www.instamojo.com/@gravitonwebit/l866e24c28cea4162bc083691a02957a8/"
                rel="im-checkout"
                data-text="Pay"
                style={{
                  background:
                    "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
                  color: "#ffffff",
                  width: "150px",
                  borderRadius: "4px",
                  textAlign: "center",
                  textDecoration: "none",
                  padding: "10px 40px",
                }}
              >
                GET OFFER
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* banner end */}

      {/*FIRST SECTION START */}

      <div className="overflow-hidden">
        <div className="bg-[#00DEFF26] relative grid grid-cols-1 lg:grid-cols-1 min-h-screen h-[827px]  ">
          <div className="mt-10">
            <img
              src={side1}
              alt="Image"
              className="w-[350px] h-[300px] absolute top-0 left-0 mb-3 lg:mb-0"
            />

            <div className="items-center justify-center">
              <h1 className="text-7xl font-bold text-center items-center mt-[120px]  ">
                SERVICES
              </h1>
            </div>

            <h3 className=" text-3xl font-bold mt-5 text-center">
              HERE WHAT YOU WILL GET
            </h3>

            <div className=" flex justify-center  mt-10">
              <button
                onClick={() => openModal(1)}
                className=" md:w-1/4  w-3/4 mt-7  mx-auto items-center bg-[#05BFDB] text-white px-2 py-2 rounded  hover:scale-95 transition text-lg border border-[#0A4D6880] border-[3px]"
              >
                Free Course Certificate
              </button>

              {currentModal === 1 && (
                <div className="fixed inset-0 flex items-center justify-center z-50 ">
                  <div
                    className="absolute inset-0 bg-black bg-opacity-25 backdrop-blur-sm"
                    onClick={closeModal}
                  >
                    <span
                      onClick={closeModal}
                      className="absolute top-0 right-0 p-1 cursor-pointer"
                    >
                      &times;
                    </span>
                  </div>

                  <div className="rounded w-[100%] sm:w-[50%] md:w-[50%]  lg:w-[50%]  h-auto  relative   grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 ">
                    <div className="bg-[#D2F9FF] h-auto ">
                      <button
                        className="text-black px-3 py-1 rounded-md mt-2 absolute top-0 right-0"
                        onClick={closeModal}
                      >
                        <RxCross2 className="h-6 w-6" />
                      </button>

                      <div className="container-fluid h-auto pt-3">
                        {" "}
                        <h1 className="text-center font-bold text-2xl text-[#0A4D68]">
                          Free Course Certificate
                        </h1>
                        <hr className=" border-t-2 border-gray-500 w-full mt-2" />
                        <div className="flex">
                          <div className="w-1/2">
                            <img
                              src={pop1}
                              alt="Image"
                              className="w-[100%] h-[250px] object-contain"
                            />
                          </div>

                          <div className="w-1/2 mt-5 font-poppins font-normal  text-left">
                            <h1 className="font-bold text-left text-xl ">
                              Professional Recognition
                            </h1>

                            <p className="text-[#717171] mt-2  text-left">
                              Our certificates are designed to add a
                              professional touch to your accomplishments, making
                              them valuable assets for your resume and career
                            </p>
                          </div>
                        </div>
                        <hr className="  border-t-2 border-gray-500 w-1/2 mx-auto" />
                        <div className="flex px-8 mt-5">
                          <div className="w-1/2 mt-2 font-poppins text-29px font-normal leading-44px tracking-0 text-left">
                            <h1 className="font-bold text-left  text-xl">
                              Skill Validation
                            </h1>

                            <p className="text-[#717171] mt-2 text-left">
                              Whether you've completed a course, attended a
                              workshop, or participated in an event, our
                              certificates serve as a validation of your skills
                              and knowledge.
                            </p>
                          </div>

                          <div className="w-1/2">
                            <img
                              src={pop2}
                              alt="Image"
                              className="w-[100%] h-[200px] object-contain"
                            />
                          </div>
                        </div>
                        <div className="flex justify-center w-[100%] px-10  py-[39px] h-30 ">
                          <button
                            style={{
                              background:
                                "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",

                              color: "#ffffff",

                              borderRadius: "4px",

                              textAlign: "center",

                              textDecoration: "none",

                              display: "inline-block",

                              padding: "10px 0",

                              fontFamily: "Montserrat",

                              marginTop: "-37px",
                            }}
                            className="text-white w-full h-[70px]  text-xl rounded hover:bg-blue-600 transition rounded transition-transform transition-shadow duration-300 ease-in-out hover:translate-x-2 hover:shadow-md mt-4  "
                            onClick={() => {}}
                          >
                            Click here to download Syllabus
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex justify-center">
              <button
                onClick={() => openModal(2)}
                className="md:w-1/4  w-3/4 mx-auto text-center mt-7 bg-[#05BFDB] text-white px-2 py-2 rounded hover:scale-95 transition text-lg border border-[#0A4D6880] border-[3px] "
              >
                Path of Frontend Development
              </button>

              {currentModal === 2 && (
                <div className="fixed inset-0  flex items-center justify-center z-50">
                  <div
                    className="absolute inset-0 bg-black bg-opacity-25 backdrop-blur-sm"
                    onClick={closeModal}
                  >
                    <span
                      onClick={closeModal}
                      className="absolute top-0 right-0 p-1 cursor-pointer"
                    >
                      &times;
                    </span>
                  </div>

                  <div className="rounded w-[100%] sm:w-[50%] md:w-[50%]  lg:w-[50%] max-h-[100vh]   h-auto  relative   grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 ">
                    <div className="bg-[#D2F9FF] h-auto">
                      <button
                        className="text-black px-3 py-1 rounded-md mt-2 absolute top-0 right-0"
                        onClick={closeModal}
                      >
                        <RxCross2 className="h-6 w-6" />
                      </button>

                      <div className="container-fluid h-auto pr-3 pt-3">
                        {" "}
                        <h1 className="text-center font-bold text-2xl text-[#0A4D68]">
                          Path of FrontEnd Development
                        </h1>
                        <hr className=" border-t-2 border-gray-500 w-full mt-5" />
                        <div className="flex">
                          <div className="w-1/2">
                            <img
                              src={pop4}
                              alt="Image"
                              className="w-[100%] h-[180px] object-contain"
                            />
                          </div>

                          <div className="w-1/2 mt-5 font-poppins text-29px font-normal leading-44px tracking-0 text-left">
                            <h1 className="font-bold text-left text-xl ">
                              In-Demand Skill
                            </h1>

                            <p className="text-[#717171] mt-2  text-left">
                              Frontend developers are in high demand across
                              industries as businesses strive to provide
                              exceptional user experiences.
                            </p>
                          </div>
                        </div>
                        <hr className=" mt-3 border-t-2 border-gray-500 w-1/2 mx-auto" />
                        <div className="flex px-8 mt-5">
                          <div className="w-1/2 mt-5 font-poppins text-29px font-normal leading-44px tracking-0 text-left">
                            <h1 className="font-bold text-left  text-xl">
                              Versatile Career Options
                            </h1>

                            <p className="text-[#717171]  text-left">
                              Frontend developers can work in various sectors,
                              including web development agencies, e-commerce,
                              healthcare, finance, and more.
                            </p>
                          </div>

                          <div className="w-1/2">
                            <img
                              src={pop3}
                              alt="Image"
                              className="w-[100%] h-[200px] object-contain "
                            />
                          </div>
                        </div>
                        <div className="flex justify-center w-[100%] px-10  py-[39px] h-30 ">
                          <button
                            style={{
                              background:
                                "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",

                              color: "#ffffff",

                              borderRadius: "4px",

                              textAlign: "center",

                              textDecoration: "none",

                              display: "inline-block",

                              padding: "10px 0",

                              fontFamily: "Montserrat",

                              marginTop: "-37px",
                            }}
                            className="text-white w-full h-[70px]  text-xl rounded hover:bg-blue-600 transition rounded transition-transform transition-shadow duration-300 ease-in-out hover:translate-x-2 hover:shadow-md mt-4  "
                            onClick={() => {}}
                          >
                            Click here to download Syllabus
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex justify-center">
              <button
                onClick={() => openModal(3)}
                className="md:w-1/4  w-3/4 mt-7 mx-auto text-center bg-[#05BFDB] text-white px-2 py-2 rounded hover:scale-95 transition text-lg border border-[#0A4D6880] border-[3px]"
              >
                Professional Training
              </button>

              {currentModal === 3 && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                  <div
                    className="absolute inset-0 bg-black bg-opacity-25 backdrop-blur-sm"
                    onClick={closeModal}
                  >
                    <span
                      onClick={closeModal}
                      className="absolute top-0 right-0 p-1 cursor-pointer"
                    >
                      &times;
                    </span>
                  </div>

                  <div className="rounded w-[100%] sm:w-[50%] md:w-[50%]  lg:w-[50%] max-h-[100vh]   h-auto  relative   grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 ">
                    <div className="bg-[#D2F9FF] h-auto">
                      <button
                        className="text-black px-3 py-1 rounded-md mt-2 absolute top-0 right-0"
                        onClick={closeModal}
                      >
                        <RxCross2 className="h-6 w-6" />
                      </button>

                      <div className="container-fluid h-auto pt-2 pb-3 pr-4">
                        {" "}
                        <h1 className="text-center font-bold text-2xl text-[#0A4D68] py-1">
                          Professional Training
                        </h1>
                        <hr className=" border-t-2 border-gray-500 w-full mt-5" />
                        <div className="flex">
                          <div className="w-1/2">
                            <img
                              src={pop6}
                              alt="Image"
                              className="w-[90%] h-[170px] object-contain transition-transform transform hover:scale-105"
                            />
                          </div>

                          <div className="w-1/2 mt-8 font-poppins  text-left ">
                            <h1 className="font-bold text-left text-xl">
                              Stay Relevant
                            </h1>

                            <p className="text-[#717171] text-left mt-1">
                              Industries evolve, and new technologies emerge.
                              Professional training ensures that you stay
                              up-to-date with the latest trends and advancements
                              in your field.
                            </p>
                          </div>
                        </div>
                        <hr className=" border-t-2 border-gray-500 w-1/2 mx-auto" />
                        <div className="flex px-10 ">
                          <div className="w-1/2 mt-8 font-poppins text-29px font-normal leading-44px tracking-0 text-left">
                            <h1 className="font-bold text-left  text-xl">
                              Enhance Expertise
                            </h1>

                            <p className="text-[#717171] text-left mt-1">
                              Whether you're looking to deepen your knowledge in
                              your current role or transition into a new career,
                              professional training can help you acquire the
                              expertise you need.
                            </p>
                          </div>

                          <div className="w-1/2">
                            <img
                              src={pop5}
                              alt="Image"
                              className="w-[100%] h-[180px] object-contain"
                            />
                          </div>
                        </div>
                        <hr className=" border-t-2 border-gray-500 w-1/2 mx-auto" />
                        <div className="flex px-10 ">
                          <div className="w-1/2">
                            <img
                              src={pop7}
                              alt="Image"
                              className="w-[100%] h-[130px] object-contain "
                            />
                          </div>

                          <div className="w-1/2 mt-8 font-poppins text-29px font-normal leading-44px tracking-0 text-left">
                            <h1 className="font-bold text-left  text-xl">
                              Career Advancement
                            </h1>

                            <p className="text-[#717171] text-left mt-1 ">
                              Professional certifications and training can open
                              doors to new opportunities and higher-paying
                              positions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex justify-center">
              <button
                onClick={() => openModal(4)}
                className="md:w-1/4  w-3/4 mt-7 mx-auto text-center bg-[#05BFDB] text-white px-2 py-2 rounded hover:scale-95 transition text-lg border border-[#0A4D6880] border-[3px] "
              >
                AI-Powered Job Placement Assistance
              </button>

              {currentModal === 4 && (
                <div className="fixed inset-0 flex items-center justify-center z-50 ">
                  <div
                    className="absolute inset-0 bg-black bg-opacity-25 backdrop-blur-sm"
                    onClick={closeModal}
                  >
                    <span
                      onClick={closeModal}
                      className="absolute top-0 right-0 p-1 cursor-pointer"
                    >
                      &times;
                    </span>
                  </div>

                  <div className="rounded w-[100%] sm:w-[50%] md:w-[50%]  lg:w-[50%] max-h-[100vh]   h-auto  relative   grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 ">
                    <div className="bg-[#D2F9FF] h-auto">
                      <button
                        className="text-black px-3 py-1 rounded-md mt-2 absolute top-0 right-0"
                        onClick={closeModal}
                      >
                        <RxCross2 className="h-6 w-6" />
                      </button>

                      <div className="container-fluid h-auto pt-3">
                        {" "}
                        <h1 className="text-center font-bold text-2xl text-[#0A4D68] py-1  ">
                          AI - Powered Job Placement Assistance
                        </h1>
                        <hr className=" border-t-2 border-gray-500 w-full mt-5" />
                        <div className="flex px-10 sm:w-[100%] ">
                          <div className="w-1/2">
                            <img
                              src={pop9}
                              alt="Image"
                              className="w-[100%] h-[200px] object-contain "
                            />
                          </div>

                          <div className="w-1/2 mt-8 font-poppins text-29px font-normal leading-44px tracking-0 text-left">
                            <h1 className="font-bold text-left text-xl ">
                              Real-time Market Insights
                            </h1>

                            <p className="text-[#717171] mt-2 text-left">
                              Stay updated with the latest industry trends and
                              job openings in your field, ensuring you never
                              miss a great opportunity
                            </p>
                          </div>
                        </div>
                        <hr className=" mt-3 border-t-2 border-gray-500 w-1/2 mx-auto" />
                        <div className="flex px-10 mt-5">
                          <div className="w-1/2 mt-8 font-poppins text-29px font-normal leading-44px tracking-0 text-left">
                            <h1 className="font-bold text-left  text-xl">
                              Efficiency
                            </h1>

                            <p className="text-[#717171]  text-left">
                              Save time and effort by allowing AI to handle the
                              initial job search, allowing you to focus on
                              preparing for interviews and advancing your
                              career.
                            </p>
                          </div>

                          <div className="w-1/2">
                            <img
                              src={pop8}
                              alt="Image"
                              className="w-[100%] h-[200px] object-contain "
                            />
                          </div>
                        </div>
                        <div className="flex justify-center w-[100%] px-10  py-[39px] h-30 ">
                          <button
                            style={{
                              background:
                                "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",

                              color: "#ffffff",

                              borderRadius: "4px",

                              textAlign: "center",

                              textDecoration: "none",

                              display: "inline-block",

                              padding: "10px 0",

                              fontFamily: "Montserrat",

                              marginTop: "-27px",
                            }}
                            className="text-white w-full h-[70px] text-xl rounded hover:bg-blue-600 transition rounded transition-transform transition-shadow duration-300 ease-in-out hover:translate-x-2 hover:shadow-md mt-4"
                            onClick={() => {}}
                          >
                            Click here to Access Certificates
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <img
              src={side2}
              alt="Image"
              className=" md:inline-block  mb-1 lg:mb-0 absolute  mt-[-85px]  right-0 w-[250px] h-[350px]"
            />
          </div>
        </div>
      </div>

      {/* FIRST SECTION END */}

      {/*Second section start  */}
      <div className="text-center mt-20 text-3xl font-bold text-[#262566]">
        <h1>Learn AI , Get certified , Secure your Future!!</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 mt-20 mx-10 md:mx-20 lg:mx-32 gap-10 ">
        <div className="border-2 flex p-8 ">
          <div>
            <img src={training1} alt="" />
          </div>

          <div>
            <h1 className="text-xl font-semibold text-[#14b1bb]">
              COMPREHENSIVE FRONTEND TRAINING
            </h1>
            <p className="mt-4 ">
              Masters the fundamentals of Frontend development including HTML,
              CSS, JS and More
            </p>
          </div>
        </div>
        <div className="border-2 flex p-8 ">
          <div>
            <img src={training2} alt="" />
          </div>

          <div>
            <h1 className="text-xl font-semibold text-[#14b1bb]">
              AI-POWERED LEARNING
            </h1>
            <p className="mt-4 ">
              Harness the potential of AI to enhance your skills and stay ahead
              of the curve
            </p>
          </div>
        </div>
        <div className="border-2 flex p-8 ">
          <div>
            <img src={training3} alt="" className="pr-5" />
          </div>

          <div>
            <h1 className="text-xl font-semibold text-[#14b1bb]">
              HANDS-ON PROJECTS
            </h1>
            <p className="mt-4 ">
              Apply your knowledge to real-world projects, gaining practical
              experience along the way
            </p>
          </div>
        </div>
        <div className="border-2 flex p-8 ">
          <div>
            <img src={training4} alt="" />
          </div>

          <div>
            <h1 className="text-xl font-semibold text-[#14b1bb]">
              EXPERT GUIDANCE:
            </h1>
            <p className="mt-4 ">
              Learn from industry experts who provide insights and mentorship
              throughout your journey.
            </p>
          </div>
        </div>
      </div>

      <div className="hidden  md:block mt-5 pointer-events-none ">
        <img src={Certificate3} className="w-[100%] h-auto"></img>
      </div>

      <div className="block md:hidden mt-8 ">
        <img src={Certificate}  className="w-[100%] h-auto"></img>
      </div>
      {/* Second section end */}

      {/* Third section start */}
      <div
        className="bg-gradient-to-b from-[#0A4D68] to-[#0082B7] mx-auto p-8
          max-w-screen-lg rounded-[8px] mt-8 "
      >
        <div className="text-center">
          <p className="text-xl md:text-2xl font-semibold text-white">
            Total: <s>₹7,999/-</s>
          </p>

          <p className="text-[#00FFCA] text-5xl font-semibold mt-3">
            Now: ₹299 /-
          </p>

          <p className=" text-white text-xl md:text-lg  mt-4">
            Limited Time Offer
          </p>
        </div>
      </div>

      <div
        className="flex flex-col items-center justify-center mt-10"
        style={{
          backgroundImage: `url(${backgroundimage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "626px",
        }}
      >
        <div className="bg-white p-8 rounded-lg shadow-lg w-full md:w-[500px] font-montserrat">
          <form className="space-y-4">
            <div className="bg-[#0A4D68] text-white p-10 mb-5">
              <h2 className="text-2xl font-semibold text-center">
                Start your journey today
              </h2>

              <p className="text-sm text-center">Offer expiring soon!!</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter Your Name"
                className="w-full border p-3 rounded-lg"
                value={values1.name}
                onChange={nexthandleChange}
              />

              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter Your Email"
                className="w-full border p-3 rounded-lg"
                value={values1.email}
                onChange={nexthandleChange}
              />

              <input
                type="text"
                id="pnum"
                name="phone"
                placeholder="Enter Your Number"
                className="w-full border p-3 rounded-lg"
                value={values1.phone}
                onChange={nexthandleChange}
              />

              <textarea
                name="textarea"
                placeholder="Enter Your Message..."
                className="border p-2 rounded-md w-full"
                rows={4}
                value={values1.textarea}
                onChange={nexthandleChange}
              />
            </div>

            <div className="flex items-center justify-center">
              {/* <a
                href="https://www.instamojo.com/@gravitonwebit/l866e24c28cea4162bc083691a02957a8/"
                rel="im-checkout"
                data-text="Pay"
                style={{
                  background:
                    "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",

                  color: "#ffffff",
                  width: "150px",
                  borderRadius: "4px",
                  textAlign: "center",
                  textDecoration: "none",
                  padding: "10px 0",
                }}
              >
                GET OFFER
              </a> */}
              <button onClick={nexthandleSubmit} style={{
                  background:
                    "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",

                  color: "#ffffff",
                  width: "150px",
                  borderRadius: "4px",
                  textAlign: "center",
                  textDecoration: "none",
                  padding: "10px 0",
                }}>Get OFFER</button>
            </div>
          </form>
        </div>
      </div>
      {/* third section end */}

      {/* Slider-section-start */}
      <div className="mt-20">
        <h2 className="text-4xl font-bold text-[#262566]  text-center">
          Hear It From Our Students
        </h2>
        <hr class="my-3 mx-auto w-40 border-t-4 border-[#05BFDB]"></hr>
        <p className="mx-5 md:mx-0 md:text-center mt-5 font-medium">
          Graviton Technologies Placement Reviews Videos will help you to
          understand our Placement Support <br />
          in Various Technology like Cloud, Programming, Data Science and More.
        </p>
      </div>

      <div className="mt-20 mx-10 md:mx-20 lg:mx-30">
        <Slider {...WebtechSlideSettings1}>
        <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide1} className="rounded-[50%]" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Palak Sharma
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
              Promotion via Workshop!!
              </h1>
              <p className="text-[#64666C] mt-3">
              They provide quality of service based on customer needs.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide2} className="rounded-[50%]" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Tarang Tripathi
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
                I could negotiate 40% salary hike
              </h1>
              <p className="text-[#64666C] mt-3">
              I recently had the opportunity to work with this company.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide3} className="rounded-[50%]" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Kunal Singh
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
                Literally a timesaver workshop!!
              </h1>
              <p className="text-[#64666C] mt-3 ">
              They are very sharp and have a high quality team members.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide4} className="rounded-[50%]" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Anshika dubey
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
              Colleague's Unexpected Recognition
              </h1>
              <p className="text-[#64666C] mt-3">
              Good company and quality products delivery on times.Nice
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide5} className="rounded-[50%] w-[80px] h-auto" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Ayush Jain
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
               Secured Exciting Placement
              </h1>
              <p className="text-[#64666C] mt-3">
              Placed in Programmics Technology through Graviton.
              </p>
            </div>
          </div>
        </Slider>
      </div>
      {/* Slider-section-end */}

      {/* video part */}
       {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 mt-28 mx-10 md:mx-20 lg:mx-32 gap-14 ">
        <div className="relative">
          <img src={vid2} alt="Video Thumbnail " className="w-[100%] h-auto" />

          <Link to="https://www.youtube.com/watch?v=y9CjLeSqnSs">
            <FontAwesomeIcon
              icon={faPlay}
              className="h-10 w-10 text-blue-500 mt-4 cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </Link>
        </div>

        <div className="relative">
          <img src={vid3} alt="Video Thumbnail " className="w-[100%] h-auto" />

          <Link to="https://www.youtube.com/watch?v=y9CjLeSqnSs">
            <FontAwesomeIcon
              icon={faPlay}
              className="h-10 w-10 text-blue-500 mt-4 cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </Link>
        </div>

        <div className="relative">
          <img src={vid4} alt="Video Thumbnail " className="w-[100%] h-auto" />

          <Link to="https://www.youtube.com/watch?v=y9CjLeSqnSs">
            <FontAwesomeIcon
              icon={faPlay}
              className="h-10 w-10 text-blue-500 mt-4 cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </Link>
        </div>

        <div className="relative">
          <img src={vid2} alt="Video Thumbnail " className="w-[100%] h-auto" />

          <Link to="https://www.youtube.com/watch?v=y9CjLeSqnSs">
            <FontAwesomeIcon
              icon={faPlay}
              className="h-10 w-10 text-blue-500 mt-4 cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </Link>
        </div>
      </div> */}
      {/* video part */}


      {/* download-button */}
      <div className="mt-10 mb-10 text-center ">
        <a
          href={AIAcceleratePdf}
          download="AIAccelerateBouchers.pdf"
          className="bg-gradient-to-r from-teal-800 to-blue-500 px-5 py-2 md:px-10 md:py-3
          rounded-[8px] text-white font-bold text-xl md:text-2xl"
        >
          <button>Download PDF</button>
        </a>
      </div>
    </>
  );
};
export default TrainingAndPlacement;