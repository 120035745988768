import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import ourservices1 from "../../assets/Images/servicepageimages/Text.svg";
import ourservices2 from "../../assets/Images/servicepageimages/Consulting.jpg";
import ourservices3 from "../../assets/Images/servicepageimages/Softwaredeveloper.jpg";
import ourservices4 from "../../assets/Images/servicepageimages/Instagram.jpg";
import client1 from "../../assets/Images/servicepageimages/clients1.jpg";
import { BsMicrosoft, BsAndroid2, BsFillNodeMinusFill } from "react-icons/bs";
import { SiCoinmarketcap, SiWebpack } from "react-icons/si";
import { GiTreeGrowth } from "react-icons/gi";
import NavigationBar from "../../common/NavigationBar";
import "./Services.css";
import Typewriter from "typewriter-effect";
import ScrollReveal from "scrollreveal";
import Technologies from "./Technologies";
import TechnologiesText from "../../assets/Images/servicepageimages/TechnologiestextImg.svg"


const Services = () => {
  useEffect(() => {
    const parallax = document.getElementById("service-banner-parallax");

    const handleScroll = () => {
      let offset = window.pageYOffset;
      parallax.style.backgroundPositionY = offset * -0.4 + "px";
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    ScrollReveal().reveal(".services-animate-card", {
      distance: "20px",
      duration: 3000,
      delay: 200,
      origin: "top",
      mobile: true,
      scale: 0.5,
      viewFactor: 0.3,
    });
  }, []);
  
  return (
    <>
      {/* banner start */}
      <div className="service-banner-section" id="service-banner-parallax">
        <NavigationBar />
        <div className="text-white pt-40 md:pt-40 px-5  md:px-10 lg:px-20">
          <h1 className="text-xl sm:text-3xl font-semibold  leading-[60px]">
            In Search Of A Reliable{" "}
            <span className="text-[#05BFDB]">Tech Ally</span>
          </h1>
          <h1 className="text-xl sm:text-3xl font-semibold  leading-[60px]">
            Have A Camera Here
          </h1>
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mt-2">
            What We <span className="text-[#05BFDB]">Provide</span>?
          </h1>
          <p className="w-full w-60 sm:w-80 md:w-96 mt-4">
            We Offer An Array Of Software Development, Android Development,
            Neuromarketing And More.
          </p>
          <h1 className="text-3xl text-[#05BFDB] font-semibold leading-[72px]">
            <Typewriter
              options={{
                strings: [
                  "Software Development",
                  "Andriod Development",
                  "NeuroMarketing",
                  "Digital Marketing",
                  "Webtalent Gravity",
                  "Grow your Business",
                ],
                autoStart: true,
                loop: true,
                typeSpeed: 50,
              }}
            />
          </h1>
          <Link to='/digitalwebinar'>
          <button
            style={{
              background:
                "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
            }}
            className="text-white rounded-[4px] px-10 py-2 mt-4"
          >
            Explore
          </button>
          </Link>
        </div>
      </div>

      {/* banner end */}

      {/* 2nd-cards */}

      <div className="mt-10">
      <div className="flex justify-center">
      <img src={ourservices1} className="w-3/4 h-3/4 reveal-image" alt="Our Services" />
      </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mx-10 gap-10 mt-10">
          <div className="">
            <div className="flex justify-center services-animate-card">
              <img
                className="w-[70%] h-auto"
                src={ourservices2}
                alt="Card Image 1"
              />
            </div>
            <h5 className="font-bold text-xl text-center mt-5">
              IT Consultancy
            </h5>
            <p className="text-center px-10 mt-3 sm:border-r sm:border-[#969696] sm:h-[70px]">
              Our web design team has ample years of experience in the core
              areas of design to build a website that you need.
            </p>
          </div>

          <div class="">
            <div class="flex justify-center services-animate-card">
              <img
                className="w-[55%] h-auto"
                src={ourservices3}
                alt="Card Image 2"
              />
            </div>
            <h5 className="font-bold text-xl text-center mt-5">
              Software & Web Development
            </h5>
            <p className="text-center px-10 mt-3 sm:border-r sm:border-[#969696] sm:h-[70px]">
              We help you develop the right website with a good user interface
              built after a lot of research in the industry to help you make the
              best out of it.
            </p>
          </div>

          <div class="">
            <div class="flex justify-center services-animate-card">
              <img
                className="w-[50%] h-auto"
                src={ourservices4}
                alt="Card Image 2"
              />
            </div>
            <h5 className="font-bold text-xl text-center mt-12">
              Branding & Marketing
            </h5>
            <p className="text-center px-10 mt-3">
              Need help with branding and marketing to reach out to a larger
              audience? We can help you create a brand presence and marketing
              efforts.
            </p>
          </div>
        </div>
      </div>

      {/* 2nd-cards */}

      {/* 3rd-section */}
     
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10  mx-10 md:mx-20 lg:mx-32 mt-20 md:mt-28">
          <div className="">
            <h2 className="text-start font-bold text-2xl md:text-5xl">
              Clients We Worked With
            </h2>
            <hr className="w-1/4 border-2 border-2 border-[#05BFDB] my-3"></hr>

            <span className="text-[#0A4D68] italic text-2xl font-bold mt-5">
              “Let us together build a flourishing business”
            </span>

            <p className="mt-4">
              When connected with us, you aren’t growing your business alone. We
              have your back and put in our best to contribute to the growth of
              your entire team and organization. So, if you are looking for the
              right agency that’ll help you build a good online presence and
              bring in more conversions and revenue, we are right here!
            </p>

            <p className="mt-3">
              The flourishing business of a software company thrives on
              technological innovation, adaptive solutions, and a client-centric
              approach. By continuously evolving to meet the ever-changing
              demands of industries, they drive efficiency, scalability, and
              digital transformation.
            </p>
          </div>

          <div className="mt-5 md:mt-0 lg:mt-0">
            <img src={client1} className="w-full h-auto" alt="About Us" />
          </div>
     </div>
     
      {/* 3rd-section */}

      {/* services cards */}
      <div className="mt-10">
        <div className="text-center">
          <span className="text-[#14b1bb] font-bold text-lg">
            What We Provide
          </span>

          <h1 className="text-[#262566] font-bold text-4xl mt-3">
            Our Services
          </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mx-10 md:mx-20 lg:mx-32 mt-10 md:mt-20">
          <div className="border-b-4 border-[#05BFDB] p-8 shadow-2xl services-animate-card">
            <BsMicrosoft className="w-10 h-10 text-[#05BFDB]" />

            <h1 className="font-bold text-xl mt-5 text-[#262566]">
              Software Development
            </h1>

            <p className="mt-4">
              We specialize in providing custom software development services,
              delivering scalable and innovative applications that empower
              businesses to streamline processes, enhance productivity, and
              achieve their goals.
            </p>
          </div>

          <div className="p-8 shadow-2xl border-b-4 border-[#05BFDB] services-animate-card">
            <BsAndroid2 className=" w-10 h-10 text-[#05BFDB]" />

            <h1 className=" font-bold text-xl mt-5 text-[#262566]">
              Andriod Development
            </h1>

            <p className=" mt-4">
              We specialize in Android app development, offering end-to-end
              solutions from ideation and design to development, testing, and
              deployment, tailored to meet your specific business needs.
            </p>
          </div>

          <div className="p-8 shadow-2xl border-b-4 border-[#05BFDB] services-animate-card">
            <BsFillNodeMinusFill className=" w-10 h-10  text-[#05BFDB]" />

            <h1 className="font-bold text-xl mt-5 text-[#262566]">
              Neuromaketing
            </h1>

            <p className="mt-4">
              With our expertise in both software development and
              neuromarketing, we provide tailored solutions that help businesses
              understand and influence consumer behavior, resulting in improved
              marketing.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mx-10 md:mx-20 lg:mx-32 mt-20">
          <div className="p-8 shadow-2xl border-b-4 border-[#05BFDB] services-animate-card">
            <SiCoinmarketcap className=" w-10 h-10 text-[#05BFDB]" />

            <h1 className="font-bold text-xl mt-5 text-[#262566]">
              Digital marketing
            </h1>

            <p className="mt-4">
              With our expertise in software development and digital marketing,
              we provide tailored solutions to optimize your online visibility,
              boost brand awareness, and maximize ROI, utilizing cutting-edge
              tools.
            </p>
          </div>

          <div className="p-8 shadow-2xl border-b-4 border-[#05BFDB] services-animate-card">
            <SiWebpack className=" w-10 h-10 text-[#05BFDB]" />

            <h1 className="font-bold text-xl mt-5 text-[#262566]">
              Webtalent gravity
            </h1>

            <p className="mt-4">
              We provides top-notch training and placement solutions, equipping
              individuals with industry-relevant skills and connecting them with
              leading software companies for rewarding career opportunities.
            </p>
          </div>

          <div className="p-8 shadow-2xl border-b-4 border-[#05BFDB] services-animate-card">
            <GiTreeGrowth className=" w-10 h-10 text-[#05BFDB]" />

            <h1 className="font-bold text-xl mt-5 text-[#262566]">
              Grow your business
            </h1>

            <p className="mt-4">
              Experience exponential business growth through our innovative
              software services, enabling you to expand your market reach,unlock
              new opportunities and stay ahead of the competition in the digital
              era.
            </p>
          </div>
        </div>
      </div>

      {/* services cards */}

        <div className="flex justify-center mt-10 md:mt-20">
          <img src={TechnologiesText} className="w-3/4 h-3/4 md:w-1/2 md:h-1/2"></img>
        </div>

        <div className="mx-10 md:mx-20 lg:mx-32 mt-10">
          <Technologies />
        </div>
   
    </>
  );
};

export default Services;
