import { Routes, Route,useNavigate   } from "react-router-dom";
import Home from "./Modules/Componants/Home";
import About from "./Modules/Componants/About";
import Services from "./Modules/Componants/Services";
import CertificationProgram from "./Modules/Componants/CertificationProgram";
import GrowYourBusiness from "./Modules/Componants/GrowYourBusiness";
import PlacementAssuranceProgram from "./Modules/Componants/PlacementAssuranceProgram";
import WebTalentGravity from "./Modules/Componants/WebTalentGravity";
import Contact from "./Modules/Componants/Contact";
import ProfessionalTraining from "./Modules/Componants/ProfessionalTraining";
import Training from "./Modules/Componants/Training";
import Serviceoffer from './Modules/Componants/Serviceoffer';
import TrainingAndPlacement from './Modules/Componants/TrainingAndPlacement'
import FooterMain from "./common/FooterMain";
import NavigationBar from "./common/NavigationBar";
import WhatsAppButton from './Modules/Componants/WhatsAppButton';
import React from "react";
import "./Style.css";
import ChatBot from "./Modules/Componants/ChatBot";
import Feedback1 from "./Modules/Componants/Feedback1";
import Feedback2 from "./Modules/Componants/Feedback2";
import Feedback3 from "./Modules/Componants/Feedback3";
import PrivacyPolicy from "./Modules/Componants/PrivacyPolicy";
import TermsCondition from "./Modules/Componants/TermsCondition";
import Faqs from "./Modules/Componants/Faqs";
import PhoneButton from "./Modules/Componants/PhoneButton";
import Payment from "./Modules/Componants/Payment";
import Digitalwebinar from "./Modules/Componants/Digitalwebinar";
import Talentgravity from "./Modules/Componants/Talentgravity";

function App() {
  const navigate = useNavigate();
  return (
    <>
      <div>
      
        {/* <NavigationBar/> */}
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route
            path="/certificationProgram"
            element={<CertificationProgram />}
          />
          <Route path="/GrowYourBusiness" element={<GrowYourBusiness />} />
          <Route
            path="/placementAssuranceProgram"
            element={<PlacementAssuranceProgram />}
          />
          <Route path="/professionalTraining" element={<ProfessionalTraining />}/>
          <Route path="/webTalentGravity" element={<WebTalentGravity />} />
          <Route path="/digitalGravity" element={<Serviceoffer />} />
          <Route path="/aiAccelerate" element={<TrainingAndPlacement />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/training" element={<Training />} />
          <Route path="/feedback1" element={<Feedback1 />}/>
          <Route path="/feedback2" element={<Feedback2 />}/>
          <Route path="/feedback3" element={<Feedback3 />}/>
          <Route path="/privacyPolicy" element={<PrivacyPolicy />}/>
          <Route path="/termsCondition" element={<TermsCondition />}/>
          <Route path="/faqs" element={<Faqs />}/>
          <Route path="/Pay/:price" element={<Payment />}/>  
          <Route path="/digitalwebinar"  element={<Digitalwebinar/>}/>      
          <Route path="/talentgravity"  element={<Talentgravity/>}/>     
          
        </Routes>
        <FooterMain />
       
      </div>
      <PhoneButton />
      <WhatsAppButton />
      
      {/* <ChatBot /> */}
    </>
  );
}

export default App;
