import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import bannerectangle1 from "../../assets/Images/digitalwebinarimg/Rectangle1.png";
import bannerectangle2 from "../../assets/Images/digitalwebinarimg/Rectangle2.png";
import secondimg3 from "../../assets/Images/digitalwebinarimg/smilingimge.svg";
import fifthcontactimg from "../../assets/Images/digitalwebinarimg/contactimg2.svg";
import presenterimg from "../../assets/Images/digitalwebinarimg/presenterimge1.jpg";
import watchimg from "../../assets/Images/digitalwebinarimg/watchimage.svg";
import readimg from "../../assets/Images/digitalwebinarimg/readimage.svg";
import listenimg from "../../assets/Images/digitalwebinarimg/listenimage.svg";
import messsquareimg from "../../assets/Images/digitalwebinarimg/regiismage.svg";
import registeimage from "../../assets/Images/digitalwebinarimg/messagesquare.svg";
import Hearticon from "../../assets/Images/digitalwebinarimg/Iconheart.svg";
import Emojiicon from "../../assets/Images/digitalwebinarimg/Iconemoji.svg";
import beigeimage from "../../assets/Images/digitalwebinarimg/gravityimage2.jpg";
import horizontal1 from "../../assets/Images/digitalwebinarimg/horizon1.png";
import horizontal2 from "../../assets/Images/digitalwebinarimg/horizon2.png";
import "./Digitalwebinar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import NavigationBar1 from "../../common/NavigationBar1";

const Digitalwebinar = () => {
  // top-form-api updated code
  const [values, setValue] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });

  function handleChange(event) {
    const newObj = { ...values, [event.target.name]: event.target.value };
    setValue(newObj);
  }
  const [selectedOption, setSelectedOption] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleRegisterClick = (e) => {
    e.preventDefault(); // Prevent form submission

    if (
      // selectedOption &&
      values.firstname != "" &&
      values.lastname != "" &&
      values.email != "" &&
      values.phone != ""
    ) {
      const min = 1000000000; // Smallest 10-digit number
      const max = 9999999999; // Largest 10-digit number
      const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
      const min1 = 10000; // Smallest 5-digit number
      const max1 = 99999; // Largest 5-digit number
      const randomValue1 = Math.floor(Math.random() * (max1 - min1 + 1)) + min1;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        amount: 9900,
        trnsId: randomValue,
        userid: randomValue1,
        phoneNo: values.phone,
        name: values.firstname + " " + values.lastname,
        email: values.email,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://gravitonweb.co.in/main/initiate_phonepe_payment/",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          window.location.href = result?.pay_page_url;
        })
        .catch((error) => console.log("error", error));
    } else {
      setErrorMessage("Please select a course before register.");
    }
  };

  // const handleSelectChange = (e) => {
  //   setSelectedOption(e.target.value);
  //   setErrorMessage("");
  // };
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [mins, setMins] = useState(0);
  const [secs, setSeconds] = useState(0);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const deadline = new Date("November 14, 2023 00:00:00").getTime();

  const calculateTimeRemaining = () => {
    const currentTime = new Date().getTime();
    const timeRemaining = deadline - currentTime;

    if (timeRemaining <= 0) {
      setTimeIsUp(true);
      return;
    }

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    const minutes = Math.floor(
      (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
    );

    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
    setDays(days);
    setHours(hours);
    setMins(minutes);
    setSeconds(seconds);
  };

  useEffect(() => {
    const interval = setInterval(calculateTimeRemaining, 1000);
    return () => clearInterval(interval);
  }, []);
 
  const targetSectionRef = useRef(null);
  const scrollToSection = () => {
    if (targetSectionRef.current) {
      targetSectionRef.current.scrollIntoView({
        behavior: "smooth", // Add smooth scrolling animation
      });
    }
  };
  return (
    <>
      {/* banner part */}
      <div className="relative  h-[400px] md:h-[500px] w-[100%]">
        <NavigationBar1/>
        <img
          src={bannerectangle1}
          className="absolute top-0 left-0  w-96 hidden md:block"
          alt="Image 1"
        />
        <img
          src={bannerectangle2}
          className="absolute top-0 right-0 w-80 hidden md:block"
          alt="Image 2"
        />
        <div className="absolute inset-0">
          <div className=" flex flex-col justify-center items-center  h-[530px] md:h-[600px]">
            <h1 className="bg-[#F2F4F7] rounded-[8px] px-4 py-2">
              Free Webinar!
            </h1>
            <h1
              className="text-xl sm:text-2xl md:text-4xl lg:text-5xl  font-medium   text-center mt-8"
              style={{ fontFamily: "Fraunces" }}
            >
              Transform Your Business with the
              <br /> Guidance Of A Certified Marketing Coach
            </h1>
            <p
              className="text-[#667085] text-center text-lg mt-4 w-[300px] md:w-[500px]  "
              style={{ fontFamily: "Avenir" }}
            >
              Free 30-minute webinar, Anuj will teach you effective and
              respectful strategies for to improve productivity and well being
              in your life.
            </p>
            <div className="flex  gap-5">
              <Link to="/digitalGravity">
                <button className="border-2 border-black px-5 py-2 rounded-[200px] text-[#344054] mt-8 ">
                  Learn More
                </button>
              </Link>

              <button
                onClick={scrollToSection}
                className="border-2 border-black px-8 py-2 rounded-[200px] text-white bg-black  mt-8"
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* second-part */}
      <div className="gradientbg-image mt-14">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mx-5 md:mx-20 lg:mx-40 ">
          <div className="flex items-center mt-5">
          <div>
            <h1
              className="text-2xl md:text-4xl lg:text-5xl  font-medium  text-white "
              style={{ fontFamily: "Fraunces" }}
            >
              What You’ll Get From the Webinar?
            </h1>
            <ul className="mt-4">
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-white w-5 h-5"
                />
                <span
                  className="text-white text-2xl pl-4"
                  style={{ fontFamily: "Poppins" }}
                >
                  Live Interaction
                </span>
              </li>

              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-white w-5 h-5 mt-4"
                />
                <span
                  className="text-white text-2xl pl-4"
                  style={{ fontFamily: "Poppins" }}
                >
                  Q&A and Polling
                </span>
              </li>

              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-white w-5 h-5 mt-4"
                />
                <span
                  className="text-white text-2xl pl-4"
                  style={{ fontFamily: "Poppins" }}
                >
                  Multi-Platform Accessibility
                </span>
              </li>

              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-white w-5 h-5 mt-4"
                />
                <span
                  className="text-white text-2xl pl-4"
                  style={{ fontFamily: "Poppins" }}
                >
                  Scheduling and Reminders
                </span>
              </li>

              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-white w-5 h-5 mt-4"
                />
                <span
                  className="text-white text-2xl pl-4"
                  style={{ fontFamily: "Poppins" }}
                >
                  Analytics and Reporting
                </span>
              </li>
            </ul>
          </div>
          </div>

          <div>
            <img src={secondimg3}/>
          </div>
        </div>
      </div>

      {/* third-part */}
     
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-24 mx-5 md:mx-20 lg:mx-40  mt-8 md:mt-14">
        <div>
          <div className="border-t-4 border-[#DE5217]  "></div>

          <img src={horizontal2} className="mt-4" />
          <h1
            className=" text-2xl md:text-4xl  mt-5"
            style={{ fontFamily: "Fraunces" }}
          >
            Are you feeling stuck in your Business?
          </h1>
        </div>

        <div className=" mt-5 md:mt-24">
          <div className="border-t-4 border-[#007CC2]  "></div>

          <img src={horizontal1} className="mt-4" />
          <h1
            className=" text-2xl md:text-4xl  mt-5"
            style={{ fontFamily: "Fraunces" }}
          >
            Struggling to find balance in your marketing?
          </h1>
        </div>
      </div>

      {/* fourth-part */}
     

      <div className="bg-[#AFEBF3] mt-10 p-20">
        <div className="flex  flex-col justify-center items-center h-auto">
          <h1
            className=" text-[#211F1F] font-semibold text-2xl md:text-4xl lg:text-5xl mt-5"
            style={{ fontFamily: "Poppins" }}
          >
            If so, join us for this free webinar!
          </h1>
          <p
            className=" text-[#211F1F] font-semibold text-xl text-center  mt-4 w-auto md:w-[600px]"
            style={{ fontFamily: "Poppins" }}
          >
            Whatever the case may be, a certified life coach can guide you
            towards the path of self-discovery and personal growth.
          </p>
        </div>
      </div>

      {/* fifth-part */}
      <div className="bg-[#E4F8FB]">
        <div
          ref={targetSectionRef}
          className=" grid grid-cols-1 md:grid-cols-2 
          md:mx-20 lg:mx-40  p-5 md:p-10 "
        >
          <div className=" flex items-center h-auto">
            <img src={fifthcontactimg} className="w-[70%]" />
          </div>

          <div>
            <form>
              <div className="bg-white shadow-xl  rounded-lg">
                <button className="bg-[#00384F] text-white px-10 py-5 w-full text-3xl font-bold">
                  Register Now At &#8377; 99/-
                </button>

                <h1 className="text-center text-lg mt-3">Limited Period</h1>

                <div
                  className="text-center mt-5"
                  style={{ fontFamily: "Quantico, sans-serif" }}
                >
                  <button className="bg-[#FF0000] px-3 py-2 sm:px-1 sm:py-2 md:px-3 md:py-2 lg:px-10 lg:py-2 xl:px-16 xl:py-2 rounded-[4px]">
                    {timeIsUp ? (
                      <div className="text-center bg-[#FF0000] text-white">
                        <h2 className="text-4xl">Time's Up!</h2>
                      </div>
                    ) : (
                      <div className="flex gap-2 sm:gap-5 text-white">
                        <div className="text-center">
                          <h1 className="text-xl">
                            {days < 10 ? "0" + days : days}
                          </h1>

                          <span className="text-md">Days</span>
                        </div>

                        <span className="text-xl">:</span>

                        <div className="text-center">
                          <h1 className="text-xl">
                            {hours < 10 ? "0" + hours : hours}
                          </h1>

                          <span className="text-md">Hours</span>
                        </div>

                        <span className="text-xl">:</span>

                        <div className="text-center">
                          <h1 className="text-xl">
                            {mins < 10 ? "0" + mins : mins}
                          </h1>

                          <span className="text-md">Mins</span>
                        </div>

                        <span className="text-xl">:</span>

                        <div className="text-center">
                          <h1 className="text-xl">
                            {secs < 10 ? "0" + secs : secs}
                          </h1>

                          <span className="text-md">Secs</span>
                        </div>
                      </div>
                    )}
                  </button>
                </div>

                <p className="text-center mt-5 text-lg">Get Free Webinar</p>

                <div className="px-3 md:px-10 pb-10">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                    <div>
                      <input
                        type="text"
                        placeholder="First Name"
                        className="bg-transparent border-2 p-2 rounded-[4px] w-full"
                        id="fname"
                        name="firstname"
                        value={values.firstname}
                        onChange={handleChange}
                        required
                      />
                      {values.firstname === "" && errorMessage && (
                        <p className="text-red-400">
                          Please fill the First Name
                        </p>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="bg-transparent border-2 p-2 rounded-[4px] w-full"
                        id="lname"
                        name="lastname"
                        value={values.lastname}
                        onChange={handleChange}
                        required
                      />
                      {values.lastname === "" && errorMessage && (
                        <p className="text-red-400">
                          Please fill the Last Name
                        </p>
                      )}
                    </div>
                  </div>

                  <input
                    type="email"
                    placeholder="Email"
                    className="bg-transparent border-2 p-2 w-full mt-5 rounded-[4px]"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    required
                  />
                  {values.email == "" && errorMessage && (
                    <p className="text-red-400">Please Fill the Email </p>
                  )}
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    className="bg-transparent border-2 p-2 w-full mt-5 rounded-[4px]"
                    id="Pnum"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    required
                  />
                  {values.phone == "" && errorMessage && (
                    <p className="text-red-400">Please fil the Phone Number </p>
                  )}
                  {/* <div className="flex justify-center">
                    <img src={DownArrow} className="w-[40%] h-auto"></img>
                  </div> */}

                  {/* <select
                    className="block bg-transparent border-2 p-2 w-full mt-7 rounded-[4px]"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    required
                  >
                    <option value="" disabled selected>
                      Select Course
                    </option>

                    <option value="option1" className="text-black">
                      Free Session To Learn GrowYour Business
                    </option>
                  </select>
                  {!selectedOption && errorMessage && (
                    <p className="text-red-400">
                      Please select a course before register
                    </p>
                  )} */}

                  <div className="flex items-center mt-4">
                    <input
                      type="checkbox"
                      className="border-2 rounded-md h-4 w-4 bg-[#05BFDB]"
                    />

                    <label htmlFor="checkbox" className="ml-2 text-lg">
                      I agree the details provided here is accurate
                    </label>
                  </div>
                  <button onClick={handleRegisterClick} className="w-full">
                    <a
                      href="https://www.instamojo.com/@gravitonwebit/l5a83dce20e07429daf78be100d36ceba/"
                      className="block w-full bg-[#05BFDB] text-white text-xl text-center
                    font-semibold py-3 mt-5 rounded-[5px] hover:bg-white hover:text-[#05BFDB]"
                    >
                      Register Now
                    </a>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* sixth-part */}
      <div
        className="grid grid-cols-1 md:grid-cols-2
          mx-5 md:mx-20 lg:mx-40  mt-10 "
      >
        <div className="bg-[#FDF8F2]  flex flex-col justify-center items-center  h-auto">
          <span className="bg-[#F2F4F7] rounded-[8px] px-4 py-2 mt-5 md:mt-0">
            Presenter
          </span>
          <h1
            className="text-xl sm:text-2xl md:text-4xl lg:text-5xl  font-medium   text-center mt-5"
            style={{ fontFamily: "Fraunces" }}
          >
            Meet Anuj
          </h1>
          <p
            className="text-black text-center text-xl mt-4 w-[300px] md:w-[400px] mb-5 md:mb-0  "
            style={{ fontFamily: "Avenir" }}
          >
            Anuj is a best-selling parenting author and speaker, and creator of
            this LifeCoach course
          </p>

          {/* <button className="border-2 border-black px-8 py-2 rounded-[200px] text-white bg-black mb-5 md:mb-0  mt-8">
            Read About Anuj
          </button> */}
        </div>

        <div>
          <img src={presenterimg} />
        </div>
      </div>

      {/* seventh-part */}
      <div className="mt-10">
        <h1
          className="  text-[#211F1F] text-xl sm:text-2xl md:text-4xl lg:text-5xl  font-medium   text-center "
          style={{ fontFamily: "Fraunces" }}
        >
          Checkout Our Free Resources
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mx-10 md:mx-20 lg:mx-40 mt-10">
        <div>
          <div>
            <img src={watchimg} className="w-[90%] h-auto" />
          </div>
          <h1
            className="  text-[#211F1F] text-xl sm:text-2xl md:text-3xl  font-medium   text-center mt-3 "
            style={{ fontFamily: "Fraunces" }}
          >
            Watch
          </h1>
          <p className="text-[#211F1F] text-center mt-3">
            Watch development combines design, precision engineering, and
            craftsmanship to create timepieces that blend form and function.
          </p>
        </div>

        <div>
          <div className="flex justify-center">
            <img src={readimg} className="w-[75%] h-auto" />
          </div>
          <h1
            className="  text-[#211F1F] text-xl sm:text-2xl md:text-3xl  font-medium   text-center  mt-3"
            style={{ fontFamily: "Fraunces" }}
          >
            Read
          </h1>
          <p className="text-[#211F1F] text-center mt-3">
            Reading books on development broadens knowledge, fosters growth, and
            empowers individuals to make a positive impact.
          </p>
        </div>

        <div>
          <div className="flex justify-center">
            <img src={listenimg} className="w-[60%] h-auto" />
          </div>
          <h1
            className="  text-[#211F1F] text-xl sm:text-2xl md:text-3xl  font-medium   text-center mt-3 "
            style={{ fontFamily: "Fraunces" }}
          >
            Listen
          </h1>
          <p className="text-[#211F1F] text-center mt-3">
            Development in the context of listening skills involves actively
            improving one's ability to understand and communicate effectively.
          </p>
        </div>
      </div>

      {/* eight-part */}
      <div className="bg-[#FDF8F2] mt-12 pb-5 md:pb-36  ">
        <div
          className="  grid grid-cols-1 md:grid-cols-2 
            mx-10 md:mx-20 lg:mx-40 pt-12  gap-5 md:gap-10"
        >
          <div className=" mt-0 md:mt-20">
            <img src={registeimage} alt="Registration Image" />

            <h1
              className="text-[#101828] text-xl sm:text-2xl md:text-3xl font-medium  mt-5"
              style={{ fontFamily: "Fraunces" }}
            >
              Thanks to this life coach, I have a clear roadmap for achieving my
              goals and creating the life I want. I'm so grateful for their
              support and guidance.
            </h1>
          </div>

          <div>
            <img src={messsquareimg} className="w-[100%] md:w-[80%]" />
          </div>
        </div>

        <div
          className="  grid grid-cols-1 md:grid-cols-2 
 mx-10 md:mx-20 lg:mx-40  pt-5 md:pt-16  gap-5 md:gap-10"
        >
          <div className="">
            <div className="flex justify-center">
              <img src={Hearticon} />
            </div>
            <h1
              className="text-[#101828] text-xl md:text-2xl  text-center font-medium  mt-5"
              style={{ fontFamily: "Fraunces" }}
            >
              Gain clarity, focus, and <br />
              confidence in your abilities
            </h1>

            <p
              className="text-[#667085] text-center text-lg mt-4 px-5 md:px-12 "
              style={{ fontFamily: "Avenir" }}
            >
              Our experienced coaches work with you to identify and overcome
              limiting beliefs, create a roadmap for success, and provide
              ongoing support and guidance.
            </p>
          </div>

          <div className="">
            <div className="flex justify-center">
              <img src={Emojiicon} />
            </div>
            <h1
              className="text-[#101828] text-xl md:text-2xl  text-center font-medium  mt-5"
              style={{ fontFamily: "Fraunces" }}
            >
              Create the life
              <br /> you want
            </h1>

            <p
              className="text-[#667085] text-center text-lg mt-4 px-5 md:px-12 "
              style={{ fontFamily: "Avenir" }}
            >
              Don't let fear or self-doubt hold you back any longer. Our life
              coaching services can help you gain the clarity and direction you
              need to create the life you want.
            </p>
          </div>
        </div>
      </div>

      {/* ninth-part */}
      <div className="bg-black md:relative ">
        <div className="bg-white  md:absolute md:left-[25%] md:top-[-80px] rounded-[8px]  ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 p-8  ">
            <div>
              <h1
                className="text-[#101828] text-xl font-medium text-center mt-3"
                style={{ fontFamily: "Fraunces" }}
              >
                Contact us today to schedule a session and
                <br /> start living the life you want.
              </h1>
            </div>

            <div>
              <p class="mt-5">
                <input
                  type="text"
                  id="email"
                  className="w-full  px-5 py-2 border rounded-[4px] border-gray-400 "
                  placeholder="Enter Your Email"
                  name="email"
                />
              </p>
              <button className="border-2 border-black px-8 py-2 rounded-[200px] text-white bg-black  mt-3">
                Sign Up
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mx-10 md:mx-20 lg:mx-40 ">
          <div className="flex flex-col items-center justify-center h-[150px] md:h-[400px]">
            <h1
              className="text-[#007CC2] text-xl sm:text-2xl md:text-5xl font-medium text-center mt-3"
              style={{ fontFamily: "Fraunces" }}
            >
              Sunday, 19th March
            </h1>
            <button className="border-2 border-white px-5 py-2 rounded-[200px] text-white mt-4 ">
              + Add To Calendar
            </button>
          </div>

          <div className="flex flex-col  items-center justify-center h-[150px] md:h-[400px]">
            <h1
              className="text-[#FFFFFF] text-xl sm:text-2xl md:text-5xl font-medium text-center "
              style={{ fontFamily: "Fraunces" }}
            >
              Online on Zoom
            </h1>
            <p className="text-white mt-4">
              Link will be shared before the event
            </p>
          </div>
        </div>
      </div>

      {/* tenth-part */}
      <div className="mt-10">
        <h1
          className="text-[#101828] text-xl sm:text-2xl md:text-4xl lg:text-5xl font-medium text-center "
          style={{ fontFamily: "Fraunces" }}
        >
          Invite your friends
        </h1>
        <p className="text-[#100D0B] text-center  text-lg  mt-4 ">
          We work with you to identify areas for improvement, develop effective
          <br />
          communication strategies, and provide ongoing support and guidance.
        </p>
        <div className="flex justify-center mt-5">
          <img src={beigeimage} />
        </div>
      </div>
    </>
  );
};

export default Digitalwebinar;
