import React, { useState } from "react";
import { MdLocationOn, MdCall, MdOutlineMailOutline } from "react-icons/md";
import NavigationBar from "../../common/NavigationBar";
import "./Contact.css";
import { server } from "../../env/env";

const Contact = () => {
  const [values, setValue] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    textarea: "",
  });

  function handleChange(event) {
    const newObj = { ...values, [event.target.name]: event.target.value };
    setValue(newObj);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    
    var formdata = new FormData();
    formdata.append("firstname", values.firstname);
    formdata.append("lastname", values.lastname);
    formdata.append("email", values.email);
    formdata.append("phone", values.phone);
    formdata.append("message", values.textarea);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://gravitonweb.co.in/main/contact_api/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // alert("success Fully Regsiterd ");
        console.log(result);
      })
      .catch((error) => console.log("error", error));
      setValue({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        textarea: "",
      });
  };

  return (
    <>
      {/* banner start */}

      <div className="contact-banner-section">
        <NavigationBar />
        <div className="pt-36 md:pt-40 px-5 sm:px-10 md:px-20">
          <h1 className="text-xl md:text-2xl lg:text-4xl font-semibold contact-title">
            The Support Team Is
          </h1>
          <h1 className="text-xl md:text-2xl lg:text-4xl font-semibold mt-2 contact-title">
            Here To Help You
          </h1>
          <p className="mt-4 contact-title text-lg md:text-base">
            Get in touch and let us know how we can help...!
          </p>
        </div>
      </div>

      {/* banner end */}

      {/* contact */}
<div className="overflow-x-hidden">
      <div className="grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 gap-10 md:gap-20 mx-5 md:mx-20 lg:mx-30 mt-10 md:mt-20">
        <div className="md:col-span-5 lg:col-span-5 bg-[#00FFCA21] p-10 md:px-14 md:py-20 shadow-lg">
          <div>
            <div className="flex">
              <MdCall className="text-[#31abfc] text-xl font-semibold" />
              <h1 className=" font-bold pl-2">PHONE</h1>
            </div>

            <div className="border-t border-[#ADADAD] w-100 h-1 mt-2" />
            <p className="mt-3">+91-9415174046</p>
          </div>

          <div className="mt-5">
            <div className="flex">
              <MdOutlineMailOutline className="text-[#31abfc] text-xl font-semibold" />

              <h1 className="font-bold pl-2">E-MAIL
              </h1>
            </div>

            <div className="border-t border-[#ADADAD] w-100 h-1 mt-2" />
            <p className="mt-3">info@gravitonweb.com</p>
          </div>

          <div className="mt-5">
            <div className="flex">
              <MdLocationOn className="text-[#31abfc] text-xl font-semibold " />

              <h1 className="font-bold pl-2">ADDRESS</h1>
            </div>

            <div className="border-t border-[#ADADAD] w-100 h-1 mt-2" />
            <p className="mt-3">
              Tavarekere, Aicobo Nagar, BTM 1st <br /> Stage, Bengaluru,
              Karnataka India 560029
            </p>
          </div>
        </div>

        <div className="md:col-span-6 lg:col-span-6">
          <h1 className="text-2xl md:text-4xl font-bold">
            Ready To Get Started ?
          </h1>
          <form>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-8 mt-8">
              <p>
                <input
                  type="text"
                  id="firstname"
                  value={values.firstname}
                  onChange={handleChange}
                  required
                  name="firstname"
                  placeholder="First Name"
                  className="py-2 w-full font-semibold text-gray-500 border-2 border-[#05BFDB] px-5"
                />
              </p>
              <p>
                <input
                  type="text"
                  id="lastname"
                  value={values.lastname}
                  onChange={handleChange}
                  name="lastname"
                  placeholder="Last Name"
                  required
                  className="py-2 w-full font-semibold text-gray-500 border-2 border-[#05BFDB] px-5"
                />
              </p>
              <p>
                <input
                  type="text"
                  id="phone"
                  value={values.phone}
                  onChange={handleChange}
                  name="phone"
                  required
                  placeholder="Phone"
                  className="py-2 w-full font-semibold text-gray-500 border-2 border-[#05BFDB] px-5"
                />
              </p>
              <p>
                <input
                  type="text"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  name="email"
                  placeholder="Email"
                  required
                  className="py-2 w-full font-semibold text-gray-500 border-2 border-[#05BFDB]  px-5"
                />
              </p>
            </div>
            <textarea
              className=" py-2 w-full font-semibold text-gray-500 border-2 border-[#05BFDB] mt-5 px-5 "
              placeholder="Enter message here"
              value={values.textarea}
              onChange={handleChange}
              name="textarea"
              rows="4"
              required
            ></textarea>

            <div className="flex flex-row items-center justify-center">
              <button
                type="submit"
                onClick={handleSubmit}
                style={{
                  transition: "transform 0.5s",
                }}
                className="px-10 mt-5 py-2 text-gray-500 font-semibold  border-2 border-[#05BFDB] 
                text-lg rounded-[4px]"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="mt-10 md:mt-28">
        <div className="text-center">
          <h2 className=" text-2xl md:text-4xl font-bold mb-1 text-center">
            Get in touch with our creator-friendly support
            <br />
            team
          </h2>
        </div>

        {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5  mx-5 md:mx-20 lg:mx-30 mt-10 md:mt-20">
          <div className="p-4">
            <div
              className="rounded-lg p-8"
              style={{
                background:
                  "linear-gradient(180deg, #F8F9C9 0%, #0A4D68 0.01%, #05BFDB 100%)",
              }}
            >           
              <h2 className="text-white text-lg  font-medium">Sales</h2>

              <div className="flex mt-2">
                <MdCall className="text-[#00FFCA] text-xl font-semibold " />
                <p className="text-white pl-2">+91-9415174046</p>
              </div>

              <div className="flex mt-2">
                <MdOutlineMailOutline className="text-[#00FFCA] text-2xl font-semibold " />

                <p className="text-white pl-2">
                  <b>akrati.verma@gravitonweb.com</b>
                </p>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div
              className="rounded-lg p-8"
              style={{
                background:
                  "linear-gradient(180deg, #F8F9C9 0%, #0A4D68 0.01%, #05BFDB 100%)",
              }}
            >
                <h2 className="text-white text-lg  font-medium">HR</h2>

              <div className="flex mt-2">
                <MdCall className="text-[#00FFCA] text-xl font-semibold " />
                <p className="text-white pl-2">+91-797858787</p>
              </div>

              <div className="flex mt-2">
                <MdOutlineMailOutline className="text-[#00FFCA] text-2xl font-semibold" />

                <p className=" text-white pl-2">
                  <b>hr@gravitonweb.com</b>
                </p>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div
              className="rounded-lg p-8"
              style={{
                background:
                  "linear-gradient(180deg, #F8F9C9 0%, #0A4D68 0.01%, #05BFDB 100%)",
              }}
            >
           
                <h2 className="text-white text-lg title-font font-medium">
                  Support
                </h2>

              <div className="flex mt-2">
                <MdCall className="text-[#00FFCA] text-xl font-semibold " />
                <p className="text-white pl-2">+91-9691417158</p>
              </div>

              <div className="flex mt-2">
                <MdOutlineMailOutline className="text-[#00FFCA] text-2xl font-semibold " />
                <p className=" text-white pl-2">
                  <b>support@gravitonweb.com</b>
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      </div>
      {/* contact */}

      {/* map */}
      <div className="mt-10">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.8281749193025!2d77.60770847507558!3d12.91876268739173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15ea3f6c5c45%3A0x9991677a220a162b!2sGraviton%20Web%20Technologies!5e0!3m2!1sen!2sin!4v1694600594595!5m2!1sen!2sin"
          className="w-full h-[50vh] px-3"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      {/* map */}

      
    </>
  );
};

export default Contact;
