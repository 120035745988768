import React, { useState, useRef, useEffect } from "react";

import ReactGA from "react-ga";

function ChatBot() {
  const [messages, setMessages] = useState([]);

  const [userMessages, setUserMessages] = useState(["", "", "", ""]);

  const [questionIndex, setQuestionIndex] = useState(0);

  const [chatOpen, setChatOpen] = useState(false);

  const chatWindowRef = useRef(null);

  const inputRefs = useRef([
    React.createRef(),

    React.createRef(),

    React.createRef(),

    React.createRef(),
  ]);

  const questions = [
    "What is your name?",

    "Where are you from?",

    "What is your Phone Number?",

    "How can we assist you today?",
  ];

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    scrollToBottom();

    // Ask the first question when the component loads

    if (questionIndex === 0 && messages.length === 0) {
      setTimeout(() => {
        setMessages([...messages, { text: questions[0], user: "bot" }]);

        setQuestionIndex(1);
      }, 1000);
    }

    // Check if all questions have been asked and answered

    if (
      questionIndex === questions.length &&
      userMessages.every((msg) => msg.trim() !== "")
    ) {
      setTimeout(() => {
        // Clear the chat and reset

        setMessages([]);

        setQuestionIndex(0);

        setUserMessages(["", "", "", ""]); // Clear user's responses

        inputRefs.current.forEach((ref) => {
          if (ref.current) {
            ref.current.value = ""; // Clear input fields
          }
        });

        setChatOpen(false); // Close the chat
      }, 1000);
    }
  }, [messages, questionIndex, userMessages]);

  useEffect(() => {
    // Focus the input field when the chat is opened

    if (chatOpen && inputRefs.current[questionIndex - 1].current) {
      inputRefs.current[questionIndex - 1].current.focus();
    }
  }, [chatOpen, questionIndex]);

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  const handleUserMessageChange = (e, index) => {
    const updatedUserMessages = [...userMessages];

    updatedUserMessages[index] = e.target.value;

    setUserMessages(updatedUserMessages);
  };

  const handleUserMessageSubmit = () => {
    ReactGA.event({
      category: "Button",

      action: "Click",

      label: "Custom Button Click",
    });

    if (userMessages[questionIndex - 1].trim() !== "") {
      const newMessage = {
        text: userMessages[questionIndex - 1],
        user: "user",
      };

      setMessages([...messages, newMessage]);

      // Clear the input field immediately

      inputRefs.current[questionIndex - 1].current.value = "";

      if (questionIndex < questions.length) {
        // Display the bot's next question immediately

        setMessages((prevMessages) => [
          ...prevMessages,

          { text: questions[questionIndex], user: "bot" },
        ]);

        setQuestionIndex(questionIndex + 1);
      }
    }
  };

  const toggleChat = () => {
    setChatOpen(!chatOpen);
  };

  return (
    <div className="fixed bottom-20 right-0 mb-4 mr-4">
      <div>
        {!chatOpen && (
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400"
            onClick={toggleChat}
          >
            ChatBot
          </button>
        )}
      </div>

      {chatOpen && (
        <div
          className="bg-gradient-to-r from-blue-400 to-purple-500 border rounded-lg shadow-lg h-80 overflow-y-auto"
          ref={chatWindowRef}
        >
          <div className="p-4 border-b flex flex-col justify-between h-full">
            <div>
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`mb-2 p-2 ${
                    message.user === "user"
                      ? "bg-black text-white self-end"
                      : "bg-gray-200 text-gray-800 self-start"
                  } rounded-md`}
                >
                  {message.text}
                </div>
              ))}
            </div>

            {questionIndex <= questions.length && (
              <div className="p-2 flex items-center">
                <input
                  type="text"
                  className="flex-grow border rounded-l py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Type a message..."
                  value={userMessages[questionIndex - 1]}
                  onChange={(e) =>
                    handleUserMessageChange(e, questionIndex - 1)
                  }
                  ref={inputRefs.current[questionIndex - 1]}
                />

                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r focus:outline-none focus:ring-2 focus:ring-blue-400"
                  onClick={handleUserMessageSubmit}
                >
                  Send
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatBot;
