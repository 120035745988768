import React from "react";
import "./style.css";
import { Link,useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faGlobe,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const FooterMain = () => {
  const location = useLocation();
  const flag = location?.pathname?.includes("/feedback");

  if (flag) {
    return <></>;
  } else {
    return (
      <>
        <div className="footer-part mt-6 p-12">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 mx-0 md:mx-12 lg:mx-14 ">
            <div>
              <h4 className="text-[#14b1bb] text-3xl font-bold">Graviton</h4>

              <h5 className="mt-3 text-white text-lg">
                <Link to="/About">About Us</Link>
              </h5>

              <p className="mt-3  text-white">
                We Are A Team Of Web
                <br /> Experts With Experience <br />
                In Website Building And <br />
                Marketing To Help Businesses
                <br /> Grow Online.
              </p>
            </div>

            <div className="mt-5 md:mt-4 lg:mt-0">
              <h5 className="text-white">Legal</h5>

              <div className="mt-2 md:mt-4 text-white">
                <Link to="/termsCondition">
                  <p className="">Terms & Conditions</p>
                </Link>

                <Link to="/privacyPolicy">
                  <p className="mt-2">Privacy Policy</p>
                </Link>

                <Link to="/faqs">
                  <p className="mt-2">Faq's</p>
                </Link>
              </div>
            </div>

            <div className="mt-5 md:mt-4 lg:mt-0">
              <h5 className="text-white">Get In Touch</h5>

              <div className="mt-2 md:mt-4 text-white">
                {/* 
              <p className="">Sign Up Free</p>
              <p className="mt-2">Sign In</p>
              <p className="mt-2">Support</p> */}
                <p className="">
                  <Link to="/contact">Contact Us</Link>
                </p>

                <p className="mt-2">
                  <Link to="/feedback1">Feedback</Link>
                </p>
              </div>
            </div>

            <div className="mt-5 md:mt-4 lg:mt-0">
              <h5 className="text-white">Quick Links</h5>

              <div className="mt-2 md:mt-4 text-white">
                <p className="">
                  <Link to="/">Home</Link>
                </p>

                <p className="mt-2">
                  {" "}
                  <Link to="/About">About</Link>
                </p>

                <p className="mt-2">
                  <Link to="/services">Services</Link>
                </p>

                <p className="mt-2">
                  <Link to="/WebTalentGravity">WebTalent Gravity</Link>
                </p>

                <p className="mt-2">
                  <Link to="/digitalGravity">Digital Gravity</Link>
                </p>

                <p className="mt-2">
                  <Link to="/aiAccelerate"> AI Accelerate</Link>
                </p>
              </div>
            </div>

            <div className="mt-5 md:mt-4 lg:mt-0">
              <h5 class="text-white">
                <Link to="/contact">Contact Us</Link>
              </h5>

              <ul class="list-inline footer-contact mt-2 md:mt-4">
                <li className="mb-3">
                  <a href="tel:+91-9415174046">
                    <FontAwesomeIcon icon={faPhone} className="footer-icon" />
                    +91-9415174046
                  </a>
                </li>

                <li className="mb-3">
                  <a href="mailto:info@gravitonweb.com">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="footer-icon"
                    />
                    info@gravitonweb.com
                  </a>
                </li>

                <li className="mb-3">
                  <Link href="#">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="footer-icon"
                    />
                    Tavarekere, Aicobo Nagar,
                    <br />
                    BTM 1st Stage, Bengaluru,
                    <br />
                    Karnataka India 560029
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <hr className="my-5 w-100 text-white "></hr>

          <div className="grid grid-cols-1  lg:grid-cols-2 mx-0 md:mx-12 lg:mx-14">
            <div>
              <div className="social-link">
                <Link to="https://www.facebook.com/Gravitonwebtechnologies?mibextid=ZbWKwL">
                  <FontAwesomeIcon icon={faFacebook} className="social-icon" />
                </Link>

                <Link to="https://instagram.com/graviton_web_technologies?igshid=NjIwNzIyMDk2Mg==">
                  <FontAwesomeIcon icon={faInstagram} className="social-icon" />
                </Link>

                <Link to="https://x.com/Gravitonwebtec1?t=drKkQrmcBjdT7BsVq2pjNg&s=09">
                  <FontAwesomeIcon icon={faTwitter} className="social-icon" />
                </Link>

                <Link to="https://www.linkedin.com/company/graviton-web-technologies/">
                  <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
                </Link>
              </div>
            </div>

            <div>
              <p className="footer-paragraph text-white">
                Copyright@2020 All Rights Reserved By :
                <Link href="#" className="footer-text">
                  <strong> Gravitonweb Technologies</strong>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default FooterMain;