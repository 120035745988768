import React from "react";
import { Link } from "react-router-dom";
import AboutUs from "../../assets/Images/AboutImage/AboutUsImage1.png";
import AboutConnect from "../../assets/Images/AboutImage/AboutImg4.svg";
import AboutGoal from "../../assets/Images/AboutImage/AboutImage3.png";
import NavigationBar from "../../common/NavigationBar";
import "./About.css";
import CountUp from "react-countup";
const About = () => {
  return (
    <>
      {/* banner start */}
      <div className="about-banner-section" id="about-banner-parallax">
        <NavigationBar />

        <div className="text-white pt-52 px-5 sm:px-10 md:px-20 text-center">
          <h1 className="text-xl sm:text-3xl md:text-5xl font-semibold mt-12 ">
            We Discover, We Design, We Execute
          </h1>

          <p className="text-lg font-medium mt-5">
            Futuristic tech solutions for complex business challenges!
          </p>
          <Link to="/digitalwebinar">
            <button
              style={{
                background:
                  "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
              }}
              className="text-white rounded-[4px] px-10 py-2 mt-10"
            >
              Explore
            </button>
          </Link>
        </div>
      </div>
      {/* banner end */}

      {/* About-Us */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10 mx-10 md:mx-20 lg:mx-32 mt-10 md:mt-20">
        <div className="">
          <h2 className="font-bold text-xl sm:text-2xl md:text-4xl">
            What Makes Gravitonwebtech The Leading Company?
          </h2>
          <hr className="w-1/4 border-2 border-2 border-[#05BFDB] my-3"></hr>

          <p className="mt-8 text-[#525252] text-base  lg:text-lg">
            From small startups to multinational giants, software companies are
            instrumental in shaping the digital world. Software companies
            assemble teams of highly skilled professionals, including software
            engineers, designers, project managers, and quality assurance
            experts. Together, they conceive, design, and code software
            solutions that address a wide range of needs.
          </p>

          <p className="mt-3 text-[#525252] text-base  lg:text-lg">
            A software company is a dynamic entity at the heart of the modern
            technological landscape. These companies are dedicated to the
            creation, development, and maintenance of software applications that
            power nearly every aspect of our lives.
          </p>
        </div>

        <div className="">
          <img src={AboutUs} className="w-full h-auto" alt="About Us" />
        </div>
      </div>
      {/* About-Us */}

      {/* Rating */}
      <div className="grid grid-cols-3 gap-5 mx-5 text-center divide-x divide-black mt-14">
        <div className="">
          <h3 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-5xl font-medium">
            <CountUp start={0} end={16} duration={10} />
            <span>+</span>
          </h3>
          <p className="text-sm mt-2 text-[#3C3C3C]">Years Of IT Experience</p>
        </div>

        <div className="">
          <h3 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-5xl font-medium">
            <CountUp start={0} end={200} duration={10} />
            <span>+</span>
          </h3>
          <p className="text-sm mt-2 text-[#3C3C3C]">Customers 30+ Countries</p>
        </div>

        <div className=" ">
          <h3 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-5xl font-medium">
            <CountUp start={0} end={850} duration={10} />
            <span>+</span>
          </h3>
          <p className="text-sm mt-2 text-[#3C3C3C]">Delivered Projects</p>
        </div>
      </div>
      {/* Rating */}

      {/* connect */}
      <div
        className="mt-14"
        style={{
          background: "linear-gradient(180deg, #0A4D68 0%, #0082B7 100%)",
        }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mx-10 md:mx-20 lg:mx-32 py-10">
          <div class="sm:flex sm:items-center sm:h-[50vh]">
            <div class="text-white">
              <h1 class="font-bold text-xl md:text-3xl lg:text-5xl">
                Lets Get In Touch
              </h1>
              <p class="mt-4">
                Explore upcoming events to connect, learn and share with other
                <br /> Zendesk customers.
              </p>
              <Link to="/contact">
                <button
                  type="submit"
                  class="border border-white mt-5 px-10 py-2 text-white text-lg font-semibold"
                >
                  Join Us
                </button>
              </Link>
            </div>
          </div>
          <div className="flex justify-center sm:items-center sm:h-[50vh]">
            <img
              src={AboutConnect}
              alt="About Banner"
              className="w-52 sm:w-72 h-52 sm:h-72"
            />
          </div>
        </div>
      </div>
      {/* connect */}

      {/* goals */}

      <div className="flex flex-col items-center justify-center mt-10 md:mt-20">
        <div className="flex items-center">
          <img src={AboutGoal} alt="" />
          <div className="ml-2">
            <h1 className="text-[#262566] font-bold text-2xl md:text-4xl">
              UR AIM
            </h1>
            <div className="w-30 border-2 border-[#05BFDB] mt-3"></div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mx-10 md:mx-20 lg:mx-18 mt-10 md:mt-20">
        <div className=" p-8 shadow-2xl divide-y divide-[#898989] about-cards-section">
          <h1 className="font-bold text-2xl mt-5 text-center">Our Vision</h1>

          <p className=" mt-5 p-3 text-justify">
            Our vision is to pioneer innovation in the world of software,
            transcending boundaries to create cutting-edge solutions that
            empower businesses and individuals alike. We strive to be at the
            forefront of technological advancements, constantly pushing the
            envelope to harness the full potential of emerging technologies. Our
            commitment to excellence drives us to develop user-centric software
            that simplifies complexities, enhances productivity, and enriches
            lives. We aim to foster a collaborative ecosystem.
          </p>
        </div>

        <div className="p-8 shadow-2xl divide-y  divide-[#898989] about-cards-section">
          <h1 className=" font-bold text-2xl mt-5  text-center">Our Mission</h1>

          <p className=" mt-4 p-3 text-justify">
            Our mission is to revolutionize the software landscape by delivering
            unparalleled value and innovation. We are dedicated to crafting
            user-centric solutions that solve real-world problems, empowering
            businesses and individuals to thrive in an ever-evolving digital
            world. Committed to excellence, we continually refine and expand our
            expertise across diverse technologies, ensuring our software sets
            new industry standards. We foster a culture of collaboration,
            creativity, and inclusivity, enabling our talented team to drive
            meaningful change.
          </p>
        </div>

        <div className="p-8 shadow-2xl  divide-y  divide-[#898989] about-cards-section">
          <h1 className=" font-bold text-xl mt-8  text-center">Our Value</h1>

          <p className=" mt-4 p-3 text-justify">
            As a software company, our values are the foundation of our identity
            and the driving force behind everything we do. Innovation is at the
            heart of our culture, inspiring us to explore new horizons, embrace
            emerging technologies, and pioneer groundbreaking solutions. We
            believe in pushing boundaries, fostering a creative environment, and
            nurturing fresh ideas that lead to meaningful advancements. Quality
            is non-negotiable for us. We are unwavering in our commitment to
            delivering software that is reliable, efficient, and user-centric.
          </p>
        </div>
      </div>

      {/* goals */}
    </>
  );
};

export default About;
