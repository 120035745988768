import React, { useState, useRef, useEffect } from "react";
import BannerImage from "../../assets/Images/WebTalentGravity/webtalentbanner4.png";
import TrainingImage1 from "../../assets/Images/WebTalentGravity/webtech-frontend-image1.avif";
import TrainingImage2 from "../../assets/Images/WebTalentGravity/webtech-backend-image2.avif";
import TrainingImage3 from "../../assets/Images/WebTalentGravity/webtech-fullstack-image2.avif";
import TrainingImage4 from "../../assets/Images/WebTalentGravity/webtech-python-image1.avif";
import TrainingImage5 from "../../assets/Images/WebTalentGravity/webtech-digital-image1.avif";
import TrainingImage6 from "../../assets/Images/WebTalentGravity/webtech-mobile-image2.avif";
import slide1 from "../../assets/Images/Homepageimages/slide7.png";
import slide2 from "../../assets/Images/Homepageimages/slide8.png";
import slide3 from "../../assets/Images/Homepageimages/slide9.png";
import slide4 from "../../assets/Images/Homepageimages/slide6.png";
import slide5 from "../../assets/Images/Homepageimages/slide10.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faStar,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import { AiOutlineFileWord } from "react-icons/ai";
import { FaHandshake } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TextImage from "../../assets/Images/WebTalentGravity/training-image-text.svg";
import ScrollReveal from "scrollreveal";
import WebTalentPdf from "../../assets/Images/WebTalentGravity/Web Talent Gravity.pdf";
import DownArrow from "../../assets/Images/WebTalentGravity/down-arrow-webtalent1.gif";
import "./WebTalent.css";
import TypeIt from "typeit";
import NavigationBar from "../../common/NavigationBar";
import Certificate from "../../assets/Images/WebTalentGravity/WebtalentgravityCertificate.png";
import Certificate2 from "../../assets/Images/WebTalentGravity/certifications2.svg";
import roadmap2 from "../../assets/Images/WebTalentGravity/Roadmapsat.svg";
import timerimg from "../../assets/Images/WebTalentGravity/timedurationimg.jpg";
import arrowright from "../../assets/Images/WebTalentGravity/arrow.svg";
import prateek from "../../assets/Images/WebTalentGravity/prateekCapture.PNG";
import Mansi from "../../assets/Images/WebTalentGravity/mansiCapture.PNG";
import nilanshi from "../../assets/Images/WebTalentGravity/nilanshiCapture.PNG";
import avdhesh from "../../assets/Images/WebTalentGravity/avdheshCapture.PNG";
import rishbh from "../../assets/Images/WebTalentGravity/rishabhCapture.PNG";
import ashish from "../../assets/Images/WebTalentGravity/ashishCapture.PNG";
import amiya from "../../assets/Images/WebTalentGravity/amiyaCapture.PNG";
import manan from "../../assets/Images/WebTalentGravity/mananCapture.PNG";
import mithali from "../../assets/Images/WebTalentGravity/mithaliCapture.PNG";

const WebTalentGravity = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [mins, setMins] = useState(0);
  const [secs, setSeconds] = useState(0);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const deadline = new Date("May 20, 2024 00:00:00").getTime();

  const calculateTimeRemaining = () => {
    const currentTime = new Date().getTime();
    const timeRemaining = deadline - currentTime;

    if (timeRemaining <= 0) {
      setTimeIsUp(true);
      return;
    }

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    const minutes = Math.floor(
      (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
    );

    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    setDays(days);
    setHours(hours);
    setMins(minutes);
    setSeconds(seconds);
  };

  useEffect(() => {
    const interval = setInterval(calculateTimeRemaining, 1000);
    return () => clearInterval(interval);
  }, []);

  const WebtechSlideSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const isSmallScreen = window.innerWidth <= 768;

  if (isSmallScreen) {
    WebtechSlideSettings.slidesToShow = 1;
  }

  const WebtechSlideSettings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  const isSmallScreen1 = window.innerWidth <= 768;

  if (isSmallScreen1) {
    WebtechSlideSettings1.slidesToShow = 1;
  }

  const [values, setValue] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });

  function handleChange(event) {
    const newObj = { ...values, [event.target.name]: event.target.value };
    setValue(newObj);
  }

  // updated-code

  const [selectedOption, setSelectedOption] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleRegisterClick = (e) => {
    e.preventDefault(); // Prevent form submission

    if (
      selectedOption &&
      values.firstname != "" &&
      values.lastname != "" &&
      values.email != "" &&
      values.phone != ""
    ) {
      const min = 1000000000; // Smallest 10-digit number
      const max = 9999999999; // Largest 10-digit number
      const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
      const min1 = 10000; // Smallest 5-digit number
      const max1 = 99999; // Largest 5-digit number
      const randomValue1 = Math.floor(Math.random() * (max1 - min1 + 1)) + min1;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        amount: 9900,
        trnsId: randomValue,
        userid: randomValue1,
        phoneNo: values.phone,
        name: values.firstname + " " + values.lastname,
        email: values.email,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://gravitonweb.co.in/main/initiate_phonepe_payment/",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          window.location.href = result?.pay_page_url;
        })
        .catch((error) => console.log("error", error));
    } else {
      setErrorMessage("Please select a course before register.");
    }
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    setErrorMessage("");
  };

  useEffect(() => {
    ScrollReveal().reveal(".webtalent-card", {
      distance: "20px",
      duration: 1000,
      delay: 100,
      origin: "top",
      mobile: true,
      scale: 0.5,
      viewFactor: 0.3,
    });
  }, []);

  //banner-section-animatation
  const headingRef = useRef();

  useEffect(() => {
    new TypeIt(headingRef.current, {
      strings: [
        "CREATING THE FUTURE",
        '<span class="text-[#fff]"> IN </span><span class="text-[#00FFCA]">WEBTALENT GRAVITY</span>',
      ],
      speed: 50,
      cursor: true,
      html: true,
      loop: true,
      //  startDelay: 1000,
    }).go();
  }, []);

  const headingRef1 = useRef();

  useEffect(() => {
    new TypeIt(headingRef1.current, {
      strings: [" Thousands Of happy Students and still counting"],
      speed: 50,
      cursor: false,
      html: true,
      loop: true,
    }).go();
  }, []);

  return (
    <>
      {/* banner */}
      <div className="relative">
        <img
          src={BannerImage}
          alt="Banner"
          className="w-full  h-[1400px] sm:h-[1100px] md:h-[1000px] lg:h-[1000px] xl:h-[950px] object-cover"
        />

        <div className="absolute inset-0 gap-0 lg:gap-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 mx-5 md:mx-10 lg:mx-20">
          <NavigationBar />
          <div className="text-white mt-20 sm:mt-32 md:mt-40 lg:mt-60">
            {/* <h1 className="text-2xl md:text-3xl lg:text-5xl font-bold">
              CREATING THE FUTURE
            </h1>
            <h1 className="text-[#00FFCA] text-2xl md:text-3xl lg:text-5xl font-bold mt-3">
              <span className="text-2xl md:text-3xl lg:text-5xl font-bold text-white">
                {" "}
                OF{" "}
              </span>
              WEBTALENT GRAVITY
            </h1> */}
            <div>
              <h1
                className="text-2xl md:text-3xl lg:text-5xl font-bold"
                ref={headingRef}
              ></h1>
            </div>

            <ul className="mt-8 pl-5">
              <li className="text-white list-disc text-xl">
              Internsip of 6 Month 
              </li>

              <li className="text-white list-disc text-xl mt-2">
                Training Development Course -{" "}
                <span className="text-[#00FFCA] font-bold"> 3 Months</span>
              </li>

              <li className="text-white list-disc text-xl mt-2">
               AI Accelerate -
                <span className="text-[#00FFCA] font-bold"> 15 Days</span>
              </li>

              <li className="text-white list-disc text-xl mt-2">
                Get Certificate As an Intern
              </li>
              <li className="text-white list-disc text-xl mt-2">
              10+ Interview calls

              </li>
           
              <li className="text-white list-disc text-xl mt-2">
              Receive an incentive after three months of training
              </li>
            </ul>
          </div>

          <div className="text-white mt-0 sm:mt-20 md:mt-20 lg:mt-32">
            <form>
              <div className="bg-white bg-opacity-10  rounded-lg">
                <button className="bg-[#00384F] px-10 py-5 w-full text-3xl font-bold">
                  Register Now At &#8377; 99/-
                </button>

                <h1 className="text-center text-lg mt-3">Limited Period</h1>

                <div
                  className="text-center mt-5"
                  style={{ fontFamily: "Quantico, sans-serif" }}
                >
                  <button className="bg-[#FF0000] px-3 py-2 sm:px-1 sm:py-2 md:px-3 md:py-2 lg:px-10 lg:py-2 xl:px-16 xl:py-2 rounded-[4px]">
                    {timeIsUp ? (
                      <div className="text-center bg-[#FF0000] text-white">
                        <h2 className="text-4xl">Time's Up!</h2>
                      </div>
                    ) : (
                      <div className="flex gap-2 sm:gap-5">
                        <div className="text-center">
                          <h1 className="text-xl">
                            {days < 10 ? "0" + days : days}
                          </h1>

                          <span className="text-md">Days</span>
                        </div>

                        <span className="text-xl">:</span>

                        <div className="text-center">
                          <h1 className="text-xl">
                            {hours < 10 ? "0" + hours : hours}
                          </h1>

                          <span className="text-md">Hours</span>
                        </div>

                        <span className="text-xl">:</span>

                        <div className="text-center">
                          <h1 className="text-xl">
                            {mins < 10 ? "0" + mins : mins}
                          </h1>

                          <span className="text-md">Mins</span>
                        </div>

                        <span className="text-xl">:</span>

                        <div className="text-center">
                          <h1 className="text-xl">
                            {secs < 10 ? "0" + secs : secs}
                          </h1>

                          <span className="text-md">Secs</span>
                        </div>
                      </div>
                    )}
                  </button>
                </div>

                <p className="text-center mt-5 text-lg">
                 
Seats are limited for our six-month internship with live projects.
                </p>

                <div className="px-3 md:px-10 pb-10">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 mt-8">
                    <div>
                      <input
                        type="text"
                        placeholder="First Name"
                        className="bg-transparent border-2 p-2 rounded-[4px] w-full"
                        id="fname"
                        name="firstname"
                        value={values.firstname}
                        onChange={handleChange}
                        required
                      />
                      {values.firstname === "" && errorMessage && (
                        <p className="text-red-400">
                          Please fill the First Name
                        </p>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="bg-transparent border-2 p-2 rounded-[4px] w-full"
                        id="lname"
                        name="lastname"
                        value={values.lastname}
                        onChange={handleChange}
                        required
                      />
                      {values.lastname === "" && errorMessage && (
                        <p className="text-red-400">
                          Please fill the Last Name
                        </p>
                      )}
                    </div>
                  </div>

                  <input
                    type="email"
                    placeholder="Email"
                    className="bg-transparent border-2 p-2 w-full mt-7 rounded-[4px]"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    required
                  />
                  {values.email == "" && errorMessage && (
                    <p className="text-red-400">Please Fill the Email </p>
                  )}
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    className="bg-transparent border-2 p-2 w-full mt-7 rounded-[4px]"
                    id="Pnum"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    required
                  />
                  {values.phone == "" && errorMessage && (
                    <p className="text-red-400">Please fil the Phone Number </p>
                  )}
                  {/* <div className="flex justify-center">
                    <img src={DownArrow} className="w-[40%] h-auto"></img>
                  </div> */}

                  <select
                    className="block bg-transparent border-2 p-2 w-full mt-7 rounded-[4px]"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    required
                  >
                    <option value="" disabled selected>
                      Select Course
                    </option>

                    <option value="option1" className="text-black">
                      FrontEnd Development
                    </option>

                    <option value="option2" className="text-black">
                      BackEnd Development
                    </option>

                    <option value="option3" className="text-black">
                      FullStack Development
                    </option>

                    <option value="option3" className="text-black">
                      Professional Training
                    </option>

                    <option value="option3" className="text-black">
                      AI Accelerate
                    </option>
                  </select>
                  {!selectedOption && errorMessage && (
                    <p className="text-red-400">
                      Please select a course before register
                    </p>
                  )}

                  <div className="flex items-center mt-4">
                    <input
                      type="checkbox"
                      className="border-2 rounded-md h-4 w-4 bg-[#05BFDB]"
                    />

                    <label htmlFor="checkbox" className="ml-2 text-lg">
                      I agree the details provided here is accurate
                    </label>
                  </div>
                  <button onClick={handleRegisterClick} className="w-full">
                    <a
                      href="https://www.instamojo.com/@gravitonwebit/l5a83dce20e07429daf78be100d36ceba/"
                      className="block w-full bg-[#05BFDB] text-white text-xl text-center
                    font-semibold py-3 mt-5 rounded-[5px] hover:bg-white hover:text-[#05BFDB]"
                    >
                      Register Now
                    </a>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* banner */}

      <div className="mt-10 md:mt-20">
        <div className="flex justify-center">
          <img src={TextImage} className="w-3/4 h-3/4"></img>
        </div>

        <p className="mx-10 md:mx-0 md:text-center mt-5 font-medium text-lg wabtalent-paragraph1">
          Gravitonweb Technologies is here to offer you a wonderful fresher
          technical course <br /> package containing the below courses relevant
          to all areas of interview procedure <br /> which would really help the
          students to shine and succeed.
        </p>
      </div>

      {/* slider */}
      <div className="mt-10 md:mt-20 mx-10 md:mx-20 lg:mx-40">
        <Slider {...WebtechSlideSettings}>
          <div className="p-4">
            <div className="border-2 border-[#05BFDB] p-5 ">
              <div class="webtalent-box">
                <img src={TrainingImage1} alt=""></img>

                <div class="webtalent-layer">
                  <h4 className="text-xl font-semibold">Frontend Developer</h4>
                </div>
              </div>

              <h1 className="text-[#262566] text-xl font-bold mt-5">
                Frontend Development
              </h1>

              <p className="text-[#64666C] mt-2">
                Gravitonweb Technologies offers frontend development training
                with real-time example and hands-on projects. Our entire
                frontend development training course focusses on an elementary
                stage to expert level with detailed concepts.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-2 border-[#05BFDB]  p-5">
              <div class="webtalent-box">
                <img src={TrainingImage2} alt=""></img>

                <div class="webtalent-layer">
                  <h4 className="text-xl font-semibold">Backend Developer</h4>
                </div>
              </div>

              <h1 className="text-[#262566] text-xl font-bold mt-5">
                Backend Development
              </h1>

              <p className="text-[#64666C] mt-2">
                Gravitonweb Technologies offers Back-end Development refers to
                the server-side development. Knowledge of various DBMS
                technology is one of the important Backend developer skills.
                MySQL, MongoDB, Oracle, SQLServer etc.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-2 border-[#05BFDB]  p-5">
              <div class="webtalent-box">
                <img src={TrainingImage3} alt=""></img>

                <div class="webtalent-layer">
                  <h4 className="text-xl font-semibold">Fullstack Developer</h4>
                </div>
              </div>

              <h1 className="text-[#262566] text-xl font-bold mt-5">
                Fullstack Development
              </h1>
              <p className="text-[#64666C] mt-2">
                Gravitonweb Technologies are here to enhance your career as a
                web developer to the advanced level with our Fullstack Developer
                training program. We will teach you Placement Oriented Angular
                Js, Node Js, Express Js etc.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-2 border-[#05BFDB]  p-5">
              <div class="webtalent-box">
                <img src={TrainingImage4} alt=""></img>

                <div class="webtalent-layer">
                  <h4 className="text-xl font-semibold">Python</h4>
                </div>
              </div>

              <h1 className="text-[#262566] text-xl font-bold mt-5">Python</h1>

              <p className="text-[#64666C] mt-2">
                Gravitonweb Technologies offers Python training with real-time
                example and hands-on projects. Python Training will make you an
                expert in Python Programming Language to become a Professional
                Python Developer with 10+ Real Time Projects.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-2 border-[#05BFDB]  p-5">
              <div class="webtalent-box">
                <img src={TrainingImage5} alt=""></img>

                <div class="webtalent-layer">
                  <h4 className="text-xl font-semibold">Digital Marketing</h4>
                </div>
              </div>

              <h1 className="text-[#262566] text-xl font-bold mt-5">
                Digital Marketing
              </h1>

              <p className="text-[#64666C] mt-2">
                One of the best digital marketing training is offered through
                deep-rooted training approaches approved by topmost MNCs and
                start-ups. Get trained on SEO, SEM, SMM, PPC, MailChimp, Google
                & FB AdWords techniques.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-2 border-[#05BFDB]  p-5">
              <div class="webtalent-box">
                <img src={TrainingImage6} alt=""></img>

                <div class="webtalent-layer">
                  <h4 className="text-xl font-semibold">Mobile Application</h4>
                </div>
              </div>

              <h1 className="text-[#262566] text-xl font-bold mt-5">
                Mobile Application
              </h1>

              <p className="text-[#64666C] mt-2">
                Gravitonweb Technologies Android & IOS Swift training and
                Placement Offered by Certified experts in Bangalore.Get full
                course & training from our experts and make a career in
                information technology.Get Enrolled Now
              </p>
            </div>
          </div>
        </Slider>
      </div>
      {/* slider */}

      {/* contact-content */}
      <div
        className="text-white  mt-10  flex flex-col justify-center text-center p-12"
        style={{
          background: "linear-gradient(180deg, #0A4D68 0%, #0082B7 100%)",
        }}
      >
        <h1 className="font-semibold text-3xl" ref={headingRef1}>
          {/* Thousands Of happy Students and still counting */}
        </h1>

        <p className="mt-4 text-[#00FFCA] text-lg">
          Gravitonweb Technology is rated as the No.1 Traning Institute in
          Bangalore with 100% placement support
        </p>

        <div className="flex justify-center mt-5">
          <a
            href="https://www.instamojo.com/@gravitonwebit/l5a83dce20e07429daf78be100d36ceba/"
            className="bg-[#05BFDB] text-white text-xl
                    font-semibold py-3 px-10 mt-5 rounded-[5px] hover:bg-white hover:text-[#05BFDB]"
          >
            Register Now
          </a>
        </div>
      </div>
      {/* contact-content */}

      {/* placement */}

      <div className="mt-10 md:mt-20">
        <div className="">
          <h1 className="text-center text-2xl md:text-3xl font-bold text-[#262566]">
            Placement Assistance At <br></br>
            <span className="text-center text-2xl md:text-4xl font-bold text-[#262566]">
              Gravitonweb Technologies
            </span>
          </h1>
          <hr class="my-3 mx-auto w-40 border-t-4 border-[#05BFDB]"></hr>
        </div>

        <p className="mx-10 md:mx-0 md:text-center mt-5 font-medium text-lg">
          Our institution is proud to offer a 100% placement guarantee to our
          students.<br></br> We are dedicated to equipping our graduates with
          the knowledge, skills, and <br></br> experience needed to excel in
          their chosen careers.
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10 mx-5 md:mx-20 lg:mx-32 mt-10 md:mt-20">
        <div
          className="rounded-[8px] webtalent-card"
          style={{
            background: "linear-gradient(180deg, #0A4D68 0%, #0082B7 100%)",
          }}
        >
          <h1 className="text-white font-semibold p-8 text-xl text-center">
            10+ Years experienced<br></br> Trainers
          </h1>
        </div>

        <div
          className="rounded-[8px] webtalent-card"
          style={{
            background: "linear-gradient(180deg, #0A4D68 0%, #0082B7 100%)",
          }}
        >
          <h1 className="text-white font-semibold p-8 text-xl text-center ">
            100% placement <br></br>assistance
          </h1>
        </div>

        <div
          className="rounded-[8px] webtalent-card"
          style={{
            background: "linear-gradient(180deg, #0A4D68 0%, #0082B7 100%)",
          }}
        >
          <h1 className="text-white font-semibold p-8 text-xl text-center">
            Free Study Materials and<br></br> Placement guide
          </h1>
        </div>

        <div
          className="rounded-[8px] webtalent-card"
          style={{
            background: "linear-gradient(180deg, #0A4D68 0%, #0082B7 100%)",
          }}
        >
          <h1 className="text-white font-semibold p-8 text-xl text-center">
            Enhances the personality of<br></br> the students
          </h1>
        </div>

        <div
          className="rounded-[8px] webtalent-card"
          style={{
            background: "linear-gradient(180deg, #0A4D68 0%, #0082B7 100%)",
          }}
        >
          <h1 className="text-white font-semibold p-8 text-xl text-center">
            3234 Tie-up Companies for<br></br> Placement Support
          </h1>
        </div>

        <div
          className="rounded-[8px] webtalent-card"
          style={{
            background: "linear-gradient(180deg, #0A4D68 0%, #0082B7 100%)",
          }}
        >
          <h1 className="text-white font-semibold p-8 text-xl text-center">
            Skills as per IT<br></br> industry
          </h1>
        </div>
      </div>

      {/* roadmap section */}
      <div className=" mt-14 md:mt-20 mx-5 md:mx-20 lg:mx-28">
        <h1
          className=" font-bold  text-2xl md:text-4xl "
          style={{ fontFamily: "Poppins" }}
        >
          SERVICES
          <p className="border-t-8 border-[#262566] w-[31%] md:w-[14%]  my-3 "></p>
        </h1>
      </div>

      <div className="hidden  md:block  mt-10 pointer-events-none ">
        <div className="flex justify-center">
          <img src={roadmap2} className="w-[60%] h-auto"></img>
        </div>
      </div>

      {/* for mobile */}
      <div className="block md:hidden ">
        <div className=" grid grid-cols-1 mx-5 mt-5">
          <div className="border-2 rounded-2xl mb-4 ">
            <h1
              className="text-white p-4"
              style={{
                background:
                  "linear-gradient(0deg, #0A4D68 8.68%, #05BFDB 140.45%)",
              }}
            >
              FRONT-END TRAINING
            </h1>

            <ol className="bg-[#C8E4FF] font-semibold p-4">
              <li className="">1. Notes, Live and Recording</li>
              <li className="">2. HTML, CSS, JavaScript</li>
              <li className="">3. React & Redux</li>
              <li className="">4. Redux Toolkit</li>
              <li className="">5. ANTID</li>
            </ol>
          </div>

          <div className="border-2 rounded-2xl mb-4">
            <h1
              className="text-white p-4"
              style={{
                background:
                  "linear-gradient(0deg, #0A4D68 8.68%, #05BFDB 140.45%)",
              }}
            >
              LOGICAL PRACTICE
            </h1>

            <ol className="bg-[#C8E4FF] font-semibold p-4">
              <li className="">1. Puzzle Solving</li>
              <li className="">2. Deductive Reasoning</li>
              <li className="">3. Pattern Recognition</li>
              <li className="">4. Alogorithm Design</li>
            </ol>
          </div>

          <div className="border-2 rounded-2xl mb-4">
            <h1
              className="text-white p-4"
              style={{
                background:
                  "linear-gradient(0deg, #0A4D68 8.68%, #05BFDB 140.45%)",
              }}
            >
              AI ACCELERATE
            </h1>

            <ol className="bg-[#C8E4FF] font-semibold p-4">
              <li className="">1. Free Course Certificate</li>
              <li className="">2. Path of Frontent Developement</li>
              <li className="">3. Professional Training</li>
              <li className="">4. AI Powered Job Assistance</li>
            </ol>
          </div>

          <div className="border-2 rounded-2xl mb-4">
            <h1
              className="text-white p-4"
              style={{
                background:
                  "linear-gradient(0deg, #0A4D68 8.68%, #05BFDB 140.45%)",
              }}
            >
              LIVE PROJECT
            </h1>

            <ol className="bg-[#C8E4FF] font-semibold p-4">
              <li className="">1. Real World Application</li>
              <li className="">2. Exeperienced Instructor</li>
              <li className="">3. Skill Enhancement</li>
              <li className="">4. Tackle Industry Level Problems</li>
            </ol>
          </div>

          <div className="border-2 rounded-2xl  mb-4">
            <h1
              className="text-white p-4"
              style={{
                background:
                  "linear-gradient(0deg, #0A4D68 8.68%, #05BFDB 140.45%)",
              }}
            >
              PROFESSIONAL TRAINING
            </h1>

            <ol className="bg-[#C8E4FF] font-semibold p-4">
              <li className="">1. Portfolio Creation </li>
              <li className="">2. Resume Enhancer</li>
              <li className="">3. GitHub Walkthrough </li>
              <li className="">4. Enhance LinkedIn</li>
            </ol>
          </div>

          <div className="border-2 rounded-2xl mb-4 ">
            <h1
              className="text-white p-4"
              style={{
                background:
                  "linear-gradient(0deg, #0A4D68 8.68%, #05BFDB 140.45%)",
              }}
            >
              PLACEMENT ASSURANCE
            </h1>

            <p className="bg-[#C8E4FF] font-semibold text-justify p-4">
              Completed the latest industry- approved courses to stay updated.
              Focused on placement training.Handle Interview with case. Get
              unique opportunities to sit for interview with care companies
            </p>
          </div>

          <div className="border-2 rounded-2xl ">
            <h1
              className="text-white p-4"
              style={{
                background:
                  "linear-gradient(0deg, #0A4D68 8.68%, #05BFDB 140.45%)",
              }}
            >
              EXPERIENCE CERTIFICATE
            </h1>

            <ol className="bg-[#C8E4FF] font-semibold p-4">
              <li className="">1. 3 Month Experience Certificate </li>
              <li className="">2. AI Accelerate Certificate</li>
            </ol>
          </div>
        </div>
      </div>

      {/* duration with support part */}

      <div className="grid grid-cols-1  md:grid-cols-2   gap-5 mx-5 md:mx-20 lg:mx-28 mt-10 md:mt-20">
        <div className="md:mt-5">
          <h1
            className=" font-bold text-2xl md:text-4xl lg:text-5xl "
            style={{ fontFamily: "Poppins" }}
          >
            DURATION WITH
            <br /> SUPPORT
            <p className="border-t-8 border-[#262566] w-[50%]  md:w-[40%]  my-3 "></p>
          </h1>
          <p className="text-[#525252] text-md lg:text-lg xl:text-xl">
            Here, in the following columns you will get the actual durations
            with supportive perks
          </p>
        </div>

        <div className=" md:flex md:justify-end">
          <img src={timerimg} className=" w-[100%] md:w-[60%] h-auto"></img>
        </div>
      </div>

      <div className=" flex mx-5 md:mx-20 lg:mx-28 mt-5 md:mt-10 ">
        <p className=" ">
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="h-4 w-4 md:h-6 md:w-6 text-[#0984F5] pr-3"
          />
          <span className="text-[#0984F5] text-lg md:text-2xl  font-semibold pr-5">
            Front-End Training
          </span>
        </p>

        <p className=" border-t-4 border-[#00AFC9] w-[10%] my-4    "></p>

        <p>
          <span className="text-[#0984F5]  text-lg md:text-2xl font-semibold pl-5">
            60 Days
          </span>
        </p>
      </div>

      <div className=" flex mx-5 md:mx-20 lg:mx-28 mt-5  ">
        <p className=" ">
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="h-4 w-4 md:h-6 md:w-6 text-[#0984F5] pr-3"
          />
          <span className="text-[#0984F5] text-lg md:text-2xl  font-semibold pr-5">
            Professional Training
          </span>
        </p>

        <p className=" border-t-4 border-[#00AFC9] w-[10%] my-4    "></p>

        <p>
          <span className="text-[#0984F5]  text-lg md:text-2xl font-semibold pl-5">
            15 Days
          </span>
        </p>
      </div>

      <div className=" flex mx-5 md:mx-20 lg:mx-28 mt-5 ">
        <p className=" ">
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="h-4 w-4 md:h-6 md:w-6 text-[#0984F5] pr-3"
          />
          <span className="text-[#0984F5] text-lg md:text-2xl  font-semibold pr-5">
            Logical Practice
          </span>
        </p>

        <p className=" border-t-4 border-[#00AFC9] w-[10%] my-4    "></p>

        <p>
          <span className="text-[#0984F5]  text-lg md:text-2xl font-semibold pl-5">
            Till Placement
          </span>
        </p>
      </div>

      <div className=" flex mx-5 md:mx-20 lg:mx-28 mt-5  ">
        <p className=" ">
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="h-4 w-4 md:h-6 md:w-6 text-[#0984F5] pr-3"
          />
          <span className="text-[#0984F5] text-lg md:text-2xl  font-semibold pr-5">
            AI Accelerate
          </span>
        </p>

        <p className=" border-t-4 border-[#00AFC9] w-[10%] my-4    "></p>

        <p>
          <span className="text-[#0984F5]  text-lg md:text-2xl font-semibold pl-5">
            5 Days Workshop
          </span>
        </p>
      </div>

      {/* hear-student-section */}

      <div className="  mt-10 md:mt-20 mx-10 md:mx-20 lg:mx-28">
        <h1
          className=" font-bold  text-2xl md:text-4xl lg:text-5xl "
          style={{ fontFamily: "Poppins" }}
        >
          HEAR FROM OUR
          <br /> STUDENTS
          <p className="border-t-8 border-[#262566] w-[50%]  md:w-[20%]  my-3 "></p>
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-12  gap-5 mx-10 md:mx-20 lg:mx-32  mt-10 md:mt-16">
        <div className="md:col-span-5 md:mt-5">
          <h1
            className="text-[#0984F5] font-semibold  text-xl md:text-3xl "
            style={{ fontFamily: "Poppins" }}
          >
            Ankit Singh
          </h1>
          <i className="text-xl font-bold">Web Designer</i>
          <p
            className="text-[#494949] text-lg  text-justify  mt-3"
            style={{ fontFamily: "Poppins" }}
          >
            I can't express how much <b>WebTalentGravity</b> has changed my
            career. The instructors are topnotch, the courses are incredibly
            informative, and the community is so supportive. Thanks to their
            training, I've grown from a novice to a confident web designer. The
            best part? I landed my dream job right after graduation.{" "}
            <b>WebTalentGravity</b> is the real deal!
          </p>
        </div>

        <div className="md:col-span-2 flex justify-center items-center">
          <div className="hidden  md:block  border-l-4 border-[#262566] h-[100%] md:h-[90%] md:ml-14"></div>
        </div>
        <div className=" block md:hidden border-t-4 border-[#262566] w-[100%] md:w-[80%]  "></div>

        <div className="md:col-span-5 md:mt-5">
          <h1
            className="text-[#0984F5] font-semibold  text-xl md:text-3xl "
            style={{ fontFamily: "Poppins" }}
          >
            Prachi Sharma
          </h1>
          <i className="text-xl font-bold">App Developer</i>
          <p
            className="text-[#494949] text-lg  text-justify  mt-3"
            style={{ fontFamily: "Poppins" }}
          >
            Learning both iOS and Android app development at{" "}
            <b>WebTalentGravity</b> was a game-changer. The instructors provided
            practical insights, and I was able to work on real app projects.
            Now, I'm a successful app developer with my own app on the market.
            If you're serious about app development, this is the place to be.
          </p>
        </div>

        <div className=" block md:hidden border-t-4 border-[#262566] w-[100%] md:w-[80%] mt-5 "></div>
        <div className="md:col-span-5 md:mt-5">
          <h1
            className="text-[#0984F5] font-semibold  text-xl md:text-3xl "
            style={{ fontFamily: "Poppins" }}
          >
            Mansi Singh
          </h1>
          <i className="text-xl font-bold">AI Enthusiast</i>
          <p
            className="text-[#494949] text-lg  text-justify  mt-3"
            style={{ fontFamily: "Poppins" }}
          >
            <b>WebTalentGravity's</b> AI program was nothing short of amazing.
            The curriculum was wellstructured, and the hands-on experience was
            invaluable. I joined as someone with a curiosity for AI and left
            with a deep understanding of machine learning and AI applications.
            I'm now pursuing a master's in AI, all thanks to{" "}
            <b>WebTalentGravity</b>.
          </p>
        </div>

        <div className="md:col-span-2 flex justify-center items-center">
          <div className="hidden  md:block  border-l-4 border-[#262566] h-[100%] md:h-[90%] md:ml-14"></div>
        </div>
        <div className=" block md:hidden border-t-4 border-[#262566] w-[100%] md:w-[80%]  md:mt-14 "></div>

        <div className="md:col-span-5 md:mt-5">
          <h1
            className="text-[#0984F5] font-semibold  text-xl md:text-3xl "
            style={{ fontFamily: "Poppins" }}
          >
            Ankush Mishra
          </h1>
          <i className="text-xl font-bold">Freelancer Developer</i>
          <p
            className="text-[#494949] text-lg  text-justify  mt-3"
            style={{ fontFamily: "Poppins" }}
          >
            What I loved most about <b>WebTalentGravity</b> was the flexibility.
            I could choose my learning format, access resources online, and
            create my own schedule. It allowed me to continue working while
            enhancing my skills. Today, I'm a successful freelance developer,
            and it's all thanks to the convenience <b>WebTalentGravity</b>{" "}
            offered.
          </p>
        </div>

        <div className=" block md:hidden border-t-4 border-[#262566] w-[100%] md:w-[80%] mt-5 "></div>
        <div className="md:col-span-5  md:mt-5 ">
          <h1
            className="text-[#0984F5] font-semibold  text-xl md:text-3xl "
            style={{ fontFamily: "Poppins" }}
          >
            Avyan Sharma
          </h1>
          <i className="text-xl font-bold">Entrepreneur</i>
          <p
            className="text-[#494949] text-lg  text-justify  mt-3"
            style={{ fontFamily: "Poppins" }}
          >
            I came to <b>WebTalentGravity</b> with an idea for a tech startup.
            The guidance and mentorship I received were invaluable. I learned
            the technical skills to build our platform and received valuable
            business insights. Today, my startup is thriving, and it all started
            at <b>WebTalentGravity</b>.
          </p>
        </div>

        <div className="md:col-span-2 flex justify-center items-center">
          <div className="hidden  md:block  border-l-4 border-[#262566] h-[100%] md:h-[90%] md:ml-14"></div>
        </div>
        <div className=" block md:hidden border-t-4 border-[#262566] w-[100%] md:w-[80%]  md:mt-14 "></div>

        <div className="md:col-span-5 md:mt-5">
          <h1
            className="text-[#0984F5] font-semibold  text-xl md:text-3xl "
            style={{ fontFamily: "Poppins" }}
          >
            Ravi Bajaj
          </h1>
          <i className="text-xl font-bold">IT Manager</i>
          <p
            className="text-[#494949] text-lg  text-justify  mt-3"
            style={{ fontFamily: "Poppins" }}
          >
            <b>WebTalentGravity</b> offers the flexibility that professionals
            like me need. I could balance my full- time job and take evening
            classes. The skills I learned helped me advance in my career.
            Whether you're a beginner or an experienced tech professional,{" "}
            <b>WebTalentGravity</b> has something for everyone
          </p>
        </div>
      </div>

      {/* placed section */}

      <div className="  mt-10 md:mt-20 mx-10 md:mx-20 lg:mx-28">
        <h1
          className=" font-bold  text-2xl md:text-4xl lg:text-5xl "
          style={{ fontFamily: "Poppins" }}
        >
          RECENTLY PLACED
          <br /> STUDENTS
          <p className="border-t-8 border-[#262566] w-[50%]  md:w-[20%]  my-3 "></p>
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mx-10 md:mx-20 lg:mx-28  mt-10 md:mt-16">
        <div>
          <div className="flex justify-center">
            <img src={prateek} />
          </div>
          <div className="text-center">
            <h1
              className="text-[#0984F5] font-semibold  text-xl md:text-3xl mt-4 "
              style={{ fontFamily: "Poppins" }}
            >
              Prateek Mehta
            </h1>
            <i className="text-xl font-bold">IT Manager</i>
            <br />
            <i className="text-xl font-semibold">Placed in Wipro</i>
          </div>
        </div>

        <div>
          <div className="flex justify-center">
            <img src={Mansi} />
          </div>
          <div className="text-center">
            <h1
              className="text-[#0984F5] font-semibold  text-xl md:text-3xl mt-4 "
              style={{ fontFamily: "Poppins" }}
            >
              Mansi Singh
            </h1>
            <i className="text-xl font-bold">UI/UX Designer</i>
            <br />
            <i className="text-xl font-semibold">Placed in Deloitte</i>
          </div>
        </div>

        <div>
          <div className="flex justify-center">
            <img src={nilanshi} />
          </div>
          <div className="text-center">
            <h1
              className="text-[#0984F5] font-semibold  text-xl md:text-3xl mt-4 "
              style={{ fontFamily: "Poppins" }}
            >
              Nilanshi
            </h1>
            <i className="text-xl font-bold">Software Engineer</i>
            <br />
            <i className="text-xl font-semibold">Placed in Jio Tech</i>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-center">
            <img src={avdhesh} />
          </div>
          <div className="text-center">
            <h1
              className="text-[#0984F5] font-semibold  text-xl md:text-3xl mt-4 "
              style={{ fontFamily: "Poppins" }}
            >
              Avdhesh Singh
            </h1>
            <i className="text-xl font-bold">Frontend Developer</i>
            <br />
            <i className="text-xl font-semibold">Placed in PlanetSpark</i>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-center">
            <img src={rishbh} />
          </div>
          <div className="text-center">
            <h1
              className="text-[#0984F5] font-semibold  text-xl md:text-3xl mt-4 "
              style={{ fontFamily: "Poppins" }}
            >
              Rishabh Kumar
            </h1>
            <i className="text-xl font-bold">Software Engineer</i>
            <br />
            <i className="text-xl font-semibold">Placed in HCL</i>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-center">
            <img src={ashish} />
          </div>
          <div className="text-center">
            <h1
              className="text-[#0984F5] font-semibold  text-xl md:text-3xl mt-4 "
              style={{ fontFamily: "Poppins" }}
            >
              Ashish Singh
            </h1>
            <i className="text-xl font-bold">Android Developer</i>
            <br />
            <i className="text-xl font-semibold">Placed in Capegemini</i>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-center">
            <img src={amiya} />
          </div>
          <div className="text-center">
            <h1
              className="text-[#0984F5] font-semibold  text-xl md:text-3xl mt-4 "
              style={{ fontFamily: "Poppins" }}
            >
              Amiya Mishra
            </h1>
            <i className="text-xl font-bold">Software Engineer</i>
            <br />
            <i className="text-xl font-semibold">Placed in Cognizant</i>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-center">
            <img src={manan} />
          </div>
          <div className="text-center">
            <h1
              className="text-[#0984F5] font-semibold  text-xl md:text-3xl mt-4 "
              style={{ fontFamily: "Poppins" }}
            >
              Manan Sharma
            </h1>
            <i className="text-xl font-bold">Web Designer</i>
            <br />
            <i className="text-xl font-semibold">Placed in TCS</i>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-center">
            <img src={mithali} />
          </div>
          <div className="text-center">
            <h1
              className="text-[#0984F5] font-semibold  text-xl md:text-3xl mt-4 "
              style={{ fontFamily: "Poppins" }}
            >
              Mithali Raj
            </h1>
            <i className="text-xl font-bold">FullStack Developer</i>
            <br />
            <i className="text-xl font-semibold">Placed in Wipro</i>
          </div>
        </div>
      </div>

      {/* prices-section */}

      <div className="  mt-10 md:mt-20 mx-10 md:mx-20 lg:mx-28">
        <h1
          className=" font-bold  text-2xl md:text-4xl lg:text-5xl "
          style={{ fontFamily: "Poppins" }}
        >
          PRICES AND PLANS
          <p className="border-t-8 border-[#262566] w-[50%]  md:w-[20%]  my-3 "></p>
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mx-10 md:mx-20 lg:mx-28  mt-10 md:mt-16">
        <div className="mb-3">
          <h1 className=" font-bold  text-2xl md:text-3xl ">
            Step 1: Web Talent Gravity
          </h1>

          <ul className="list-disc pl-5 text-[#0984F5] font-medium text-xl md:text-2xl mt-5">
            <li>Frontend Training</li>
            <li>Internsip of 6 Month </li>
            
            <li className="mt-3">Professional Training</li>

            <li className="mt-3">Experience Certificate</li>

            <li className="mt-3">Placement Assurance</li>

            <li className="mt-3">AI Accelerate</li>

            <li className="mt-3">Logical Practice</li>
            <li className="mt-3">Live Projects</li>
            <li className="mt-3">10+ Interview calls</li>
            <li className="mt-3">5,000/Month  incentive for 3 month</li>
          </ul>
          <p className="mt-4">
            <i className="font-bold text-[#03519E]  text-2xl md:text-3xl">
              Also Free Demo Classes are Available
            </i>
          </p>
        </div>

        <div>
          <span className=" font-bold  text-[#0984F5] text-2xl md:text-3xl lg:text-4xl  ">
            ₹ 25,000 /-
          </span>

          <s className="text-red-500 font-bold  text-2xl md:text-3xl pl-3">
            ₹50,000/-
          </s>
          <h1 className="font-bold  text-[#0984F5] text-2xl pl-5 mt-4 ">
            *EMI Option Also
            <br />
            Available
          </h1>
        </div>
        

        <div className="mb-3">
          <h1 className=" font-bold  text-2xl md:text-3xl ">
            Step 2: Talent Gravity Path
          </h1>

          <ul className="list-disc pl-5 text-[#0984F5] font-medium text-xl md:text-2xl mt-5">
            <li>Frontend Training</li>
            <li className="mt-3">Professional Training</li>

            <li className="mt-3">Experience Certificate</li>

            <li className="mt-3">Placement Assurance</li>

            <li className="mt-3">AI Accelerate</li>

            <li className="mt-3">Logical Practice (Free Demo Class)</li>
            <li className="mt-3">Placement Guarantee</li>
            <li className="mt-3">Live Projects</li>
          </ul>
          <p className="mt-4">
            <i className="font-bold text-[#03519E]  text-xl ">
              #TALENT GRAVITY PATH IS APPLICABLE FOR WHOM CLEARED WEBTALENT
              GRAVITY MOCK INTERVIEW
            </i>
          </p>
        </div>

        <div>
          <span className=" font-bold  text-[#0984F5] text-2xl md:text-3xl lg:text-4xl  ">
            ₹ 4,999 /-
          </span>

          <h1 className="font-bold  text-[#0984F5] text-2xl pl-5 mt-4 ">
            *EMI Option Also
            <br />
            Available
          </h1>
        </div>

        <div>
          <h1 className=" font-bold  text-2xl md:text-3xl ">
          Step 3: Talent Support Path
          </h1>

          <ul className="list-disc pl-5 text-[#0984F5] font-medium text-xl md:text-2xl mt-5">
            <li>Notes</li>
            <li className="mt-3">Support for Career Build</li>

            <li className="mt-3">AI Accelerate (₹499 only)</li>

            <li className="mt-3">Logical Practice</li>

            <li className="mt-3">Placement Guarantee</li>

            <li className="mt-3">Experience Certificate</li>
           
          </ul>
       
        </div>

        <div>
          <span className=" font-bold  text-[#0984F5] text-2xl md:text-3xl lg:text-4xl  ">
          ₹ 299 /-
          </span>

      
        </div>


      </div>


        <div className="hidden  md:block mt-5 pointer-events-none ">
        <img src={Certificate2} className="w-[100%] h-auto"></img>
      </div>


        <div className="block md:hidden mt-5 ">
        <img src={Certificate} className="w-[100%] h-auto"></img>
      </div>


      {/* certification */}

      {/* <div className="mt-10 md:mt-20">
        <h2 className="mx-10 md:mx-0 text-2xl md:text-4xl font-bold md:text-center text-[#262566]">
          Certification by Graviton Web Technologies
        </h2>
        <p className="mx-10 md:mx-0 md:text-center mt-5 font-medium  text-lg">
          Graviton Web Technologies provides globally accredited certificate
          courses
          <br /> in Bangalore for IT related subjects.
        </p>
      </div>

      <div className="grid grid-cols-1  lg:grid-cols-3 gap-10 md:grid-cols-3 mx-10 md:mx-20 lg:mx-32 mt-10 md:mt-20">
        <div className=" border-2 p-8 webtalent-card">
          <AiOutlineFileWord className="bg-sky-500 text-white text-6xl font-semibold rounded-full" />

          <h1 className=" text-xl lg:text-3xl font-bold text-[#262566] mt-4">
            Interview Preparation
          </h1>

          <p className="text-[#64666C] mt-3  font-lg">
            The foremost key to get success in an interview is the ‘Resume’, the
            foundation of an individual career journey. When a candidate is
            contacted for an interview, they are analyzed for aptness as a
            budding employee.
          </p>
        </div>

        <div className=" border-2  p-8 webtalent-card">
          <FaHandshake className="bg-sky-500 text-white  text-6xl  font-semibold  rounded-full" />

          <h1 className="text-xl lg:text-3xl font-bold text-[#262566] mt-4">
            Real Time Internship
          </h1>

          <p className="text-[#64666C] mt-3 font-lg">
            The most important concept in the training course is ‘Internship’,
            gaining practice on a real-time project. We train and permit the
            students to work with senior authorities on the real-time project
            that gets into Gravitonweb Technologies
          </p>
        </div>

        <div className=" border-2  p-8 webtalent-card">
          <AiOutlineFileWord className="bg-sky-500 text-white  text-6xl  font-semibold  rounded-full" />

          <h1 className="text-xl lg:text-3xl font-bold text-[#262566] mt-4">
            Placement Training
          </h1>

          <p className="text-[#64666C] mt-3 font-lg ">
            Gravitonweb Technologies is the leading IT training institute in
            Bangalore. Gravitonweb Technologies not only provides in-depth
            training and knowledge but also helps the trainees to get lucrative
            job posts
          </p>
        </div>
      </div> */}

      {/* <div className="flex justify-center mt-10">
        <img src={Certificate} className="w-[70%] h-auto"></img>
      </div> */}

      {/* certification */}

      {/* Slider-part */}

      <div className="mt-5 ">
        <h1 className="text-xl md:text-3xl lg:text-4xl font-bold text-[#262566] text-center"  style={{ fontFamily: "Poppins" }}>
          
          CUSTOMERS FEEDBACK
        </h1>
      </div>

      <div className="mt-5 md:mt-10 mx-10 md:mx-20 lg:mx-30">
        <Slider {...WebtechSlideSettings1}>
          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide1} className="rounded-[50%]" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Palak Sharma
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
                Promotion via Workshop!!
              </h1>
              <p className="text-[#64666C] mt-3">
                They provide quality of service based on customer needs.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide2} className="rounded-[50%]" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Tarang Tripathi
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
                I could negotiate 40% salary hike
              </h1>
              <p className="text-[#64666C] mt-3">
                I recently had the opportunity to work with this company.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide3} className="rounded-[50%]" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Kunal Singh
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
                Literally a timesaver workshop!!
              </h1>
              <p className="text-[#64666C] mt-3 ">
                They are very sharp and have a high quality team members.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide4} className="rounded-[50%]" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Anshika dubey
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
                Colleague's Unexpected Recognition
              </h1>
              <p className="text-[#64666C] mt-3">
                Good company and quality products delivery on times.Nice
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide5} className="rounded-[50%] w-[80px] h-auto" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Ayush Jain
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
               Secured Exciting Placement
              </h1>
              <p className="text-[#64666C] mt-3">
              Placed in Programmics Technology through Graviton.
              </p>
            </div>
          </div>
        </Slider>
      </div>

      {/* slider-part */}

      {/* download-button */}
      <div className="mt-10 mb-10 text-center  ">
        <a
          href={WebTalentPdf}
          download="WebTalentBouchers.pdf"
          className="bg-gradient-to-r from-teal-800 to-blue-500 px-5 py-2 md:px-10 md:py-3
        rounded-[8px] text-white font-bold text-xl md:text-2xl"
        >
          <button>Download PDF</button>
        </a>
      </div>

      {/* <div>
      <embed
        src={WetTalentPdf} 
        type="application/pdf"
        width="100%"
        height={showFullPdf ? '500px' : '200px'}
      />
      <button onClick={() => setShowFullPdf(!showFullPdf)}>
        {showFullPdf ? 'Show Less' : 'Show More'}
      </button>
    </div> */}

      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10 mt-5 md:mt-20 mx-5 md:mx-20 lg:mx-28">
        <div
          className="pdf-container overflow-x-hidden bg-white w-full p-10 rounded-lg shadow-md hidden lg:block"
          style={{ backgroundColor: "white", width: "100%" }}
        >
          <iframe
            src={WebTalentPdf+'#toolbar=0'}
            title="PDF Viewer 1"
            width="100%"
            height="400px"
            frameBorder="0"
          />

          <div className="mt-10">
          <a
          href={WebTalentPdf}
          download="WebTalentBouchers.pdf"
          className="bg-gradient-to-r from-teal-800 to-blue-500 px-5 py-2
           rounded-[4px] text-white font-semibold text-xl"
        >
          <button>Download PDF</button>
         </a>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default WebTalentGravity;
