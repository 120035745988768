import React, { useState , useEffect} from "react";
import { useParams } from "react-router";

  const Payment = () => {
  const { price } = useParams();
  const [stateValue, setStateValue] = useState(true);
  const Payment = () => {
  const min = 1000000000; // Smallest 10-digit number
  const max = 9999999999; // Largest 10-digit number
  const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
  const min1 = 10000; // Smallest 5-digit number
  const max1 = 99999; // Largest 5-digit number
  const randomValue1 = Math.floor(Math.random() * (max1 - min1 + 1)) + min1;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      amount: price * 100,
      trnsId: randomValue,
      userid: randomValue1,
      phoneNo: 99999999,
      name: "Candidate",
      email: "Candidate@gmail.com",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://gravitonweb.co.in/main/initiate_phonepe_payment/",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setStateValue(false);
        window.location.href = result?.pay_page_url;
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    Payment();
  }, []);

  return (
    <>
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-2xl font-semibold">Redirecting...</p>
      </div>
    </>
  );
};

export default Payment;
