import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsMicrosoft, BsAndroid2, BsFillNodeMinusFill } from "react-icons/bs";
import { SiCoinmarketcap, SiWebpack } from "react-icons/si";
import { GiTreeGrowth } from "react-icons/gi";
import featuresimage1 from "../../assets/Images/Homepageimages/featuresimage1.jpg";
import featuresimage2 from "../../assets/Images/Homepageimages/featuresimage2.jpg";
import featuresimage3 from "../../assets/Images/Homepageimages/featuresimage3.jpg";
import featuresimage4 from "../../assets/Images/Homepageimages/featuresimage4.jpg";
import featuresimage5 from "../../assets/Images/Homepageimages/featuresimage5.jpg";
import featuresimage6 from "../../assets/Images/Homepageimages/featuresimage6.jpg";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faChartLine,
  faGlobe,
  faBriefcase,
  faHandshake,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import Video from "../../assets/Images/Homepageimages/Vid_blue.mp4";
import { FiMonitor } from "react-icons/fi";
import { BsGraphUp } from "react-icons/bs";
import { FaLaptop } from "react-icons/fa";
import "./Home.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../../assets/Images/Homepageimages/slide7.png";
import slide2 from "../../assets/Images/Homepageimages/slide8.png";
import slide3 from "../../assets/Images/Homepageimages/slide9.png";
import slide4 from "../../assets/Images/Homepageimages/slide6.png";
import slide5 from "../../assets/Images/Homepageimages/slide10.png";
import HomeBanner from "../../assets/Images/Homepageimages/home-banner-image1.png";
import NavigationBar from "../../common/NavigationBar";
import Typewriter from "typewriter-effect";
import WhyChooseImg1 from "../../assets/Images/Homepageimages/whychooseimage1.svg";
import WhyChooseImg2 from "../../assets/Images/Homepageimages/whychooseimage2.png";
import WhyChooseImg3 from "../../assets/Images/Homepageimages/whychooseimage3.svg";
import WhyChooseImg4 from "../../assets/Images/Homepageimages/whychooseimage4.png";
import WhyChooseImg5 from "../../assets/Images/Homepageimages/whychooseimage5.svg";
import OfferText from "../../assets/Images/Homepageimages/offertextimg.svg";
import OfferImg from "../../assets/Images/Homepageimages/offerimg1.png";
import Accelerate from "../../assets/Images/Homepageimages/accelerateimg1.png";
import ServiceOffer from "../../assets/Images/Homepageimages/servicesofferimg1.png";
import AboutText from "../../assets/Images/Homepageimages/abouttextimg.svg";
import AboutImg from "../../assets/Images/Homepageimages/aboutimg1.png";
import FeatureText from "../../assets/Images/Homepageimages/ourfeaturestextimg.svg";
import TestimonialText from "../../assets/Images/Homepageimages/Testimonialtextimg.svg";
import RecentImage1 from "../../assets/Images/Homepageimages/recent1.png";
import RecentImage2 from "../../assets/Images/Homepageimages/recent2.png";
import RecentImage3 from "../../assets/Images/Homepageimages/recent3.png";
import RecentImage4 from "../../assets/Images/Homepageimages/recent4.png";
import RecentImage5 from "../../assets/Images/Homepageimages/recent5.png";
import RecentImage6 from "../../assets/Images/Homepageimages/recent6.png";
import RecentImage7 from "../../assets/Images/Homepageimages/recent7.png";
import RecentImage8 from "../../assets/Images/Homepageimages/recent9.png";
import CountUp from "react-countup";
import ScrollReveal from "scrollreveal";
import { useInView } from "react-intersection-observer";

const Home = () => {
  const homeSlideSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const isSmallScreen = window.innerWidth <= 768;

  if (isSmallScreen) {
    homeSlideSettings.slidesToShow = 1;
  }

  useEffect(() => {
    const parallax = document.getElementById("home-banner-parallax");

    const handleScroll = () => {
      let offset = window.pageYOffset;
      parallax.style.backgroundPositionY = offset * -0.4 + "px";
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // animation part
  const [ref1, inView1] = useInView({
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    triggerOnce: true,
  });
  const [ref3, inView3] = useInView({
    triggerOnce: true,
  });
  const [ref4, inView4] = useInView({
    triggerOnce: true,
  });
  const [ref5, inView5] = useInView({
    triggerOnce: true,
  });
  const [ref6, inView6] = useInView({
    triggerOnce: true,
  });

  return (
    <>
      {/* banner */}

      <div className="home-banner-section" id="home-banner-parallax">
        <NavigationBar />
        <div className="text-white pt-36 md:pt-40 px-5  md:px-10 lg:px-20">
          <h1 className="text-2xl sm:text-3xl font-semibold text-[#00FFCA] leading-[60px]">
            WELCOME TO
          </h1>
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold mt-2">
            GRAVITON WEB
          </h1>
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold mt-2">
            TECHNOLOGY
          </h1>
          <p className="w-full w-60 sm:w-80 md:w-96 mt-4">
            1300+ leading global brands have trusted Codup to support their
            engineering needs and build custom web & mobile apps.
          </p>
          <h1 className="text-3xl text-[#00FFCA] font-semibold leading-[72px]">
            <Typewriter
              options={{
                strings: ["Development", "Marketing", "Consultancy"],
                autoStart: true,
                loop: true,
                typeSpeed: 50,
              }}
            />
          </h1>
          <Link to="/digitalwebinar">
            <button
              style={{
                background:
                  "linear-gradient(0deg, #0A4D68 8.68%, #05BFDB 140.45%)",
                transition: "all 0.5s",
              }}
              className="text-white rounded-[4px] px-10 py-2 mt-4 home-hover-button1"
            >
              Explore
            </button>
          </Link>
          <Link to="/contact">
            <button
              style={{
                transition: "transform 0.5s",
              }}
              className="text-white rounded-[4px] px-6 py-2 border-2 border-[#05BFDB] ml-5 mt-4 
          home-hover-button2"
            >
              Get In Touch
            </button>
          </Link>
        </div>
      </div>

      {/* banner */}

      {/* whychoose*/}

      <div className="mt-10 md:mt-12">
        <div className="flex justify-center">
          <img src={WhyChooseImg1} className="w-3/4 h-3/4"></img>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mx-10 md:mx-20 lg:mx-32 mt-10 md:mt-18">
          <div className="" ref={ref1}>
            {inView1 && (
              <img
                src={WhyChooseImg2}
                className="opacity-1 animate__animated animate__slideInLeft animate-slowly"
              />
            )}
          </div>

          <div>
            <p className="text-base md:text-base lg:text-md xl:text-lg text-[#525252] font-normal">
              At Graviton Web Technologies, we understand that behind every
              business, there is a dream, a vision that ignites passion and
              drives ambition. That’s why choosing us means choosing a partner
              who genuinely cares about your success.
            </p>

            <div className="grid grid-cols-3 gap-5 mt-5">
              <div>
                <h3 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-5xl font-medium">
                  <CountUp start={0} end={16} duration={10} />
                  <span>+</span>
                </h3>
                <p className="text-sm mt-2 text-[#3C3C3C]">
                  Years Of IT Experience
                </p>
                <hr className="border-b border-black w-1/2 mt-2"></hr>
              </div>

              <div>
                <h3 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-5xl font-medium">
                  <CountUp start={0} end={200} duration={10} />
                  <span>+</span>
                </h3>
                <p className="text-sm mt-2 text-[#3C3C3C]">
                  Customers 30+ Countries
                </p>
                <hr className="border-b border-black w-1/2 mt-2"></hr>
              </div>

              <div>
                <h3 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-5xl font-medium">
                  <CountUp start={0} end={850} duration={10} />
                  <span>+</span>
                </h3>
                <p className="text-sm mt-2 text-[#3C3C3C]">
                  Delivered Projects
                </p>
                <hr className="border-b border-black w-1/2  mt-2"></hr>
              </div>
            </div>

            <div className="mt-8">
              <img src={WhyChooseImg4}></img>
            </div>

            {/* <div className="">
              <Link to="https://www.linkedin.com/company/graviton-web-technologies/">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="text-white m-3 w-[20px] h-[20px] 
                 rounded-[50%] p-2 sm:p-4"
                  style={{
                    background:
                      "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
                  }}
                />
              </Link>

              <Link to="https://www.facebook.com/Gravitonwebtechnologies?mibextid=ZbWKwL">
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="text-white m-3 w-[20px] h-[20px]
                 rounded-[50%] p-2 sm:p-4"
                  style={{
                    background:
                      "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
                  }}
                />
              </Link>

              <Link to="https://instagram.com/graviton_web_technologies?igshid=NjIwNzIyMDk2Mg==">
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="text-white m-3 w-[20px] h-[20px]
                 rounded-[50%] p-2 sm:p-4"
                  style={{
                    background:
                      "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
                  }}
                />
              </Link>
            </div> */}

            <ul className="wrapper">
              <li className="icon facebook">
                <span className="tooltip">Facebook</span>

                <Link to="https://www.facebook.com/Gravitonwebtechnologies?mibextid=ZbWKwL">
                  <FontAwesomeIcon icon={faFacebook} />
                </Link>
              </li>

              <li className="icon instagram">
                <span className="tooltip">Instagram</span>

                <Link to="https://instagram.com/graviton_web_technologies?igshid=NjIwNzIyMDk2Mg==">
                  <FontAwesomeIcon icon={faInstagram} />
                </Link>
              </li>

              <li className="icon linkedin">
                <span className="tooltip">LinkedIn</span>

                <Link to="https://www.linkedin.com/company/graviton-web-technologies/">
                  <FontAwesomeIcon icon={faLinkedin} />
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-10 md:mt-20">
          <img src={WhyChooseImg5} className="w-[100%] h-auto"></img>
        </div>
      </div>
      {/* why choose */}

      {/* services cards */}
      <div className="mt-10 md:mt-20">
        <div className="text-center">
          <span className="text-[#14b1bb] font-bold text-lg">
            What We Provide
          </span>

          <h1 className="text-[#262566] font-bold text-4xl mt-3">
            Our Services
          </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mx-10 md:mx-20 lg:mx-32 mt-10 md:mt-20">
          <div className="border-l-4 border-[#14b1bb] p-8 shadow-2xl home-services-slide">
            <BsMicrosoft className="w-10 h-10 home-services-icon" />

            <h1 className="home-services-heading  font-bold text-xl mt-5">
              Software Development
            </h1>

            <p className="home-services-paragraph  mt-4">
              We specialize in providing custom software development services,
              delivering scalable and innovative applications that empower
              businesses to streamline processes, enhance productivity, and
              achieve their goals.
            </p>
          </div>

          <div className="p-8 shadow-2xl border-l-4 border-[#14b1bb] home-services-slide">
            <BsAndroid2 className=" w-10 h-10  home-services-icon " />

            <h1 className="home-services-heading font-bold text-xl mt-5">
              Andriod Development
            </h1>

            <p className="home-services-paragraph mt-4">
              We specialize in Android app development, offering end-to-end
              solutions from ideation and design to development, testing, and
              deployment, tailored to meet your specific business needs.
            </p>
          </div>

          <div className="p-8 shadow-2xl border-l-4 border-[#14b1bb] home-services-slide">
            <BsFillNodeMinusFill className=" w-10 h-10 home-services-icon" />

            <h1 className="home-services-heading font-bold text-xl mt-5">
              Neuromaketing
            </h1>

            <p className="home-services-paragraph  mt-4">
              With our expertise in both software development and
              neuromarketing, we provide tailored solutions that help businesses
              understand and influence consumer behavior, resulting in improved
              marketing.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mx-10 md:mx-20 lg:mx-32 mt-10  md:mt-20">
          <div className="p-8 shadow-2xl border-l-4 border-[#14b1bb] home-services-slide">
            <SiCoinmarketcap className=" w-10 h-10 home-services-icon" />

            <h1 className="home-services-heading font-bold text-xl mt-5">
              Digital marketing
            </h1>

            <p className="home-services-paragraph mt-4">
              With our expertise in software development and digital marketing,
              we provide tailored solutions to optimize your online visibility,
              boost brand awareness, and maximize ROI, utilizing cutting-edge
              tools.
            </p>
          </div>

          <div className="p-8 shadow-2xl border-l-4 border-[#14b1bb] home-services-slide">
            <SiWebpack className=" w-10 h-10  home-services-icon" />

            <h1 className="home-services-heading font-bold text-xl mt-5">
              Webtalent gravity
            </h1>

            <p className="home-services-paragraph mt-4">
              We provides top-notch training and placement solutions, equipping
              individuals with industry-relevant skills and connecting them with
              leading software companies for rewarding career opportunities.
            </p>
          </div>

          <div className="p-8 shadow-2xl border-l-4 border-[#14b1bb] home-services-slide">
            <GiTreeGrowth className=" w-10 h-10 home-services-icon" />

            <h1 className="home-services-heading font-bold text-xl mt-5">
              Grow your business
            </h1>

            <p className="home-services-paragraph mt-4">
              Experience exponential business growth through our innovative
              software services, enabling you to expand your market reach,unlock
              new opportunities and stay ahead of the competition in the digital
              era.
            </p>
          </div>
        </div>
      </div>
      {/* services cards */}

      {/* service 1 part */}

      <div className="mt-10 md:mt-20">
        <div className="flex justify-center">
          <img src={OfferText} className="w-3/4 h-3/4 md:w-1/2 md:h-1/2"></img>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mx-10 md:mx-20 lg:mx-32 mt-10 md:mt-18">
          <div>
            <p className="text-base md:text-base lg:text-md xl:text-lg text-[#525252] font-normal">
              For a limited time, we are thrilled to offer an exclusive courses.
              Don't miss out on this opportunity to invest in your future.
              Whether you're a beginner looking to start your tech journey or a
              seasoned professional aiming to upskill, our courses cater to all
              levels.
            </p>

            <p className="text-base md:text-base lg:text-md xl:text-lg text-[#525252] font-normal mt-4">
              Are you ready to embrace the future of technology and enhance your
              skills? At Gravitonwebtech, we're excited to introduce our
              technology courses designed to propel you into a realm of
              innovation and expertise.
            </p>
          </div>

          {/* <div className="flex justify-center">
            <img src={OfferImg} className="w-[100%] md:w-[70%]"></img>
          </div> */}
          <div className=" flex justify-center" ref={ref2}>
            {inView2 && (
              <img
                src={OfferImg}
                className="w-[100%] md:w-[70%] opacity-1 animate__animated animate__slideInRight animate-slowly"
              />
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-24 mx-10 md:mx-20 lg:mx-32 mt-10 md:mt-20">
          <div className="border-2 border-[#05BFDB] p-4 rounded-[4px]">
            <img src={ServiceOffer}></img>
            <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mt-4">
              Website and Android App
            </h1>
            <p className="text-base text-[#464646] font-normal mt-2">
              To get offers for Website and Android App Development , checkout
              our Service Offer section.
            </p>
            <Link to="/digitalGravity">
              <button
                className="mt-5 w-full text-lg text-white py-3 font-semibold rounded-[4px]"
                style={{
                  background:
                    "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
                }}
              >
                Read more
              </button>
            </Link>
          </div>

          <div className="border-2 border-[#05BFDB] p-4 rounded-[4px]">
            <img src={Accelerate}></img>
            <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mt-4">
              AI Accelerate
            </h1>
            <p className="text-base text-[#464646] font-normal mt-2">
              To get offers for AI Accelerate and all the courses, checkout our
              AI Accelerate section.
            </p>
            <Link to="/aiAccelerate">
              <button
                className="mt-5 w-full text-lg text-white py-3 font-semibold rounded-[4px]"
                style={{
                  background:
                    "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
                }}
              >
                Read more
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* service 1 part */}

      {/* About us*/}

      <div className="mt-10 md:mt-20">
        <div className="flex justify-center">
          <img src={AboutText} className="w-3/4 h-3/4 md:w-1/2 md:h-1/2"></img>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mx-10 md:mx-20 lg:mx-32 mt-5 md:mt-18">
          <div>
            <p className="text-base md:text-base lg:text-md xl:text-lg text-[#525252] font-normal">
              A software company is a dynamic entity at the heart of the modern
              technological landscape. These companies are dedicated to the
              creation, development, and maintenance of software applications
              that power nearly every aspect of our lives. From small startups
              to multinational giants, software companies are instrumental in
              shaping the digital world.
            </p>

            <p className="text-base md:text-base lg:text-md xl:text-lg text-[#525252] font-normal mt-4">
              Software companies assemble teams of highly skilled professionals,
              including software engineers, designers, project managers, and
              quality assurance experts. Together, they conceive, design, and
              code software solutions that address a wide range of needs. These
              needs can encompass mobile apps, web applications, enterprise
              software, gaming platforms, and much more.
            </p>
          </div>

          {/* <div className="">
            <img
              src={AboutImg}
              className="w-[100%] md:w-[90%]"
              alt="About Us"
            />
          </div> */}
          <div className="" ref={ref3}>
            {inView3 && (
              <img
                src={AboutImg}
                className="w-[100%] md:w-[90%] opacity-1 animate__animated animate__slideInRight animate-slowly"
              />
            )}
          </div>
        </div>
      </div>
      {/*  About us*/}

      {/* blog */}
      <div className="mt-10 md:mt-20">
        <div className="text-center">
          <span className="text-[#14b1bb] font-bold text-lg">
            Blog & Articles
          </span>

          <h1 className="text-[#262566] font-bold text-4xl mt-3">
            Recent Blog
          </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10  mx-10 md:mx-20 lg:mx-32 mt-10 md:mt-20">
          <div className="">
            <div class="blog-effect-box">
              <img src={featuresimage1} alt=""></img>

              <div class="blog-effect-layer">
                <h4 className="text-2xl font-semibold">PLANNING</h4>
              </div>
            </div>

            <div className="p-8 shadow-2xl">
              <h3 className="text-[#204066] text-xl  font-bold">PLANNING</h3>
              <p className="mt-3  text-[#525252]">
                Efficient planning ensures success; organize, prioritize, and
                execute for optimal outcomes.
              </p>
            </div>
          </div>

          <div>
            <div class="blog-effect-box">
              <img src={featuresimage2} alt=""></img>

              <div class="blog-effect-layer">
                <h4 className="text-2xl font-semibold">CONSULTANCY</h4>
              </div>
            </div>

            <div className="p-8 shadow-2xl">
              <h3 className="text-[#204066] text-xl font-bold">CONSULTANCY</h3>
              <p className="mt-3  text-[#525252]">
                Expert consultancy guides strategy, offering tailored solutions
                for informed decisions.
              </p>
            </div>
          </div>

          <div>
            <div class="blog-effect-box">
              <img src={featuresimage3} alt=""></img>

              <div class="blog-effect-layer">
                <h4 className="text-2xl font-semibold">RESEARCH</h4>
              </div>
            </div>

            <div className="p-8 shadow-2xl">
              <h3 className="text-[#204066] text-xl  font-bold">RESEARCH</h3>
              <p className="mt-3  text-[#525252]">
                Thorough research fuels innovation, unlocking knowledge and
                insights to drive progress.
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10  mx-10 md:mx-20 lg:mx-32 mt-10 md:mt-20">
          <div>
            <div class="blog-effect-box">
              <img src={featuresimage4} alt=""></img>

              <div class="blog-effect-layer">
                <h4 className="text-2xl font-semibold">MARKETING</h4>
              </div>
            </div>

            <div className="p-8 shadow-2xl">
              <h3 className="text-[#204066] text-xl  font-bold">MARKETING</h3>
              <p className="mt-3  text-[#525252]">
                Strategic marketing captivates audiences, shaping brands, and
                driving business.
              </p>
            </div>
          </div>

          <div>
            <div class="blog-effect-box">
              <img src={featuresimage5} alt=""></img>

              <div class="blog-effect-layer">
                <h4 className="text-2xl font-semibold">BUSINESS ANALYTICS</h4>
              </div>
            </div>

            <div className="p-8 shadow-2xl">
              <h3 className="text-[#204066] text-xl font-bold">
                BUSINESS ANALYTICS
              </h3>
              <p className="mt-3  text-[#525252]">
                Business analytics leverages data to uncover actionable
                insights, driving informed.
              </p>
            </div>
          </div>

          <div>
            <div class="blog-effect-box">
              <img src={featuresimage6} alt=""></img>

              <div class="blog-effect-layer">
                <h4 className="text-2xl font-semibold">BRANDING</h4>
              </div>
            </div>

            <div className="p-8 shadow-2xl">
              <h3 className="text-[#204066] text-xl font-bold">BRANDING</h3>
              <p className="mt-3  text-[#525252]">
                Effective branding creates an identity that resonates, leaving a
                lasting impression.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* blog */}

      {/* portfolio */}

      <div className="mt-10 md:mt-28">
        <div className="text-center">
          <h1 className="text-[#262566] font-bold text-4xl mt-3">
            Our Portfolio
          </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10  mx-10 md:mx-20 lg:mx-32 mt-10 md:mt-20">
          <div
            class="recent-work-card border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
               shadow-xl"
          >
            <img
              src={RecentImage1}
              alt=""
              className="border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
               shadow-lg  "
            />

            <div class="recent-effect-layer">
              <h4 className="text-2xl font-semibold">
                <Link to="https://hola9.com/">HOLA 9</Link>
              </h4>
            </div>
          </div>

          <div
            class="recent-work-card border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
               shadow-xl "
          >
            <img
              src={RecentImage2}
              alt=""
              className="border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
               shadow-lg   "
            />

            <div class="recent-effect-layer">
              <h4 className="text-2xl font-semibold">
                <Link to="https://www.lasereyeinstitute.com/">
                  LASER EYE <br />
                  INSTITUTE
                </Link>
              </h4>
            </div>
          </div>

          <div
            class="recent-work-card border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
               shadow-xl "
          >
            <img
              src={RecentImage3}
              alt=""
              className="border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
                  shadow-lg  "
            />

            <div class="recent-effect-layer">
              <h4 className="text-2xl font-semibold">
                <Link to="https://ahne.in/">
                  AHNE <br />
                  CONSULTANCY
                  <br /> SERVICE
                </Link>
              </h4>
            </div>
          </div>

          <div
            class="recent-work-card border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
               shadow-xl "
          >
            <img
              src={RecentImage4}
              alt=""
              className="border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
                  shadow-lg "
            />

            <div class="recent-effect-layer">
              <h4 className="text-2xl font-semibold">
                <Link to="https://procareers.in/">PRO CAREERS</Link>
              </h4>
            </div>
          </div>

          <div
            class="recent-work-card border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
               shadow-xl "
          >
            <img
              src={RecentImage5}
              alt=""
              className="border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
                  shadow-lg "
            />

            <div class="recent-effect-layer">
              <h4 className="text-2xl font-semibold">
                <Link to="https://ibspl.com/">
                  ISMART
                  <br /> BUSINESS <br />
                  SOLUTION
                </Link>
              </h4>
            </div>
          </div>

          <div
            class="recent-work-card border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
               shadow-xl "
          >
            <img
              src={RecentImage6}
              alt=""
              className="border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
                  shadow-lg   "
            />

            <div class="recent-effect-layer">
              <h4 className="text-2xl font-semibold">
                <Link to="https://chopraemb.com/">
                  CHOPRA
                  <br /> EMBOIDERY
                  <br /> MILLS
                </Link>
              </h4>
            </div>
          </div>

          <div
            class="recent-work-card border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
               shadow-xl "
          >
            <img
              src={RecentImage7}
              alt=""
              className="border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
                  shadow-lg  "
            />

            <div class="recent-effect-layer">
              <h4 className="text-2xl font-semibold">
                <Link to="">GROWW CAPITALS</Link>
              </h4>
            </div>
          </div>

          <div
            class="recent-work-card border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
               shadow-xl "
          >
            <img
              src={RecentImage8}
              alt=""
              className="border border-white rounded-bl-2xl rounded-br-2xl rounded-tr-2xl
                  shadow-lg "
            />

            <div class="recent-effect-layer">
              <h4 className="text-2xl font-semibold">
                <Link to="https://acechronicle.in/">ACE CHRONICLE</Link>
              </h4>
            </div>
          </div>
        </div>
      </div>

      {/* Portfolio end */}

      {/* video part */}

      <div className="grid grid-cols-1 lg:grid-cols-2 mt-10 md:mt-28 mx-10 md:mx-20 lg:mx-32 gap-14 ">
        <div className="relative">
          <video className="w-full" autoPlay muted loop>
            <source src={Video} type="video/mp4" />
          </video>

          <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-5xl font-semibold ">
            Graviton
          </h1>
        </div>

        <div className=" ">
          <h1 className="text-4xl font-bold text-[#262566]">
            Why We Are Different?
          </h1>

          <div className="mt-8 flex">
            <div>
              <FontAwesomeIcon
                icon={faHouse}
                className="text-3xl text-[#14b1bb] "
              />
            </div>

            <div className="ml-5">
              <h1 className="text-2xl font-semibold">Our Company History</h1>

              <p className="mt-2 font-normal">
                We stand out through innovation, dedication, and excellence. Our
                unique approach, unwavering commitment, and constant evolution
                set us apart, delivering exceptional results and unmatched
                experiences.
              </p>
            </div>
          </div>

          <div className="mt-5 flex">
            <div>
              <FontAwesomeIcon
                icon={faChartLine}
                className="text-3xl text-[#14b1bb] "
              />
            </div>

            <div className="ml-5">
              <h1 className="text-2xl font-semibold">Our Success Mission</h1>

              <p className="mt-2 font-normal">
                Igniting potential, driving growth. We provide tailored
                solutions, coaching, and resources to transform aspirations into
                achievements. Your success, our mission."
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* video part */}

      {/*Features new cards */}

      <div className="mt-10 md:mt-20">
        <div className="flex justify-center">
          <img
            src={FeatureText}
            className="w-3/4 h-3/4 md:w-1/2 md:h-1/2"
          ></img>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 mt-10  mx-10 md:mx-20 lg:mx-32 gap-14">
          <div className="home-features-section " ref={ref4}>
            {inView4 && (
              <div className=" text-white py-16 px-10 bg-[#14b1bb]  opacity-1 animate__animated animate__slideInLeft animate-slowly ">
                <div className=" flex justify-center ">
                  <FiMonitor className="w-14 h-14" />
                </div>

                <h2 className="text-3xl mt-4 font-semibold text-center">
                  Awesome Work Place
                </h2>

                <p className="text-lg mt-4 text-center">
                  An awesome workplace fosters collaboration, supports
                  innovation, and offers growth opportunities. With a diverse,
                  inclusive culture and inspiring leadership, it's exceptional.
                </p>
              </div>
            )}
          </div>

          <div className="home-features-section " ref={ref5}>
            {inView5 && (
              <div className="text-white   py-16 px-10 bg-[#14b1bb]  opacity-1 animate__animated animate__slideInUp animate-slowly  ">
                <div className=" flex justify-center ">
                  <BsGraphUp className="w-14 h-14" />
                </div>

                <h2 className="text-3xl mt-4 font-semibold text-center">
                  Unlimited Facility
                </h2>

                <p className="text-lg mt-4 text-center">
                  Unlimited facility offers boundless convenience and resources,
                  ensuring seamless access to what you need, whenever you need
                  it, without restrictions.
                </p>
              </div>
            )}
          </div>

          <div className="home-features-section " ref={ref6}>
            {inView6 && (
              <div className="text-white   py-16 px-10 bg-[#14b1bb]  opacity-1 animate__animated animate__slideInRight animate-slowly ">
                <div className=" flex justify-center ">
                  <FaLaptop className="w-14 h-14" />
                </div>

                <h2 className="text-3xl mt-4 font-semibold text-center">
                  Responsive Design
                </h2>

                <p className="text-lg mt-4 text-center">
                  Responsive design adapts flawlessly to various devices and
                  screen sizes, ensuring an optimal user experience, higher
                  engagement, and broader accessibility.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/*Features new cards */}

      {/* contact */}
      <div
        className="min-h-[250px] w-[100%] p-4 flex flex-col md:flex-row justify-evenly items-center mt-14"
        style={{
          background: "linear-gradient(180deg, #0A4D68 0%, #0082B7 100%)",
        }}
      >
        <div className="text-white font-bold text-2xl p-4 rounded md:m-4 md:w-1/2 lg:w-1/3">
          <p>Have you any question? Ask Us anything, we’d love to answer!</p>
        </div>

        <div className="w-full md:w-auto m-3 p-3">
          <Link to="/contact">
            <button
              type="submit"
              className="bg-[#05BFDB] mt-4 md:mt-0 lg:mt-0 ml-0 md:ml-4 lg:ml-4 px-10 py-3 rounded-[8px] text-white text-lg font-semibold"
            >
              Let's Connect
            </button>
          </Link>
        </div>
      </div>
      {/* contact */}

      {/* testominal */}
      <div className="mt-10 md:mt-20">
        <div className="flex justify-center">
          <img
            src={TestimonialText}
            className="w-3/4 h-3/4 md:w-1/2 md:h-1/2"
          ></img>
        </div>
      </div>

      <div className="mt-10 mx-10 md:mx-20 lg:mx-30">
        <Slider {...homeSlideSettings}>
          <div className="p-4">
            <div
              className="border border-white rounded-tl-3xl rounded-tr-3xl rounded-br-3xl
                p-5 shadow-md bg-[#0f7a81] p-5"
            >
              <div className="flex justify-center ">
                <img src={slide1} alt="slide1" className="rounded-[50%]" />
              </div>
              <div className="text-center text-white">
                <h1 className=" text-2xl mt-5  font-semibold">Palak Sharma</h1>
                <p className="mt-5 text-lg font-lg">
                  They provide quality of service based on customer needs.
                </p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />
              </div>
            </div>
          </div>

          <div className="p-4">
            <div
              className="border border-white rounded-tl-3xl rounded-tr-3xl rounded-br-3xl
              p-5 shadow-md bg-[#0f7a81] p-5"
            >
              <div className="flex justify-center ">
                <img src={slide2} alt="slide1" className="rounded-[50%]" />
              </div>
              <div className="text-center text-white">
                <h1 className=" text-2xl mt-5  font-semibold">
                  Tarang Tripathi
                </h1>
                <p className="mt-5 text-lg font-lg">
                  I recently had the opportunity to work with this company.
                </p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />
              </div>
            </div>
          </div>

          <div className="p-4">
            <div
              className="border border-white rounded-tl-3xl rounded-tr-3xl rounded-br-3xl
               p-5 shadow-md bg-[#0f7a81] p-5"
            >
              <div className="flex justify-center ">
                <img src={slide3} alt="slide1" className="rounded-[50%]" />
              </div>
              <div className="text-center text-white">
                <h1 className=" text-2xl mt-5  font-semibold">Kunal Singh</h1>
                <p className="mt-5 text-lg font-lg">
                  They are very sharp and have a high quality team members.
                </p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />
              </div>
            </div>
          </div>

          <div className="p-4">
            <div
              className="border border-white rounded-tl-3xl rounded-tr-3xl rounded-br-3xl
               p-5 shadow-md bg-[#0f7a81] p-5"
            >
              <div className="flex justify-center ">
                <img
                  src={slide4}
                  alt="slide1"
                  className="rounded-[50%] w-[80px] h-auto"
                />
              </div>
              <div className="text-center text-white">
                <h1 className=" text-2xl mt-5  font-semibold">Anshika Dubey</h1>
                <p className="mt-5 text-lg font-lg">
                  Good company and quality products delivery on times.Nice
                </p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />
              </div>
            </div>
          </div>


          <div className="p-4">
            <div
              className="border border-white rounded-tl-3xl rounded-tr-3xl rounded-br-3xl
               p-5 shadow-md bg-[#0f7a81] p-5"
            >
              <div className="flex justify-center ">
                <img
                  src={slide5}
                  alt="slide1"
                  className="rounded-[50%] w-[80px] h-auto"
                />
              </div>
              <div className="text-center text-white">
                <h1 className=" text-2xl mt-5  font-semibold">Ayush Jain</h1>
                <p className="mt-5 text-lg font-lg">
                 Placed in Programmics Technology through Graviton.
                </p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-yellow-400 mt-4"
                />
              </div>
            </div>
          </div>

        </Slider>
      </div>

      {/* testominal */}
    </>
  );
};

export default Home;
