import React, { useEffect } from "react";

import CountUp from "react-countup";
import NavigationBar from "../../common/NavigationBar";
import secondheading from "../../assets/Images/certficatepageimg/secondheading2.svg";
import thirdcertificate from "../../assets/Images/certficatepageimg/certificate3.svg";
import certificateprogram from "../../assets/Images/certficatepageimg/cuate.svg";
import topleft from "../../assets/Images/certficatepageimg/lefttop.svg";
import topright from "../../assets/Images/certficatepageimg/righttop.svg";
import bottomleft from "../../assets/Images/certficatepageimg/leftbottom.svg";
import rightbottom from "../../assets/Images/certficatepageimg/bottomright.svg";
import frontendimge from "../../assets/Images/certficatepageimg/frontendimg.png";
import backendimge from "../../assets/Images/certficatepageimg/backendimg.png";
import fullstackimge from "../../assets/Images/certficatepageimg/fullstckimg.png";
import artificailimge from "../../assets/Images/certficatepageimg/AIimg.png";
import pythonimge from "../../assets/Images/certficatepageimg/pythonimg.png";


import "./Certfication.css";

const CertificationProgram = () => {
  useEffect(() => {
    const parallax = document.getElementById("certificates-banner-parallax");

    const handleScroll = () => {
      let offset = window.pageYOffset;
      parallax.style.backgroundPositionY = offset * -0.4 + "px";
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {/* banner start */}
      <div
        className="certificates-banner-section"
        id="certificates-banner-parallax"
      >
        <NavigationBar />

        <div className="h-screen flex items-center">
          <div className="text-white px-5 md:px-10 lg:px-20 ">
            <h1 className="text-2xl sm:text-3xl font-semibold leading-[60px]">
              GET CERTIFIED AT
            </h1>

            <h1 className="text-3xl sm:text-3xl text-[#00FFCA] md:text-4xl lg:text-4xl font-semibold mt-2">
              GRAVITON WEB TECH
            </h1>

            <p className="w-full w-60 sm:w-80 md:w-96 mt-4">
              Empowering Innovation, Transforming Futures
            </p>

            <button
              style={{
                background:
                  "linear-gradient(0deg, #0A4D68 8.68%, #05BFDB 140.45%)",

                transition: "all 0.5s",
              }}
              className="text-white rounded-[4px] px-10 py-2 mt-4 home-hover-button1"
            >
              GET CERTIFIED TODAY
            </button>
          </div>
        </div>
      </div>
      {/* banner end */}

      {/* Tranning Certificate Start*/}
      <div className="flex justify-center mt-10 ">
        <img src={secondheading} className="w-3/4 h-3/4 " alt="Our Services" />
      </div>

      <div className="justify-center mt-10">
        <img
          src={thirdcertificate}
          className="certificate-banner-image"
          alt="certificates"
        ></img>
      </div>
      {/* Tranning part end  */}


      {/*  about  */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mx-10 md:mx-20 lg:mx-28  ">
        <div className=" mt-8">
          <h2 className="text-start font-bold text-2xl md:text-5xl">
            Certification Programs
          </h2>
          <hr className="w-1/4  border-2 border-[#05BFDB] my-3"></hr>

          <p className="mt-4 text-justify text-[#5E5E5E]">
            Gravitonwebtech is a leading technology company dedicated to
            providing cutting-edge solutions for businesses and individuals.
            With a focus on innovation and excellence, we strive to empower our
            clients with the latest technologies and digital strategies.
          </p>
          <div className="mt-20">
            <span className="text-[#0A4D68] italic text-2xl font-bold mt-5 ">
              “Tech Skills Training Institute: Graviton Web Technology”
            </span>
          </div>
        </div>

        <div className="flex justify-center sm:items-center sm:h-[52vh]">
          <img
            src={certificateprogram}
            className="w-52 sm:w-72 h-52 sm:h-72"
            alt="certificate programs"
          />
        </div>
      </div>
      {/*  about part end  */}


      {/* cards-part */}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mx-10 md:mx-20 lg:mx-28 gap-10 mt-5 ">
        <div className="relative h-[500px] md:h-[600px] lg:h-[550px]  xl:h-[500px] ">
          {/* Center Content */}
          <div className="absolute inset-0 p-5 bg-[#DFF7FA]  rounded border-2 hover:border-[#1490EA] cursor-pointer ">
            <div className="flex justify-center">
              <img
                src={frontendimge}
                className="w-2/4 h-2/4 "
                alt="program cards"
              />
            </div>
            <div className=" text-center mt-5">
              <h3 className="text-lg font-bold  ">
                Professional Certification In Front-end Development
              </h3>

              <p className="text-[#5E5E5E] mt-3  text-justify">
                Gravitonweb Technologies offers frontend development training
                with real-time example and hands-on projects. Our entire
                frontend development training course focusses on an elementary
                stage to expert level with detailed concepts.
              </p>
            </div>
          </div>

          {/* Corner Images */}
          <img
            src={topleft}
            alt="Corner Image 1"
            className="absolute top-0 left-2 w-14 h-14"
          />
          <img
            src={topright}
            alt="Corner Image 2"
            className="absolute top-0 right-2 w-14 h-14"
          />
          <img
            src={bottomleft}
            alt="Corner Image 3"
            className="absolute bottom-0 left-2 w-14 h-14"
          />
          <img
            src={rightbottom}
            alt="Corner Image 4"
            className="absolute bottom-0 right-2 w-14 h-14"
          />
        </div>

        <div className="relative h-[500px] md:h-[600px] lg:h-[550px]  xl:h-[500px] ">
          {/* Center Content */}
          <div className="absolute inset-0 p-5  bg-[#DFF7FA]  rounded border-2 hover:border-[#1490EA] cursor-pointer ">
            <div className="flex justify-center">
              <img
                src={backendimge}
                className="w-2/4 h-2/4 "
                alt="program cards"
              />
            </div>
            <div className=" text-center mt-5">
              <h3 className="text-lg font-bold  ">
                Professional Certification In Back-end Development
              </h3>

              <p className="text-[#5E5E5E] mt-3  text-justify">
                Gravitonweb Technologies offers Back-end Development refers to
                the server-side development. It focuses on databases, scripting,
                website architecture.. . MySQL, MongoDB, Oracle, SQLServer,
                Redis are widely used for this purpose.
              </p>
            </div>
          </div>

          {/* Corner Images */}
          <img
            src={topleft}
            alt="Corner Image 1"
            className="absolute top-0 left-2 w-14 h-14"
          />
          <img
            src={topright}
            alt="Corner Image 2"
            className="absolute top-0 right-2 w-14 h-14"
          />
          <img
            src={bottomleft}
            alt="Corner Image 3"
            className="absolute bottom-0 left-2 w-14 h-14"
          />
          <img
            src={rightbottom}
            alt="Corner Image 4"
            className="absolute bottom-0 right-2 w-14 h-14"
          />
        </div>

        <div className="relative h-[500px] md:h-[600px] lg:h-[550px]  xl:h-[500px] ">
          {/* Center Content */}
          <div className="absolute inset-0   p-5  bg-[#DFF7FA]  rounded border-2 hover:border-[#1490EA] cursor-pointer  ">
            <div className="flex justify-center">
              <img
                src={fullstackimge}
                className="w-2/4 h-2/4 "
                alt="program cards"
              />
            </div>
            <div className=" text-center mt-5">
              <h3 className="text-lg font-bold  ">
                Professional Certification In Full-Stack Development
              </h3>

              <p className="text-[#5E5E5E] mt-3  text-justify">
                Gravitonweb Technologies offers frontend development training
                with real-time example and hands-on projects. Our entire
                frontend development training course focusses on an elementary
                stage to expert level with detailed concepts.
              </p>
            </div>
          </div>

          {/* Corner Images */}
          <img
            src={topleft}
            alt="Corner Image 1"
            className="absolute top-0 left-2 w-14 h-14"
          />
          <img
            src={topright}
            alt="Corner Image 2"
            className="absolute top-0 right-2 w-14 h-14"
          />
          <img
            src={bottomleft}
            alt="Corner Image 3"
            className="absolute bottom-0 left-2 w-14 h-14"
          />
          <img
            src={rightbottom}
            alt="Corner Image 4"
            className="absolute bottom-0 right-2 w-14 h-14"
          />
        </div>

        <div className="relative h-[500px] md:h-[600px] lg:h-[550px]  xl:h-[500px] ">
          {/* Center Content */}
          <div className="absolute inset-0 p-5  bg-[#DFF7FA]  rounded border-2 hover:border-[#1490EA] cursor-pointer ">
            <div className="flex justify-center">
              <img
                src={artificailimge}
                className="w-2/4 h-2/4 "
                alt="program cards"
              />
            </div>
            <div className=" text-center mt-5">
              <h3 className="text-lg font-bold  ">
                Professional Certification In Artificial Intelligence
              </h3>

              <p className="text-[#5E5E5E] mt-3  text-justify">
                Gravitonweb Technologies offers frontend development training
                with real-time example and hands-on projects. Our entire
                frontend development training course focusses on an elementary
                stage to expert level with detailed concepts.
              </p>
            </div>
          </div>

          {/* Corner Images */}
          <img
            src={topleft}
            alt="Corner Image 1"
            className="absolute top-0 left-2 w-14 h-14"
          />
          <img
            src={topright}
            alt="Corner Image 2"
            className="absolute top-0 right-2 w-14 h-14"
          />
          <img
            src={bottomleft}
            alt="Corner Image 3"
            className="absolute bottom-0 left-2 w-14 h-14"
          />
          <img
            src={rightbottom}
            alt="Corner Image 4"
            className="absolute bottom-0 right-2 w-14 h-14"
          />
        </div>

        <div className="relative h-[500px] md:h-[600px] lg:h-[550px]  xl:h-[500px] ">
          {/* Center Content */}
          <div className="absolute inset-0 p-5  bg-[#DFF7FA]  rounded border-2 hover:border-[#1490EA] cursor-pointer ">
            <div className="flex justify-center">
              <img
                src={pythonimge}
                className="w-2/4 h-2/4 "
                alt="program cards"
              />
            </div>
            <div className=" text-center mt-5">
              <h3 className="text-lg font-bold  ">
                Professional Certification In Python
              </h3>

              <p className="text-[#5E5E5E] mt-3  text-justify">
                Gravitonweb Technologies offers frontend development training
                with real-time example and hands-on projects. Our entire
                frontend development training course focusses on an elementary
                stage to expert level with detailed concepts.
              </p>
            </div>
          </div>

          {/* Corner Images */}
          <img
            src={topleft}
            alt="Corner Image 1"
            className="absolute top-0 left-2 w-14 h-14"
          />
          <img
            src={topright}
            alt="Corner Image 2"
            className="absolute top-0 right-2 w-14 h-14"
          />
          <img
            src={bottomleft}
            alt="Corner Image 3"
            className="absolute bottom-0 left-2 w-14 h-14"
          />
          <img
            src={rightbottom}
            alt="Corner Image 4"
            className="absolute bottom-0 right-2 w-14 h-14"
          />
        </div>

        <div className="relative h-[500px] md:h-[600px] lg:h-[550px]  xl:h-[500px] ">
          {/* Center Content */}
          <div className="absolute inset-0   p-5  bg-[#DFF7FA]  rounded border-2 hover:border-[#1490EA] cursor-pointer ">
            <div className="flex justify-center">
              <img
                src={pythonimge}
                className="w-2/4 h-2/4 "
                alt="program cards"
              />
            </div>
            <div className=" text-center mt-5">
              <h3 className="text-lg font-bold  ">
                Professional Certification In Python
              </h3>

              <p className="text-[#5E5E5E] mt-3  text-justify">
                Gravitonweb Technologies offers frontend development training
                with real-time example and hands-on projects. Our entire
                frontend development training course focusses on an elementary
                stage to expert level with detailed concepts.
              </p>
            </div>
          </div>

          {/* Corner Images */}
          <img
            src={topleft}
            alt="Corner Image 1"
            className="absolute top-0 left-2 w-14 h-14"
          />
          <img
            src={topright}
            alt="Corner Image 2"
            className="absolute top-0 right-2 w-14 h-14"
          />
          <img
            src={bottomleft}
            alt="Corner Image 3"
            className="absolute bottom-0 left-2 w-14 h-14"
          />
          <img
            src={rightbottom}
            alt="Corner Image 4"
            className="absolute bottom-0 right-2 w-14 h-14"
          />
        </div>
      </div>

      {/* now start counting start */}
      <div
        className="mt-10 font-extralight grid grid-cols-1 lg:grid-cols-4 items-center   bg-opacity-85 py-6 px-16 min-h-[200px] w-full overflow-hidden"
        style={{
          background:
            "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
        }}
      >
        <div className="p-4 rounded m-4 text-center">
          <h3 className="text-white font-semibold text-6xl">
           
            <CountUp start={0} end={1000} duration={10} />
                  <span>+</span>
          </h3>
          <h3 className="text-white font-bold text-lg">Learners</h3>
        </div>

        <div className="p-4 rounded m-4 text-center">
          <h3 className="text-white font-semibold text-6xl">
          
            <CountUp start={0} end={50} duration={10} />
                  <span>+</span>
          </h3>
          <h3 className="text-white font-bold text-lg">Hiring Partners</h3>
        </div>

        <div className="p-4 rounded m-4 text-center">
          <h3 className="text-white font-semibold text-6xl">
            <CountUp start={0} end={18} duration={10} />
            <span>+</span>
          </h3>
          <h3 className="text-white font-bold text-lg">
            Live Concurrent Batches
          </h3>
        </div>

        <div className="p-4 rounded m-4 text-center">
          <h3 className="text-white font-semibold text-6xl">
            <CountUp start={0} end={5} duration={10}  />
            <span>+</span>
          </h3>
          <h3 className="text-white font-bold text-lg">Campuses</h3>
        </div>
      </div>
    </>
  );
};

export default CertificationProgram;
