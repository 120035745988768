import React, { useState } from "react";
import Business from "../../assets/Images/feedbackImgs/Business.png";
import { BiSend } from "react-icons/bi";
import top from "../../assets/Images/feedbackImgs/top.png";
import bottom from "../../assets/Images/feedbackImgs/bottom.svg";
import NavigationBar from "../../common/NavigationBar";
import { Link } from "react-router-dom";
export default function Feedback1() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    howDidYouHear: "",
    message: "",
    submit: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };
  const [selectedOption, setSelectedOption] = useState("option4");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  return (
    <>
      {/* main */}
      <div class="relative">
        <NavigationBar />
        <img
          src={top}
          alt="Background"
          className="w-full h-auto fixed top-0 left-0"
        />
        <img
          src={bottom}
          alt=""
          className="w-full h-auto fixed bottom-0 left-0 object-cover"
        />

        <div className="absolute inset-0 gap-0 lg:gap-28 grid grid-cols-1 md:grid-cols-2 mx-5 md:mx-20 lg:mx-48 mt-24 md:mt-28">
          <div className="shadow-lg rounded-lg bg-white py-10 px-10">
            <h2 class="text-xl md:text-2xl text-center font-medium text-[#05BFDB]">
              Your Feedback is important to us
            </h2>
            <p class="text-center">We're happy to hear your feedback</p>
            <form onSubmit={handleSubmit}>
              <div className="mt-5">
                <input
                  type="text"
                  name="FullName"
                  value={formData.FullName}
                  onChange={handleChange}
                  placeholder="Full Name"
                  className="w-full px-3 py-2 border rounded bg-[#F5F5F5]"
                />
              </div>
              <div className="mt-4">
                <input
                  type="text"
                  name="Email"
                  value={formData.Email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="w-full px-3 py-2 border rounded bg-[#F5F5F5]"
                />
              </div>
              <div className="mt-4">
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Phone Number (Optional)"
                  className="w-full px-3 py-2 border rounded bg-[#F5F5F5]"
                />
              </div>
              <div className="mt-4">
                <label>How did you hear about us?</label>
                <div className="md:space-x-2 space-x-1 mt-2">
                  <input
                    type="radio"
                    id="Social Media"
                    name="options"
                    value="Social Media"
                    checked={selectedOption === "Social Media"}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="Social Media">Social Media</label>

                  <input
                    type="radio"
                    id="Friend"
                    name="options"
                    value="Friend"
                    checked={selectedOption === "Friend"}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="Friend">Friend</label>
                  <input
                    type="radio"
                    id="College"
                    name="options"
                    value="College"
                    checked={selectedOption === "College"}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="College">College</label>
                  <input
                    type="radio"
                    id="other"
                    name="options"
                    value="other"
                    checked
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="other">Other</label>
                </div>
              </div>
              <div className="mt-4">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Where did you hear about us?"
                  className="w-full px-3 py-2 border rounded bg-[#F5F5F5]"
                  rows="3"
                />
              </div>
              <div className="text-center">
               <Link to="/feedback2">
               <button
                  style={{
                    background:
                      "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
                    color: "#ffffff",
                    borderRadius: "4px",
                    padding: "10px 0",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="text-white w-full text-2xl hover:shadow-md mt-4"
                >
                  Next 
                <BiSend className="w-8 h-8 ml-2" />
                </button>
               </Link>
              </div>
            </form>
          </div>

          <div class="hidden md:block">
            <img src={Business} alt="" className="w-[80%] h-auto" />
          </div>

        </div>
      </div>
    </>
  );
}
