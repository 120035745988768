import React from 'react'
import NavigationBar from "../../common/NavigationBar";
import img02 from "../../assets/Images/AboutImage/hero-bg.jpg";

const GrowYourBusiness = () => {
  return (
    <>

     {/* banner start */}
      <div className=" w-full h-64 md:h-96 lg:h-96 relative">
        <img
          className="object-cover w-full h-64 md:h-96 lg:h-96"
          src={img02}
          alt=""
        />

        <div className="absolute inset-0 bg-[#2c2927]  bg-opacity-75" />
        <NavigationBar />
        <div className="absolute inset-0 flex flex-col justify-center items-center">
          <p className="text-xl md:text-2xl lg:text-6xl font-bold text-white">
           Grow Your Business
          </p>
        </div>
      </div>
      {/* banner end */}

    </>
  )
}

export default GrowYourBusiness