import React from "react";
import background from "../../assets/Images/feedbackImgs/background.png";
import business3 from "../../assets/Images/feedbackImgs/business3.png";
import third from "../../assets/Images/feedbackImgs/third.png";
import NavigationBar from "../../common/NavigationBar";
const Feedback3 = () => {
  return (
    <>
      <div className="min-h-screen relative overflow-hidden">
        <NavigationBar />
        {/* Background Image */}
        <img
          src={background}
          alt="Background"
          className="w-full h-600px min-h-[100%] object-cover absolute top-0 left-0 "
        />

        <div className="relative text-center mt-40 md:mt-20 ">
          {/* Business 3 Image */}
          <img
            src={business3}
            alt="Business 2"
            className="w-1/2 sm:w-1/4 mx-auto "
          />

          {/* Third Image */}
          <img
            src={third}
            alt="Third"
            className="   w-full h-[199px] "
          />
          <div className="absolute top-0 left-0 w-full h-full grid place-items-center text-center">
            <h1 className="font-semibold text-2xl sm:text-4xl text-white mt-3 p-12 sm:mt-5 md:mt-20"></h1>
            <h1 className=" font-bold  items-center justify-center text-3xl sm:text-4xl text-white   sm:mt-5 md:mt-20">
              THANK YOU FOR YOUR
              <br /> FEEDBACK
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback3;
