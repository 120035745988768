import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import slide1 from "../../assets/Images/Homepageimages/slide7.png";
import slide2 from "../../assets/Images/Homepageimages/slide8.png";
import slide3 from "../../assets/Images/Homepageimages/slide9.png";
import slide4 from "../../assets/Images/Homepageimages/slide6.png";
import slide5 from "../../assets/Images/Homepageimages/slide10.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStar } from "@fortawesome/free-solid-svg-icons";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import vid2 from "../../assets/Images/ServiceOffer/vid-background2.avif";
import vid3 from "../../assets/Images/ServiceOffer/vid-background3.avif";
import vid4 from "../../assets/Images/ServiceOffer/vid-background4.avif";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DigitalGravityPdf from "../../assets/Images/ServiceOffer/Digital Gravity Purposal__.pdf";
import BannerImage from "../../assets/Images/ServiceOffer/banner-service-offer1.png";
import DigitalGif from "../../assets/Images/ServiceOffer/Digitaly-Gravity-video.gif";
import NavigationBar from "../../common/NavigationBar";
import ServiceProvide from "../../assets/Images/ServiceOffer/serviceProvide.png";
import ServiceBanner from "../../assets/Images/ServiceOffer/services-offer-banner1.gif";
import ServiceContact from "../../assets/Images/ServiceOffer/service-offer-contact.png";
import { server } from "../../env/env";

function Placement() {
  // bottom-form-api
  const [values1, setValue1] = useState({
    name: "",
    email: "",
    phone: "",
    textarea: "",
  });

  function nexthandleChange(event) {
    const newObj = { ...values1, [event.target.name]: event.target.value };
    setValue1(newObj);
  }

  const nexthandleSubmit = (event) => {
    event.preventDefault();

    var formdata = new FormData();
    formdata.append("firstname", values1.name);
    formdata.append("email", values1.email);
    formdata.append("phone", values1.phone);
    formdata.append("message", values1.textarea);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(server.prod + "main/AiAccelerateContactData/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert("success Fully Regsiterd ");
      })
      .catch((error) => console.log("error", error));

    setValue1({
      name: "",
      email: "",
      phone: "",
      textarea: "",
    });
  };

  const WebtechSlideSettings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  const isSmallScreen1 = window.innerWidth <= 768;

  if (isSmallScreen1) {
    WebtechSlideSettings1.slidesToShow = 1;
  }

  // top-form-api updated code
  const [values, setValue] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });

  function handleChange(event) {
    const newObj = { ...values, [event.target.name]: event.target.value };
    setValue(newObj);
  }
  const [selectedOption, setSelectedOption] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleRegisterClick = (e) => {
    e.preventDefault(); // Prevent form submission

    if (
      // selectedOption &&
      values.firstname != "" &&
      values.lastname != "" &&
      values.email != "" &&
      values.phone != ""
    ) {
      const min = 1000000000; // Smallest 10-digit number
      const max = 9999999999; // Largest 10-digit number
      const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
      const min1 = 10000; // Smallest 5-digit number
      const max1 = 99999; // Largest 5-digit number
      const randomValue1 = Math.floor(Math.random() * (max1 - min1 + 1)) + min1;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        amount: 9900,
        trnsId: randomValue,
        userid: randomValue1,
        phoneNo: values.phone,
        name: values.firstname + " " + values.lastname,
        email: values.email,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://gravitonweb.co.in/main/initiate_phonepe_payment/",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          window.location.href = result?.pay_page_url;
        })
        .catch((error) => console.log("error", error));
    } else {
      setErrorMessage("Please select a course before register.");
    }
  };

  // const handleSelectChange = (e) => {
  //   setSelectedOption(e.target.value);
  //   setErrorMessage("");
  // };
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [mins, setMins] = useState(0);
  const [secs, setSeconds] = useState(0);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const deadline = new Date("November 14, 2023 00:00:00").getTime();

  const calculateTimeRemaining = () => {
    const currentTime = new Date().getTime();
    const timeRemaining = deadline - currentTime;

    if (timeRemaining <= 0) {
      setTimeIsUp(true);
      return;
    }

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    const minutes = Math.floor(
      (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
    );

    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    setDays(days);
    setHours(hours);
    setMins(minutes);
    setSeconds(seconds);
  };

  useEffect(() => {
    const interval = setInterval(calculateTimeRemaining, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* banner section */}
      <div className="relative">
        <img
          src={BannerImage}
          alt="Banner"
          className="w-full  h-[1500px] md:h-[1000px] lg:h-[1050px] xl:h-[900px] object-cover"
        />
        <div className="absolute inset-0 gap-0 lg:gap-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  mx-5 md:mx-10 lg:mx-20">
          <NavigationBar />
          <div className="text-white mt-24 sm:mt-56 md:mt-0 md:flex md:flex-col md:justify-center md:h-full">
            <div className="">
              <p className="text-3xl lg:text-6xl font-bold text-[#00FFCA] mt-4">
                Offer ₹7,999/-
              </p>

              <p className="mt-4">
                <h1 className="text-2xl lg:text-4xl font-semibold ">
                  Android App and Website
                </h1>
                <h1 className="text-2xl font-semibold mt-3 ">Extra Offer</h1>
                <p className="mt-3">
                  <span className="text-lg  font-medium">DOMAIN + HOSTING</span>
                  <span className="text-lg  font-semibold pl-5 text-[#05BFDB]">
                    FREE
                  </span>
                </p>
                <p className="mt-3">
                  <span className="text-lg font-medium">DIGITAL MARKETING</span>
                  <span className="text-lg font-semibold pl-5 text-[#05BFDB]">
                    FREE 1 Month
                  </span>
                </p>
                <p className="mt-3">
                  <span className="text-lg  font-medium">GOOGLE LISTING</span>
                  <span className="text-lg font-semibold pl-5 text-[#05BFDB]">
                    FREE
                  </span>
                </p>
                <p className="mt-3">
                  <span className="text-lg  font-medium">
                    COMPANY PROFILE CREATION
                  </span>
                  <span className="text-lg text-[#05BFDB] font-semibold pl-5">
                    FREE
                  </span>
                </p>
                <p className="mt-3">
                  <span className="text-lg  font-medium">
                    TECHNICAL SUPPORT 1 MONTH
                  </span>
                  <span className="text-lg text-[#05BFDB] font-semibold pl-5">
                    FREE
                  </span>
                </p>
              </p>
            </div>
          </div>

          {/* <div className="text-white md:flex md:flex-col md:justify-center md:h-full ">
            <img
              src={DigitalGif}
              className="mx-auto block w-[70%] h-auto rounded"
              alt="About Us"
            />
          </div> */}

          <div className="text-white mt-5 sm:mt-20 md:mt-20 lg:mt-32">
            <form>
              <div className="bg-white bg-opacity-10  rounded-lg">
                <button className="bg-[#00384F] px-10 py-5 w-full text-3xl font-bold">
                  Register Now At &#8377; 99/-
                </button>

                <h1 className="text-center text-lg mt-3">Limited Period</h1>

                <div
                  className="text-center mt-5"
                  style={{ fontFamily: "Quantico, sans-serif" }}
                >
                  <button className="bg-[#FF0000] px-3 py-2 sm:px-1 sm:py-2 md:px-3 md:py-2 lg:px-10 lg:py-2 xl:px-16 xl:py-2 rounded-[4px]">
                    {timeIsUp ? (
                      <div className="text-center bg-[#FF0000] text-white">
                        <h2 className="text-4xl">Time's Up!</h2>
                      </div>
                    ) : (
                      <div className="flex gap-2 sm:gap-5">
                        <div className="text-center">
                          <h1 className="text-xl">
                            {days < 10 ? "0" + days : days}
                          </h1>

                          <span className="text-md">Days</span>
                        </div>

                        <span className="text-xl">:</span>

                        <div className="text-center">
                          <h1 className="text-xl">
                            {hours < 10 ? "0" + hours : hours}
                          </h1>

                          <span className="text-md">Hours</span>
                        </div>

                        <span className="text-xl">:</span>

                        <div className="text-center">
                          <h1 className="text-xl">
                            {mins < 10 ? "0" + mins : mins}
                          </h1>

                          <span className="text-md">Mins</span>
                        </div>

                        <span className="text-xl">:</span>

                        <div className="text-center">
                          <h1 className="text-xl">
                            {secs < 10 ? "0" + secs : secs}
                          </h1>

                          <span className="text-md">Secs</span>
                        </div>
                      </div>
                    )}
                  </button>
                </div>

                <p className="text-center mt-5 text-lg">
                Get Free Webinar
                </p>

                <div className="px-3 md:px-10 pb-10">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 mt-8">
                    <div>
                      <input
                        type="text"
                        placeholder="First Name"
                        className="bg-transparent border-2 p-2 rounded-[4px] w-full"
                        id="fname"
                        name="firstname"
                        value={values.firstname}
                        onChange={handleChange}
                        required
                      />
                      {values.firstname === "" && errorMessage && (
                        <p className="text-red-400">
                          Please fill the First Name
                        </p>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="bg-transparent border-2 p-2 rounded-[4px] w-full"
                        id="lname"
                        name="lastname"
                        value={values.lastname}
                        onChange={handleChange}
                        required
                      />
                      {values.lastname === "" && errorMessage && (
                        <p className="text-red-400">
                          Please fill the Last Name
                        </p>
                      )}
                    </div>
                  </div>

                  <input
                    type="email"
                    placeholder="Email"
                    className="bg-transparent border-2 p-2 w-full mt-7 rounded-[4px]"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    required
                  />
                  {values.email == "" && errorMessage && (
                    <p className="text-red-400">Please Fill the Email </p>
                  )}
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    className="bg-transparent border-2 p-2 w-full mt-7 rounded-[4px]"
                    id="Pnum"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    required
                  />
                  {values.phone == "" && errorMessage && (
                    <p className="text-red-400">Please fil the Phone Number </p>
                  )}
                  {/* <div className="flex justify-center">
                    <img src={DownArrow} className="w-[40%] h-auto"></img>
                  </div> */}

                  {/* <select
                    className="block bg-transparent border-2 p-2 w-full mt-7 rounded-[4px]"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    required
                  >
                    <option value="" disabled selected>
                      Select Course
                    </option>

                    <option value="option1" className="text-black">
                      Free Session To Learn GrowYour Business
                    </option>
                  </select>
                  {!selectedOption && errorMessage && (
                    <p className="text-red-400">
                      Please select a course before register
                    </p>
                  )} */}

                  <div className="flex items-center mt-4">
                    <input
                      type="checkbox"
                      className="border-2 rounded-md h-4 w-4 bg-[#05BFDB]"
                    />

                    <label htmlFor="checkbox" className="ml-2 text-lg">
                      I agree the details provided here is accurate
                    </label>
                  </div>
                  <button onClick={handleRegisterClick} className="w-full">
                    <a
                      href="https://www.instamojo.com/@gravitonwebit/l5a83dce20e07429daf78be100d36ceba/"
                      className="block w-full bg-[#05BFDB] text-white text-xl text-center
                    font-semibold py-3 mt-5 rounded-[5px] hover:bg-white hover:text-[#05BFDB]"
                    >
                      Register Now
                    </a>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* banner section */}

      {/* 2-part */}
      <div className="mt-10  mx-10 md:mx-20 lg:mx-32">
        <h1 className="text-xl md:text-3xl lg:text-[41px] font-medium">
          Why
          <span className="text-xl md:text-3xl lg:text-[41px] font-medium text-[#05BFDB]">
            {" "}
            Digital Gravity{" "}
          </span>
          is a reliable course as nowadays? As an expert
        </h1>
        <h1 className="text-xl md:text-3xl lg:text-[41px] font-medium mt-4">
          in the field
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 gap-5 md:gap-20 mx-10 md:mx-20 lg:mx-32 mt-8">
        <div className="md:col-span-2 lg:col-span-2">
          <hr className="border-b border-black w-36 md:mt-5"></hr>
        </div>

        <div className="md:col-span-7 lg:col-span-10">
          <p className="text-base font-normal text-[#545454]">
            In the rapidly evolving landscape of digital skills education,
            DigitalGravity stands out as a beacon of reliability and excellence.
            Here's why we are your trusted partner in the journey towards career
            transformation. At DigitalGravity, our courses are meticulously
            crafted to align with the latest industry trends and demands. We
            work closely with experts and industry leaders to ensure that what
            you learn is not only up-to-date but also immediately applicable in
            real-world scenarios. We understand the importance of practical
            skills in today's job market. Our courses emphasize hands-on
            learning through projects and assignments that mirror the challenges
            you'll face in your professional endeavors. This experiential
            learning approach sets us apart and prepares you for success from
            day one.
          </p>

          <p className="text-base font-normal text-[#545454] mt-3">
            Our team of instructors comprises seasoned professionals with
            extensive industry experience. Beyond their impressive resumes, they
            are passionate educators committed to your success. With
            DigitalGravity, you learn not just from textbooks but from
            individuals who have thrived in the very roles you aspire to.
            Recognizing that every learner is unique, we offer personalized
            learning paths tailored to your goals and learning style. Whether
            you're a beginner or an experienced professional looking to upskill,
            our courses are designed to meet you where you are and propel you
            forward.
          </p>
        </div>
      </div>
      {/* 2-part */}

      {/* 3-part */}
      <div className="mt-10 md:mt-20">
        <img src={ServiceProvide}></img>
      </div>
      {/* 3-part */}

      {/* 4-part */}
      <div className="relative">
        <img
          src={ServiceBanner}
          alt=""
          className="w-full h-64 sm:h-56 md:h-auto object-cover"
        />

        <div className="absolute top-1/2 transform -translate-y-1/2 left-8 md:left-12 lg:left-20">
          <h1 className="text-base md:text-2xl lg:text-4xl font-semibold text-white">
            Unlock Your Business<br></br> Potential with<br></br>
            <span className="text-[#00FFCA] text-base md:text-2xl lg:text-4xl font-semibold">
              Gravitonwebtech
            </span>
          </h1>
          <Link>
            <button
              className="mt-4 px-10 py-2 text-white font-semibold bg-gradient-to-r
           from-[#0A4D68] to-[#05BFDB] rounded-[4px]"
            >
              Enroll Now
            </button>
          </Link>
        </div>
      </div>
      {/* 4-part */}

      {/* contact-part */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-20  px-10 md:px-20 lg:px-32  py-10 md:py-20 bg-gradient-to-b from-[#FFFFFF] to-[#05BFDB]">
        <div className="">
          <img
            src={ServiceContact}
            alt=""
            className="h-auto w-full object-cover "
          />
        </div>

        <div className="">
          <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-[#0A4D68] mb-4">
            Let's Connect
          </h2>

          <p className="text-gray-600 mb-6">
            Step up your online presence with the proper utilization of digital
            assets.
          </p>

          <form>
            <div className="mb-4">
              <input
                type="text"
                className="w-full px-3 py-2 border-2 border-[#0A4D68] bg-transparent"
                placeholder="Your Name"
                id="fname"
                name="name"
                value={values1.name}
                onChange={nexthandleChange}
              />
            </div>

            <div className="mb-4">
              <input
                type="email"
                className="w-full px-3 py-2 border-2 border-[#8F8F8F] bg-transparent"
                placeholder="Your Email"
                id="email"
                name="email"
                value={values1.email}
                onChange={nexthandleChange}
              />
            </div>

            <div className="mb-4">
              <input
                type="text"
                className="w-full px-3 py-2 border-2 border-[#8F8F8F] bg-transparent"
                placeholder="Your Phone Number"
                id="pnum"
                name="phone"
                value={values1.phone}
                onChange={nexthandleChange}
              />
            </div>

            <div className="mb-4">
              <textarea
                rows="4"
                className="w-full px-3 py-2 border-2 border-[#8F8F8F] bg-transparent"
                placeholder="Your Message"
                value={values1.textarea}
                onChange={nexthandleChange}
                name="textarea"
              ></textarea>
            </div>

            <div className="flex justify-center mt-5 ">
              <button
                type="submit"
                className="bg-gradient-to-r from-[#0A4D68] to-[#05BFDB] py-3 w-full 
                text-white font-semibold text-xl rounded-[8px] "
                onClick={nexthandleSubmit}
              >
                Enroll Now
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* contact-part */}

      {/* reviews*/}
      <div className="mt-10 md:mt-20">
        <h1 className="text-xl md:text-3xl font-bold text-[#262566] text-center">
          {" "}
          CUSTOMERS FEEDBACK
        </h1>
      </div>

      <div className="mt-5 md:mt-10  mx-10 md:mx-20 lg:mx-30">
        <Slider {...WebtechSlideSettings1}>
          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide1} className="rounded-[50%]" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Palak Sharma
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
              Promotion via Workshop!!
              </h1>
              <p className="text-[#64666C] mt-3">
              They provide quality of service based on customer needs.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide2} className="rounded-[50%]" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Tarang Tripathi
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
                I could negotiate 40% salary hike
              </h1>
              <p className="text-[#64666C] mt-3">
              I recently had the opportunity to work with this company.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide3} className="rounded-[50%]" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Kunal Singh
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
                Literally a timesaver workshop!!
              </h1>
              <p className="text-[#64666C] mt-3 ">
              They are very sharp and have a high quality team members.
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide4} className="rounded-[50%]" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Anshika dubey
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
              Colleague's Unexpected Recognition
              </h1>
              <p className="text-[#64666C] mt-3">
              Good company and quality products delivery on times.Nice
              </p>
            </div>
          </div>

          <div className="p-4">
            <div className="border-4 bg-blue-100 border-[#05BFDB] p-8">
              <div className="flex ">
                <img src={slide5} className="rounded-[50%] w-[80px] h-auto" />
                <h1 className="text-2xl font-semibold pl-6 mt-4">
                  Ayush Jain
                </h1>
              </div>
              <hr className="border-gray-400 my-3 w-full " />
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />

                <FontAwesomeIcon
                  icon={faStar}
                  className="h-4 w-4 text-[#05BFDB] mt-4"
                />
              </div>
              <h1 className="text-xl font-bold mt-5 ">
               Secured Exciting Placement
              </h1>
              <p className="text-[#64666C] mt-3">
                Placed in Programmics Technology through  Graviton.
              </p>
            </div>
          </div>

        </Slider>
      </div>
      {/* reviews*/}

      {/* video part */}

      {/* <div className="grid grid-cols-1 lg:grid-cols-2 mt-28 mx-10 md:mx-20 lg:mx-32 gap-14 ">
        <div className="relative">
          <img src={vid2} alt="Video Thumbnail " className="w-[100%] h-auto" />

          <Link to="https://www.youtube.com/watch?v=y9CjLeSqnSs">
            <FontAwesomeIcon
              icon={faPlay}
              className="h-10 w-10 text-blue-500 mt-4 cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </Link>
        </div>

        <div className="relative">
          <img src={vid3} alt="Video Thumbnail " className="w-[100%] h-auto" />

          <Link to="https://www.youtube.com/watch?v=y9CjLeSqnSs">
            <FontAwesomeIcon
              icon={faPlay}
              className="h-10 w-10 text-blue-500 mt-4 cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </Link>
        </div>

        <div className="relative">
          <img src={vid4} alt="Video Thumbnail " className="w-[100%] h-auto" />

          <Link to="https://www.youtube.com/watch?v=y9CjLeSqnSs">
            <FontAwesomeIcon
              icon={faPlay}
              className="h-10 w-10 text-blue-500 mt-4 cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </Link>
        </div>

        <div className="relative">
          <img src={vid2} alt="Video Thumbnail " className="w-[100%] h-auto" />

          <Link to="https://www.youtube.com/watch?v=y9CjLeSqnSs">
            <FontAwesomeIcon
              icon={faPlay}
              className="h-10 w-10 text-blue-500 mt-4 cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </Link>
        </div>
      </div> */}

      {/* video part */}

      {/* download-button */}
      <div className="mt-10 mb-10 text-center ">
        <a
          href={DigitalGravityPdf}
          download="DigitalGravityBouchers.pdf"
          className="bg-gradient-to-r from-teal-800 to-blue-500 px-5 py-2 md:px-10 md:py-3
         rounded-[8px] text-white font-bold text-xl md:text-2xl"
        >
          <button>Download PDF</button>
        </a>
      </div>
    </>
  );
}
export default Placement;
