import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import textsecond from "../../assets/Images/placementimages/secondtext1.svg";
import bannerjob1 from "../../assets/Images/placementimages/jobbannerimg.svg";
import headingfourth from "../../assets/Images/placementimages/fourthheading4.svg";
import servicecards1 from "../../assets/Images/placementimages/cardservice1.png";
import servicecards2 from "../../assets/Images/placementimages/cardservice2.png";
import servicecards3 from "../../assets/Images/placementimages/cardservice3.png";
import courseour1 from "../../assets/Images/placementimages/Ourcourse1.svg";
import groupcircle1 from "../../assets/Images/placementimages/circlegroup1.svg";
import groupcircle2 from "../../assets/Images/placementimages/circlegroup2.svg";
import groupcircle3 from "../../assets/Images/placementimages/circlegroup3.svg";
import groupcircle4 from "../../assets/Images/placementimages/circlegroup4.svg";

import hcl from "../../assets/Images/placementimages/hcl.png";
import techmahindra from "../../assets/Images/placementimages/techmahindra.png";
import wipro from "../../assets/Images/placementimages/wipro.png";
import infosys from "../../assets/Images/placementimages/infosys.png";
import Marquee from "react-fast-marquee";
import placeAssurancepdf from "../../assets/Images/placementimages/PlacementAssurence-Gravitonweb 1.pdf";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavigationBar from "../../common/NavigationBar";
import "./PlacementAssuranceProgram.css";
import CountUp from "react-countup";

const PlacementAssuranceProgram = () => {
  useEffect(() => {
    const parallax = document.getElementById("placement-banner-parallax");

    const handleScroll = () => {
      let offset = window.pageYOffset;
      parallax.style.backgroundPositionY = offset * -0.4 + "px";
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {/* banner start */}
    

      <div className="placement-banner-section" id="placement-banner-parallax">
        <NavigationBar />

        <div className="text-white pt-52 px-5 sm:px-10 md:px-20 text-center">
          <h1 className="text-xl sm:text-3xl md:text-5xl font-semibold mt-12 ">
            Guaranteeing Your Career
            <br />
            <span className="text-[#05BFDB]">Success</span>
          </h1>

          <p className="text-lg font-medium mt-5">
            Unlock Your Future with Our Placement Assurance Program
          </p>
          <Link to="/digitalwebinar">
            <button
              style={{
                background:
                  "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
              }}
              className="text-white rounded-[4px] px-10 py-2 mt-10"
            >
              Explore
            </button>
          </Link>
        </div>
      </div>

      {/* banner end */}

      {/* second part  */}

      <div className="  flex justify-center mt-10 ">
        <img src={textsecond} className="w-3/4 h-3/4 " />
      </div>

      {/* second part end */}

      {/* third part start */}

      <div className=" hidden  md:block mt-10 pointer-events-none ">
        <img src={bannerjob1} className="w-[90%] h-auto" />
      </div>

      {/* for mobile */}
      <div className="block md:hidden border-t-4 border-[#00AFC9] w-[15%]  mt-20  "></div>
      <div className="md:hidden grid grid-cols-2    gap-3 mx-10  mt-[-60px]  ">
        <img src={groupcircle1} className="w-3/4 h-auto" />

        <h1
          className="text-[#0A4D68]   text-lg font-bold "
          style={{ fontFamily: "Poppins" }}
        >
          100% CORE JOB GUARANTEE
          <div className="border-t-4 border-[#00AFC9] w-[100%] md:w-[80%]  md:mt-14 "></div>
          <p className="text-[#5A5A5A]    " style={{ fontFamily: "Poppins" }}>
            Get Placed in Top companies like Harman, Huawei, Sony etc.
          </p>
        </h1>
      </div>

      <div className="block md:hidden border-t-4 border-[#00AFC9] w-[15%]  mt-20  "></div>
      <div className="md:hidden grid grid-cols-2    gap-3 mx-10  mt-[-60px]  ">
        <img src={groupcircle2} className="w-3/4 h-auto" />

        <h1
          className="text-[#0A4D68]   text-lg font-bold "
          style={{ fontFamily: "Poppins" }}
        >
          SPECIAL PLACEMENT PREPARATION
          <div className="border-t-4 border-[#00AFC9] w-[100%] md:w-[80%]  md:mt-14 "></div>
          <p className="text-[#5A5A5A]     " style={{ fontFamily: "Poppins" }}>
            Personalized help for cracking interview
          </p>
        </h1>
      </div>

      <div className="block md:hidden border-t-4 border-[#00AFC9] w-[15%]  mt-20  "></div>
      <div className="md:hidden grid grid-cols-2    gap-3 mx-10  mt-[-60px]  ">
        <img src={groupcircle3} className="w-3/4 h-auto" />

        <h1
          className="text-[#0A4D68]   text-lg font-bold "
          style={{ fontFamily: "Poppins" }}
        >
          INDUSTRY COURSES
          <div className="border-t-4 border-[#00AFC9] w-[100%] md:w-[80%]  md:mt-14 "></div>
          <p className="text-[#5A5A5A]      " style={{ fontFamily: "Poppins" }}>
            AI, Data Science, Embedded Systems, IOT & more
          </p>
        </h1>
      </div>

      <div className="block md:hidden border-t-4 border-[#00AFC9] w-[15%]  mt-20  "></div>
      <div className="md:hidden grid grid-cols-2    gap-3 mx-10  mt-[-60px]  ">
        <img src={groupcircle4} className="w-3/4 h-auto" />

        <h1
          className="text-[#0A4D68]   text-lg font-bold "
          style={{ fontFamily: "Poppins" }}
        >
          HANDS ON PROJECTS
          <div className="border-t-4 border-[#00AFC9] w-[100%] md:w-[80%]  md:mt-14 "></div>
          <p className="text-[#5A5A5A]      " style={{ fontFamily: "Poppins" }}>
            Learn through projects & 1-on-1 Sessions
          </p>
        </h1>
      </div>

      <div
        className="mt-10 font-extralight grid grid-cols-1 lg:grid-cols-3 items-center   bg-opacity-85 py-6 px-16 min-h-[200px] w-full overflow-hidden"
        style={{
          background:
            "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
        }}
      >
        <div
          className="p-4 rounded m-4 text-center"
          style={{ fontFamily: "Poppins" }}
        >
          <h3 className="text-white font-semibold text-6xl">
            <CountUp start={0} end={620} duration={10} />
            <span>+</span>
          </h3>
          <h3 className="text-white font-bold text-lg">Students Enrolled</h3>
          <p className="text-white font-bold">This Year</p>
        </div>

        <div
          className="p-4 rounded m-4 text-center"
          style={{ fontFamily: "Poppins" }}
        >
          <h3 className="text-white font-semibold text-6xl text-center">₹500,000</h3>

          <h3 className="text-white font-bold text-lg">Average Salary (LPA)</h3>
          <p className="text-white font-bold">This Year</p>
        </div>

        <div
          className="p-4 rounded m-4 text-center"
          style={{ fontFamily: "Poppins" }}
        >
          <h3 className="text-white font-semibold text-6xl">
            <CountUp start={0} end={410} duration={10} />
            <span>+</span>
          </h3>
          <h3 className="text-white font-bold text-lg">Students Placed</h3>
          <p className="text-white font-bold">This Year</p>
        </div>
      </div>

      {/* fourth part start */}
      <div className="flex justify-center mt-10 ">
        <img src={headingfourth} className="w-2/4 h-3/4 " />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2   lg:grid-cols-3 gap-10 mx-10 md:mx-20 lg:mx-32  mt-5 md:mt-14">
        <div className="bg-[#AFEBF3] rounded border-2 hover:border-[#1490EA] shadow-xl cursor-pointer">
          <div className="flex justify-center relative group">
            <img
              src={servicecards1}
              className="w-[95%] h-auto p-14  bg-white rounded  mt-2"
            />
            <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 text-white text-3xl font-bold opacity-0 group-hover:opacity-100  ">
              LEARN
            </div>
          </div>
          <p
            className="mt-3  text-[#000000] text-center p-5"
            style={{ fontFamily: "Poppins" }}
          >
            Completed the latest industry-approved courses to stay updated
          </p>
        </div>

        <div className="bg-[#AFEBF3] rounded border-2 hover:border-[#1490EA] shadow-xl cursor-pointer">
          <div className="flex justify-center relative group">
            <img
              src={servicecards2}
              className="w-[95%] h-auto p-14  bg-white rounded  mt-2"
            />
            <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 text-white text-3xl font-bold opacity-0 group-hover:opacity-100  ">
              PREPARE
            </div>
          </div>
          <p
            className="mt-3  text-[#000000] text-center p-5"
            style={{ fontFamily: "Poppins" }}
          >
            Focused on placement training.Handle Interview with case
          </p>
        </div>

        <div className="bg-[#AFEBF3] rounded border-2 hover:border-[#1490EA] shadow-xl cursor-pointer">
          <div className="flex justify-center relative group">
            <img
              src={servicecards3}
              className="w-[95%] h-auto p-14  bg-white rounded  mt-2"
            />
            <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 text-white text-3xl font-bold opacity-0 group-hover:opacity-100  ">
              PROGRESS
            </div>
          </div>
          <p
            className="mt-3  text-[#000000] text-center p-5"
            style={{ fontFamily: "Poppins" }}
          >
            Get unique opportunities to sit for interview with care companies
          </p>
        </div>
      </div>

      {/* fifth part  */}

      <div className="flex justify-center mt-20 ">
        <img src={courseour1} className=" w-[70%] md:w-[40%] h-2/4 " />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3  gap-10 mx-10 md:mx-20 lg:mx-32  mt-10 md:mt-16">
        <div className="md:mt-5">
          <h1
            className="text-[#0A4D68] font-semibold  text-xl md:text-3xl "
            style={{ fontFamily: "Poppins" }}
          >
            FRONT-END WEB DEVELOPMENT
          </h1>
        </div>

        <div className="border-t-4 border-[#00AFC9] w-[100%] md:w-[80%]  md:mt-14 "></div>

        <div>
          <p
            className="text-[#494949]  text-lg   text-justify "
            style={{ fontFamily: "Poppins" }}
          >
            Gravitonweb Technologies offers frontend development training with
            real-time example and hands-on projects. Our entire frontend
            development training course focusses on an elementary stage to
            expert level with detailed concepts.
          </p>
        </div>
      </div>
      <div className=" border-t-2 border-gray-400 w-[90%] mx-auto mt-10"></div>

      <div className="grid grid-cols-1 md:grid-cols-3  gap-10 mx-10 md:mx-20 lg:mx-32  mt-10 md:mt-16">
        <div className="md:mt-5">
          <h1
            className="text-[#0A4D68] font-semibold  text-xl md:text-3xl "
            style={{ fontFamily: "Poppins" }}
          >
            BACK-END WEB DEVELOPMENT
          </h1>
        </div>

        <div className="border-t-4 border-[#00AFC9] w-[100%] md:w-[80%]  md:mt-14 "></div>

        <div>
          <p
            className="text-[#494949]  text-lg   text-justify "
            style={{ fontFamily: "Poppins" }}
          >
            Back-end Development refers to the server-side development. It
            focuses on databases, scripting, website architecture. It contains
            behind-the-scene activities that occur when performing any action on
            a website any action on a website.
          </p>
        </div>
      </div>
      <div className=" border-t-2 border-gray-400 w-[90%] mx-auto mt-10"></div>

      <div className="grid grid-cols-1 md:grid-cols-3  gap-10 mx-10 md:mx-20 lg:mx-32  mt-10 md:mt-16">
        <div className="md:mt-5">
          <h1
            className="text-[#0A4D68] font-semibold  text-xl md:text-3xl "
            style={{ fontFamily: "Poppins" }}
          >
            FULL-STACK WEB DEVELOPMENT
          </h1>
        </div>

        <div className="border-t-4 border-[#00AFC9] w-[100%] md:w-[80%]  md:mt-14 "></div>

        <div>
          <p
            className="text-[#494949]  text-lg   text-justify "
            style={{ fontFamily: "Poppins" }}
          >
            Gravitonweb Technologies are here to enhance your career as a web
            developer to the advanced level with our Fullstack Developer
            training program. We will teach you Placement Oriented Angular Js,
            Node Js, Express Js, MongoDB, PHP, Mysql, etc.
          </p>
        </div>
      </div>
      <div className=" border-t-2 border-gray-400 w-[90%] mx-auto mt-10"></div>

      <div className="grid grid-cols-1 md:grid-cols-3  gap-10 mx-10 md:mx-20 lg:mx-32  mt-10 md:mt-16">
        <div className="md:mt-5">
          <h1
            className="text-[#0A4D68] font-semibold  text-xl md:text-3xl "
            style={{ fontFamily: "Poppins" }}
          >
            MOBILE APPLICATION
          </h1>
        </div>

        <div className="border-t-4 border-[#00AFC9] w-[100%] md:w-[80%]  md:mt-14 "></div>

        <div>
          <p
            className="text-[#494949]  text-lg   text-justify "
            style={{ fontFamily: "Poppins" }}
          >
            Gravitonweb Technologies Android & IOS Swift training and Placement
            Offered by Certified experts in Bangalore not only equips you with
            the skills you need but also offers promising placement
            opportunities to kickstart your career."
          </p>
        </div>
      </div>
      <div className=" border-t-2 border-gray-400 w-[90%] mx-auto mt-10"></div>

      <div className="grid grid-cols-1 md:grid-cols-3  gap-10 mx-10 md:mx-20 lg:mx-32  mt-10 md:mt-16">
        <div className="md:mt-5">
          <h1
            className="text-[#0A4D68] font-semibold  text-xl md:text-3xl "
            style={{ fontFamily: "Poppins" }}
          >
            PYTHON
          </h1>
        </div>

        <div className="border-t-4 border-[#00AFC9] w-[100%] md:w-[80%]  md:mt-14 "></div>

        <div>
          <p
            className="text-[#494949]  text-lg   text-justify "
            style={{ fontFamily: "Poppins" }}
          >
            Gravitonweb Technologies offers frontend development training with
            real-time example and hands-on projects. Our entire frontend
            development training course focusses on an elementary stage to
            expert level with detailed concepts.
          </p>
        </div>
      </div>
      <div className=" border-t-2 border-gray-400 w-[90%] mx-auto mt-10"></div>

      <div className="grid grid-cols-1 md:grid-cols-3  gap-10 mx-10 md:mx-20 lg:mx-32  mt-10 md:mt-16">
        <div className="md:mt-5">
          <h1
            className="text-[#0A4D68] font-semibold  text-xl md:text-3xl "
            style={{ fontFamily: "Poppins" }}
          >
            DIGITAL MARKETING
          </h1>
        </div>

        <div className="border-t-4 border-[#00AFC9] w-[100%] md:w-[80%]  md:mt-14 "></div>

        <div>
          <p
            className="text-[#494949]  text-lg   text-justify "
            style={{ fontFamily: "Poppins" }}
          >
            One of the best digital marketing training is offered through
            deep-rooted training approaches approved by topmost MNCs and
            start-ups. Get trained on SEO, SEM, SMM, PPC, MailChimp, Google & FB
            AdWords techniques with 100% Placement in Bangalore.
          </p>
        </div>
      </div>

      {/* marquee */}

      <div className="bg-white py-16 mt-10 ">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-[#1293d4] mb-[10px]">
            Our Alumni Work Here
          </h1>
        </div>

        <div className="mt-10 ">
          <Marquee direction="right" speed={80} delay={5}>
            <div className="image_wrapper ml-28">
              <img src={hcl} alt="" className="w-[180px] h-[100px]" />
            </div>
            <div className="image_wrapper ml-28">
              <img src={infosys} alt="" className="w-[180px] h-[50px]" />
            </div>
            <div className="image_wrapper ml-28">
              <img src={techmahindra} alt="" className="w-[180px] h-[100px]" />
            </div>

            <div className="image_wrapper ml-28">
              <img src={wipro} alt="" className="w-[180px] h-[100px]" />
            </div>
          </Marquee>
        </div>
      </div>

      {/* marquee */}

      {/* work start */}
      <div className="text-center">
        <h1 className="text-[#262566] font-bold text-4xl mt-4">
          Our Students Love Us
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2   lg:grid-cols-3 gap-10 mx-10 md:mx-20 lg:mx-32 mt-20">
        <div>
          <div className="p-8 shadow-2xl">
            <h1 className="text-[#31abfc] font-bold text-xl mt-5"></h1>

        

            <p className="text-[#5f5f5f] mt-4">
              It was a great experience in CareerLabs. My dream was to get into
              an embedded domain. As a fresher it is difficult to get into the
              Embedded Design field, but CareerLabs made a huge difference in my
              career by giving the best training and placement assistance. I
              would like to say CareerLabs is the best choice for those who
              dream of embedded opportunity.
            </p>
            <h3 className="text-[#262566] font-bold text-xl mt-[45px]">
              Priya Sharma <br />
              Designer
            </h3>
          </div>
        </div>

        <div>
          <div className="p-8  shadow-2xl">
          

            <h1 className="text-[#31abfc] font-bold text-xl mt-5"></h1>

            <p className="text-[#5f5f5f] mt-5">
              Deepak Patel, an aspiring data analyst, joined our Training &
              Placement program to enhance his analytical skills. After
              completing the program, Deepak's proficiency in data analysis and
              visualization caught the attention of a prominent analytics firm.
              He was offered a position as a Data Analyst, where his expertise
              in handling complex datasets and generating actionable insights
              has been instrumental in driving data-driven decision-making for
              the company.
            </p>
            <h3 className="text-[#262566] font-bold text-xl">
              Deepak Patel
              <br />
              Designer
            </h3>
          </div>
        </div>

        <div>
          <div className="p-8 shadow-2xl">
          

            <h1 className="text-[#31abfc] font-bold text-xl mt-5"></h1>

            <p className="text-[#5f5f5f] mt-4">
              Rajiv Kapoor, a recent graduate in finance, joined our Training &
              Placement program to gain practical knowledge and enhance his
              employability in the finance industry. Through our specialized
              training modules and industry insights, Rajiv was able to showcase
              his financial acumen during the placement process. He secured a
              position as a Financial Analyst at a renowned investment firm,
              where his analytical skills and in-depth market knowledge have
              made a significant impact.
            </p>
            <h3 className="text-[#262566] font-bold text-xl">
              Rajiv Kapoor <br />
              Developer
            </h3>
          </div>
        </div>
      </div>

            {/* download-button */}
            <div className="mt-10 mb-10 text-center  ">
        <a
          href={placeAssurancepdf}
          download="PlaceAssuranceBouchers.pdf"
          className="bg-gradient-to-r from-teal-800 to-blue-500 px-5 py-2 md:px-10 md:py-3
        rounded-[8px] text-white font-bold text-xl md:text-2xl"
        >
          <button>Download PDF</button>
        </a>
      </div>
    </>
  );
};

export default PlacementAssuranceProgram;
