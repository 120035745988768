import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import tarining from "../../assets/Images/proofisionalityimages/tarining.jpg";
import instructor from "../../assets/Images/proofisionalityimages/instructor.jpg";
import self from "../../assets/Images/proofisionalityimages/self.jpg";
import testmonial from "../../assets/Images/proofisionalityimages/testmonial.jpg";
import { SiPowerbi } from "react-icons/si";
import { FaLaptop } from "react-icons/fa";
import { BiLogoPython } from "react-icons/bi";
import { VscGraph } from "react-icons/vsc";
import { PiMedal } from "react-icons/pi";
import {
  AiOutlineFilePdf,
  AiFillPieChart,
  AiOutlineFileWord,
} from "react-icons/ai";
import { FaVideo, FaJava, FaAws } from "react-icons/fa";
import { GrCloudSoftware } from "react-icons/gr";
import { SiMicrosoftazure } from "react-icons/si";
import gallery1 from "../../assets/Images/professionalImage/gallery2.svg";
import { TbCloudComputing } from "react-icons/tb";
import { VscAzureDevops } from "react-icons/vsc";
import ittrain from "../../assets/Images/proofisionalityimages/ittrain.jpg";
import hands from "../../assets/Images/proofisionalityimages/hands.jpg";
import timing from "../../assets/Images/proofisionalityimages/timing.jpg";
import fee from "../../assets/Images/proofisionalityimages/fee.jpg";
import lab from "../../assets/Images/proofisionalityimages/lab.png";
import interview from "../../assets/Images/proofisionalityimages/interview.png";
import professionaltrainingPdf from "../../assets/Images/professionalImage/ProfessionalTraining 1.pdf";
import professionaltext from "../../assets/Images/professionalImage/professioalonline.svg";
import professionaltext1 from "../../assets/Images/professionalImage/professionaltraining.svg";
import professionalcircle from "../../assets/Images/professionalImage/profeesioalcircle.png";
import choosetext1 from "../../assets/Images/professionalImage/whychoose.svg";
import NavigationBar from "../../common/NavigationBar";
import "./Professional.css";

const ProfessionalTraining = () => {
  useEffect(() => {
    const parallax = document.getElementById("profissional-banner-parallax");

    const handleScroll = () => {
      let offset = window.pageYOffset;
      parallax.style.backgroundPositionY = offset * -0.4 + "px";
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {/* banner start */}
      <div
        className="profissional-banner-section"
        id="profissional-banner-parallax"
      >
        <NavigationBar />

        <div className="h-screen flex items-center">
          <div className="text-white px-5 md:px-10 lg:px-20">
            <h1 className="text-2xl sm:text-3xl font-semibold leading-[60px]">
              PROFESSIONAL
            </h1>
            <h1 className="text-3xl sm:text-3xl text-[#00FFCA] md:text-4xl lg:text-4xl font-semibold mt-2">
              TRAINING
            </h1>
            <p className="w-full w-60 sm:w-80 md:w-96 mt-4">
              Empower Your Career with Professional Training Excellence
            </p>
            <Link to="/digitalwebinar">
              <button
                style={{
                  background:
                    "linear-gradient(0deg, #0A4D68 8.68%, #05BFDB 140.45%)",
                  transition: "all 0.5s",
                }}
                className="text-white rounded-[4px] px-10 py-2 mt-4 home-hover-button1"
              >
                Explore
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* banner end */}

      {/* second part start */}
      <div className=" hidden  md:block  pointer-events-none ">
        <img src={professionaltext} className="w-[100%] h-auto" />
      </div>

      {/* for mobile */}

      <div className="block md:hidden  mt-8  ">
        <div className="flex justify-center">
          <img src={professionaltext1} className="w-3/4 h-3/4" />
        </div>
      </div>

      <div className="md:hidden grid grid-cols-2    gap-3 mx-10  mt-5 ">
        <div>
          <h1
            className="text-[#0A4D68]   text-lg font-bold "
            style={{ fontFamily: "Poppins" }}
          >
            On-Premise Classroom Training
          </h1>
        </div>

        <div>
          <div class="flex  justify-center items-center">
            <div className="w-4 h-4 border-t-4 border-l-4 border-[#00AFC9] transform -rotate-45"></div>
            <div className="border-t-4 border-dashed border-[#00AFC9] w-[50%]   "></div>
            <img src={professionalcircle} className="w-[30%] h-auto" />
          </div>
        </div>

        <div className="mt-4">
          <h1
            className="text-[#0A4D68]   text-lg font-bold "
            style={{ fontFamily: "Poppins" }}
          >
            Instructor-Led Live, Online Training
          </h1>
        </div>

        <div className="mt-4">
          <div class="flex  justify-center items-center">
            <div className="w-4 h-4 border-t-4 border-l-4 border-[#00AFC9] transform -rotate-45"></div>
            <div className="border-t-4 border-dashed border-[#00AFC9] w-[50%]   "></div>
            <img src={professionalcircle} className="w-[30%] h-auto" />
          </div>
        </div>

        <div className="mt-4">
          <h1
            className="text-[#0A4D68]   text-lg font-bold "
            style={{ fontFamily: "Poppins" }}
          >
            Self-Paced Online Training
          </h1>
        </div>

        <div className="mt-4">
          <div class="flex  justify-center items-center">
            <div className="w-4 h-4 border-t-4 border-l-4 border-[#00AFC9] transform -rotate-45"></div>
            <div className="border-t-4 border-dashed border-[#00AFC9] w-[50%]   "></div>
            <img src={professionalcircle} className="w-[30%] h-auto" />
          </div>
        </div>

        <div className="mt-4">
          <h1
            className="text-[#0A4D68]   text-lg font-bold "
            style={{ fontFamily: "Poppins" }}
          >
            Comprehensive Courses
          </h1>
        </div>

        <div className="mt-4">
          <div class="flex  justify-center items-center">
            <div className="w-4 h-4 border-t-4 border-l-4 border-[#00AFC9] transform -rotate-45"></div>
            <div className="border-t-4 border-dashed border-[#00AFC9] w-[50%]   "></div>
            <img src={professionalcircle} className="w-[30%] h-auto" />
          </div>
        </div>
      </div>

      {/* second-part end */}

      {/* third-part start */}

      <div className="mt-12 md:mt-20">
        <h3 className=" font-bold text-2xl text-center">
          TRENDING CERTIFICATION COURSES
        </h3>

        <div className="grid grid-cols-1 lg:grid-cols-3  gap-10  mx-10 md:mx-20 lg:mx-28 mt-14 text-black">
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <FaAws className="w-20 h-auto text-center text-white rounded-lg bg-blue-500 " />

              <h3 className="font-bold text-2xl mt-5 ">AWS TRAINING</h3>

              <p className="text-gray-600 text-xl mt-5">
                AWS Training will make you become an expert in Various Aspects
                of AWS Cloud computing Roles Such as Cloud Practitioner,
                Architect, Developer with Live Projects Projects..
              </p>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <BiLogoPython className="w-20 h-auto p-4   text-center text-white rounded-lg bg-blue-500" />

              <h3 className="font-bold text-2xl my-6">PYTHON TRAINING</h3>

              <p className="text-gray-600 text-xl">
                Python Training will make you an expert in Python Programming
                Language, equipping you to become a Professional Python
                Developer with 10+ Real-Time Projects. hands-on projects
              </p>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <FaJava className="w-20 h-auto p-4   text-center text-white rounded-lg bg-blue-500" />

              <h3 className="font-bold text-2xl my-6">JAVA TRAINING</h3>

              <p className="text-gray-600 text-xl">
                "Java Certification Training Course will help you become an
                expert in Core Java, J2EE, Spring, Hibernate, and Struts,
                empowering you with comprehensive knowledge and practical skills
                ."
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 mx-10 md:mx-20 lg:mx-28 mt-14 text-black ">
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <AiFillPieChart className="w-20 h-auto p-4 text-center text-white rounded-lg bg-blue-500" />

              <h3 className="font-bold text-2xl my-6">
                FULL STACK DEVELOPER TRAINING
              </h3>

              <p className="text-gray-600 text-xl">
                Unlock your potential with Full Stack Developer Training and
                master both front-end and back-end development. Get hands-on
                experience in web development, databases, and Join us to build
                dynamic
              </p>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <GrCloudSoftware className="w-20 h-auto p-4 text-center text-white rounded-lg bg-blue-500" />

              <h3 className="font-bold text-2xl my-6">
                SOFTWARE TESTING TRAINING
              </h3>

              <p className="text-gray-600 text-xl">
                Elevate your career with Software Testing Training and become a
                skilled quality assurance professional. Learn the art of finding
                bugs and ensuring software reliability through comprehensive
                {/* // testing methodologies.

        Join us to master the essential skills needed to excel in Software Testing and secure your place in the tech industry. */}
              </p>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <SiPowerbi className="w-20 h-auto p-4 text-center text-white rounded-lg bg-blue-500" />

              <h3 className="font-bold text-2xl my-6">POWER BI TRAINING</h3>

              <p className="text-gray-600 text-xl mt-12">
                Power BI Certification Training empowers you to become an expert
                in developing DAX Formulas, creating Data Models for Data
                Analysis, and mastering the art of visualizing insights for
                informed decision-making.
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3  gap-10  mx-10 md:mx-20 lg:mx-28 mt-14  sm:pt-20 text-black mt-10">
          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <SiMicrosoftazure className="w-20 h-auto p-4   text-center text-white rounded-lg bg-blue-500" />

              <h3 className="font-bold text-2xl my-6">
                MICROSOFT AZURE TRAINING
              </h3>

              <p className="text-gray-600 text-xl">
                Azure Training will make you pass the Certification exams Azure
                Administrator (AZ-104) and Azure Architect Design (AZ-304)
              </p>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <TbCloudComputing className="w-20 h-auto p-4   text-center text-white rounded-lg bg-blue-500" />

              <h3 className="font-bold text-2xl my-6">
                CLOUD COMPUTING COURSE
              </h3>

              <p className="text-gray-600 text-xl">
                Cloud Computing Courses in will help you become an expert in
                AWS, AZURE & Google Cloud Platform, empowering you to harness
                the full potential
                {/* technology for your career and projects. Discover limitless possibilities in the cloud! */}
              </p>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-2xl">
            <div className="p-8">
              <VscAzureDevops className="w-20 h-auto p-4   text-center text-white rounded-lg bg-blue-500" />

              <h3 className="font-bold text-2xl my-6">DEVOPS TRAINING</h3>

              <p className="text-gray-600 text-xl">
                DevOps Training Course helps to explore your skills in DevOps
                process, methodologies, and tools like Git, Docker, Puppet,
                Nagios, Chef, Jenkins, Ansible, and Kubernetes.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* third-part end */}

      {/* fourth-part start */}

      <div className="flex justify-center mt-14 md:mt-20">
        <img src={choosetext1} className="w-[80%] h-auto  md:w-3/4 md:h-3/4 " />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mx-10 md:mx-20 lg:mx-36  ">
        <div className=" mt-5 md:mt-8">
          <p className=" text-justify text-[#5C5C5C]">
            A Choice that makes a big difference in your career. Gravitonweb
            Technologies is committed to helping its students to reach their
            goals and their training experiences, by providing the innovative
            surroundings and faculties. With our industry-leading instructors
            and hands-on training approach, we empower you to excel in the
            ever-evolving tech landscape. Join us today to unlock your full
            potential and secure a successful future in technology.
          </p>
          <Link to="/contact">
          <button
              style={{
                background:
                  "linear-gradient(0deg, #0A4D68 8.68%, #05BFDB 140.45%)",
                transition: "all 0.5s",
              }}
              className="text-white rounded-[4px] px-10 py-2 mt-5 md:mt-10 home-hover-button1 "
            >
              Contact Us
            </button>
            </Link>
        </div>

        <div className=" flex justify-center">
            <img
              src={gallery1}
              alt=""
              className="w-full h-auto md:w-[50%] md:h-auto"
            />
          </div>
      </div>



 

      <div className="">
      <div className="text-[#000000] italic text-2xl font-bold   flex justify-center text-center mt-10 md:mt-20">    
            “If you want to give the best technology experience to your esteemed employees, we are here”
          </div>

        <div className="text-white">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 mx-4 md:mx-10 lg:mx-16 mt-6 md:mt-12">
            {/* IT EXPERTS AS TRAINERS */}

            <div className="bg-white rounded-xl shadow-2xl p-4">
              <img
                className="max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl mx-auto"
                src={ittrain}
                alt=""
              />

              <h5 className="font-bold text-center text-black mt-5">
                IT EXPERTS AS TRAINERS
              </h5>

              <p className="text-gray-600 text-xl text-center mt-3">
                Learning a technology with a professional who is well expertise
                in that solve 60% of your needs.
              </p>
            </div>

            {/* FULLY HANDS-ON TRAINING */}

            <div className="bg-white rounded-xl shadow-2xl p-4">
              <img
                className="max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl mx-auto"
                src={hands}
                alt=""
              />

              <h5 className="font-bold text-center text-black mt-5">
                FULLY HANDS-ON TRAINING
              </h5>

              <p className="text-gray-600 text-xl text-center mt-2">
                We support any training with more practical classes. So, we
                always prefer to give hands-on training.
              </p>
            </div>

            {/* FLEXIBLE TIMINGS */}

            <div className="bg-white rounded-xl shadow-2xl p-4">
              <img
                className="max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl mx-auto"
                src={timing}
                alt=""
              />

              <h5 className="font-bold text-center text-black mt-5 ">
                FLEXIBLE TIMINGS
              </h5>

              <p className="text-gray-600 text-xl text-center mt-2">
                We started with 2 trainers, now we are 100+ and it’s still
                increasing. So we can give the students flexibility timings.
              </p>
            </div>

            {/* AFFORDABLE FEES */}

            <div className="bg-white rounded-xl shadow-2xl p-4">
              <img
                className="max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl mx-auto"
                src={fee}
                alt=""
              />

              <h5 className="font-bold text-center text-black mt-5">
                AFFORDABLE FEES
              </h5>

              <p className="text-gray-600 text-xl text-center mt-2">
                We are dead cheap in fees. Quality training with less price is
                only at Besant Technologies.
              </p>
            </div>

            {/* LAB SUPPORT */}

            <div className="bg-white rounded-xl shadow-2xl p-4">
              <img
                className="max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl mx-auto"
                src={lab}
                alt=""
              />

              <h5 className="font-bold text-center text-black mt-5">
                LAB SUPPORT
              </h5>

              <p className="text-gray-600 text-xl text-center mt-2">
                If you need software assistance we are here to back you up.
                Bring your laptop and load the required software and learn.
              </p>
            </div>

            {/* INTERVIEW PREPARATION */}

            <div className="bg-white rounded-xl shadow-2xl p-4">
              <img
                className="max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl mx-auto"
                src={interview}
                alt=""
              />

              <h5 className="font-bold text-center text-black mt-5">
                INTERVIEW PREPARATION
              </h5>

              <p className="text-gray-600 text-xl text-center mt-2">
                Every course in covered with interview point questions and
                real-time scenarios of what the company may look after from you.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* five end */}

      {/* sixth start */}

      <div name="support" className="w-full  ">
        <h4 className=" font-bold text-2xl px-2 pt-20 text-center">
          We Offer Three Types Of Online Training
        </h4>

        <p className="text-center mt-10">
          Get Hands-On Knowledge with Real Time Projects from this Online
          Training Courses. We are rated as “Best Online Training Course
          Provider” <br />
          from India with Placement guidance. Enroll for Free Courses & Get
          Sample Self-Paced Videos on Trending Technologies
        </p>

        <div className="w-[100%] h-auto  text-white mt-10 ">
          <div className="grid grid-cols-1 lg:grid-cols-3  gap-10  mx-10 md:mx-20 lg:mx-28 mt-14    text-black">
            <div className="bg-white rounded-xl shadow-2xl">
              <div className="p-8">
                <FaLaptop className="w-20 h-auto p-4   text-center text-white rounded-lg bg-blue-500 " />

                <p className="text-gray-600 text-xl mt-10">
                  Live Instructor-Led Online Courses with World Class
                  Experienced Trainers for you
                </p>
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-2xl">
              <div className="p-8">
                <VscGraph className="w-20 h-auto p-4   text-center text-white rounded-lg bg-blue-500" />

                <p className="text-gray-600 text-xl mt-10">
                  Learn from Basis to Advanced level in Latest Technologies.
                </p>
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-2xl">
              <div className="p-8">
                <AiOutlineFileWord className="w-20 h-auto p-4   text-center text-white rounded-lg bg-blue-500 " />

                <p className="text-gray-600 text-xl mt-10">
                  Resume Building & Mock Interviews for Students | 100+ Hours
                  In-Depth Online Courses & Sample Projects
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3  gap-10  mx-10 md:mx-20 lg:mx-28 mt-14  text-black mt-10">
            <div className="bg-white rounded-xl shadow-2xl">
              <div className="p-8">
                <FaVideo className="w-20 h-auto p-4   text-center text-white rounded-lg bg-blue-500 " />

                <p className="text-gray-600 text-xl mt-10">
                  Best Job Oriented Practical Training & 100% Placement Training
                  | The Complete Self-Paced Videos to Understand Better.
                </p>
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-2xl">
              <div className="p-8">
                <AiOutlineFilePdf className="w-20 h-auto p-4   text-center text-white rounded-lg bg-blue-500 " />

                <p className="text-gray-600 text-xl mt-10">
                  Quality & Unique Course Content Prepared by Industry Experts.
                  | Demo Live Project s for Practice on Real-Time scenario.
                </p>
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-2xl">
              <div className="p-8">
                <PiMedal className="w-20 h-auto p-4   text-center text-white rounded-lg bg-blue-500 " />

                <p className="text-gray-600 text-xl mt-10">
                  200+ Online Batches Completed & 10000+ Learners around the
                  world.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* sixth end */}

      {/* download-button */}
      <div className="mt-10 mb-10 text-center  ">
        <a
          href={professionaltrainingPdf}
          download="ProfessionaltrainingBouchers.pdf"
          className="bg-gradient-to-r from-teal-800 to-blue-500 px-5 py-2 md:px-10 md:py-3
        rounded-[8px] text-white font-bold text-xl md:text-2xl"
        >
          <button>Download PDF</button>
        </a>
      </div>
    </>
  );
};

export default ProfessionalTraining;
