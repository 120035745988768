import React, { useState, useEffect } from "react";
import logo from "./imgs/logo.png";
import logo1 from "./imgs/logo1.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Scrollup from "../Modules/Componants/Scrollup";

const NavigationBar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isBusinessOpen, setIsBusinessOpen] = useState(false);
  const [isTrainingOpen, setIsTrainingOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const closeDropdowns = () => {
    setIsBusinessOpen(false);
    setIsTrainingOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 40) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Scroll to the top when the route changes

    window.scrollTo(0, 0);
  }, [location.pathname]);

  window.addEventListener("scroll", function () {
    const firstButton = document.querySelector(".first-dropdown-button");
    const secondButton = document.querySelector(".second-dropdown-button");
    const thirdButton = document.querySelector(".third-dropdown-button");

    if (window.scrollY > 0) {
      firstButton.style.color = "black";
      secondButton.style.color = "black";
      thirdButton.style.color = "black";
    } else {
      firstButton.style.color = "white";
      secondButton.style.color = "white";
      thirdButton.style.color = "white";
    }
  });

  return (
    <>
      <nav
        className={`fixed top-0 left-0 right-0 z-50 ${
          isScrolled
            ? "bg-white text-black shadow-b-lg px-5 py-2 sm:px-10 sm:py-2 md:px-20 md:py-2"
            : "bg-transparent text-white px-5 py-5  md:px-10 md:py-5 lg:px-20 lg:py-5"
        }`}
      >
        <Scrollup />
        <div className="flex justify-between items-center">
          <Link to="/">
            {isScrolled ? (
              <img className="w-25 h-10 cursor-pointer" src={logo} alt="" />
            ) : (
              <img
                className="w-25 h-10 cursor-pointer"
                src={logo1} // Replace with the path to your first logo
                alt=""
              />
            )}
          </Link>

          <div className="lg:hidden">
            {/* Hamburger Icon */}

            <button
              className="p-2 focus:outline-none third-dropdown-button"
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
          </div>

          {/* Drawer */}

          {isDrawerOpen && (
            <div className="lg:hidden absolute top-0 right-0 h-screen w-64 bg-blue-300 text-white p-4 shadow-md">
              <button
                className="text-white p-2 focus:outline-none absolute top-2 right-2"
                onClick={() => setIsDrawerOpen(false)}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>

              <ul className="space-y-3">
                <li>
                  <Link
                    to="/"
                    className=" hover:text-blue-500"
                    onClick={() => setIsDrawerOpen(false)}
                  >
                    Home
                  </Link>
                </li>

                <li>
                  <Link
                    to="/about"
                    className=" hover:text-blue-500"
                    onClick={() => setIsDrawerOpen(false)}
                  >
                    About
                  </Link>
                </li>

                <li>
                  <Link
                    to="/services"
                    className="hover:text-blue-500"
                    onClick={() => setIsDrawerOpen(false)}
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/talentgravity"
                    className="hover:text-blue-500"
                    onClick={() => setIsDrawerOpen(false)}
                  >
                    Talent Gravity
                  </Link>
                </li>

                <li>
                  <button
                    className="text-white hover:text-blue-500 focus:outline-none"
                    onClick={() => {
                      setIsBusinessOpen(!isBusinessOpen);
                      setIsTrainingOpen(false);
                    }}
                  >
                    <Link
                      // to="/GrowYourBusiness"
                      className="hover:text-blue-500"
                      onClick={() => setIsDrawerOpen(false)}
                    >
                      Grow Your Business
                    </Link>

                    {isBusinessOpen ? (
                      <svg
                        className="w-4 h-4 inline ml-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        className="w-4 h-4 inline ml-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        ></path>
                      </svg>
                    )}
                  </button>

                  {isBusinessOpen && (
                    <div className="ml-2">
                      {/* Add content for Grow Your Business dropdown */}

                      <ul className="mt-2 space-y-1">
                        <p>
                          <Link
                            to="/digitalGravity"
                            className="hover:text-blue-500"
                            onClick={() => setIsDrawerOpen(false)}
                          >
                            Digital Gravity
                          </Link>
                        </p>
                        <p>
                          <Link
                            to="/digitalwebinar"
                            className="hover:text-blue-500"
                            onClick={() => setIsDrawerOpen(false)}
                          >
                            Digital Webinar
                          </Link>
                        </p>

                        <p>
                          {/* <Link to="/neuroweb"
                          className="text-white hover:text-blue-500"
                          onClick={() => setIsDrawerOpen(false)}>
                         Grow Your business
                        </Link> */}

                          <a
                            href="https://neuro.gravitonweb.com/"
                            onClick={() => closeDropdowns()}
                          >
                            {" "}
                            Grow Your Business
                          </a>
                        </p>
                      </ul>
                    </div>
                  )}
                </li>

                <li>
                  <button
                    className="text-white hover:text-blue-500 focus:outline-none"
                    onClick={() => {
                      setIsTrainingOpen(!isTrainingOpen);

                      setIsBusinessOpen(false);
                    }}
                  >
                    <Link
                      to="/Training"
                      className="hover:text-blue-500"
                      onClick={() => setIsDrawerOpen(false)}
                    >
                      Training
                    </Link>

                    {isTrainingOpen ? (
                      <svg
                        className="w-4 h-4 inline ml-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        className="w-4 h-4 inline ml-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        ></path>
                      </svg>
                    )}
                  </button>

                  {isTrainingOpen && (
                    <div className="ml-2">
                      {/* Add content for Training dropdown */}

                      <ul className="mt-2 space-y-1">
                        <p>
                          <Link
                            to="/webTalentGravity"
                            className="hover:text-blue-500"
                            onClick={() => setIsDrawerOpen(false)}
                          >
                            WebTalentGravity
                          </Link>
                        </p>

                        <p>
                          <Link
                            to="/professionalTraining"
                            className="hover:text-blue-500"
                            onClick={() => setIsDrawerOpen(false)}
                          >
                            Professional Training
                          </Link>
                        </p>

                        <p>
                          <Link
                            to="/certificationProgram"
                            className="hover:text-blue-500"
                            onClick={() => setIsDrawerOpen(false)}
                          >
                            Certification Program
                          </Link>
                        </p>

                        <p>
                          <Link
                            to="/placementAssuranceProgram"
                            className="hover:text-blue-500"
                            onClick={() => setIsDrawerOpen(false)}
                          >
                            Placement Assurance Program
                          </Link>
                        </p>

                        <p onClick={() => closeDropdowns()}>
                          <Link
                            to="/aiAccelerate"
                            className="hover:text-blue-500"
                            onClick={() => setIsDrawerOpen(false)}
                          >
                            AI Accelerate
                          </Link>
                        </p>
                      </ul>
                    </div>
                  )}
                </li>

                <li onClick={() => closeDropdowns()}>
                  <Link
                    to="/contact"
                    className="hover:text-blue-500"
                    onClick={() => setIsDrawerOpen(false)}
                  >
                    Contact
                  </Link>
                </li>
              </ul>

              <div className="mt-4">
              <Link
                    to="/contact">
                <button
                  className="rounded-[4px] px-5 py-2 font-semibold"
                  style={{
                    background:
                      "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
                  }}
                >
                  Get Started
                </button>
                </Link>
              </div>
            </div>
          )}

          {/* for desktop */}

          <ul className="hidden lg:flex text-md font-medium space-x-6">
            <li>
              <Link
                to="/"
                className="hover:text-blue-500"
                onClick={() => closeDropdowns()}
              >
                Home
              </Link>
            </li>

            <li>
              <Link
                to="/about"
                className="hover:text-blue-500"
                onClick={() => closeDropdowns()}
              >
                About
              </Link>
            </li>

            <li>
              <Link
                to="/services"
                className="hover:text-blue-500"
                onClick={() => closeDropdowns()}
              >
                Services
              </Link>
            </li>


            <li>
                  <Link
                    to="/talentgravity"
                    className="hover:text-blue-500"
                    onClick={() => setIsDrawerOpen(false)}
                  >
                    Talent Gravity 
                  </Link>
                </li>

            <li className="relative">
              <Link
                // to="/GrowYourBusiness"
                className="hover:text-blue-500"
                onClick={() => closeDropdowns()}
              >
                Grow Your Business
              </Link>

              <button
                className="first-dropdown-button"
                onClick={() => {
                  setIsBusinessOpen(!isBusinessOpen);
                  setIsTrainingOpen(false);
                }}
              >
                <svg
                  className={` h-3 ml-2 transition-transform transform ${
                    isBusinessOpen ?"rotate-180": "rotate-0" 
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>

              {isBusinessOpen && (
                <ul className="absolute top-10 right-0 bg-white text-black   border shadow-lg rounded-bl-lg rounded-br-lg  text-center text-sm">
                  <p className="border-b-2 p-3">
                    <Link
                      to="/digitalGravity"
                      className="text-black text-blue-500"
                      onClick={() => closeDropdowns()}
                    >
                      Digital Gravity
                    </Link>
                  </p>
                  <p className="border-b-2 p-3">
                    <Link
                      to="/digitalwebinar"
                      className="text-black hover:text-blue-500"
                      onClick={() => closeDropdowns()}
                    >
                      Digital Webinar
                    </Link>
                  </p>

                  <p className=" p-3">
                    {/* <Link to="/business/neuroweb"
                   className="text-black hover:text-blue-500"
                   onClick={() => closeDropdowns()}>
                   Grow your Business
                   </Link> */}

                    <a
                      href="https://neuro.gravitonweb.com/"
                      onClick={() => closeDropdowns()}
                    >
                      {" "}
                      Grow Your Business
                    </a>
                  </p>
                </ul>
              )}
            </li>

            <li className="relative">
              <Link
                to="/Training"
                className="hover:text-blue-500"
                onClick={() => closeDropdowns()}
              >
                Training
              </Link>

              <button
                className="second-dropdown-button"
                onClick={() => {
                  setIsTrainingOpen(!isTrainingOpen);
                  setIsBusinessOpen(false);
                }}
              >
                <svg
                  className={` h-3 ml-2  transition-transform transform ${
                    isTrainingOpen ? "rotate-180":"rotate-0"
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>

              {isTrainingOpen && (
                <ul className="absolute top-10 ml-[-35px] bg-white   text-white border shadow-lg rounded-bl-lg rounded-br-lg  text-center text-sm  ">
                  <p className="border-b-2 p-3">
                    <Link
                      to="/webTalentGravity"
                      className="text-black text-blue-500 "
                      onClick={() => closeDropdowns()}
                    >
                      WebTalentGravity
                    </Link>
                  </p>

                  <p className="border-b-2 p-3">
                    <Link
                      to="/professionalTraining"
                      className="text-black hover:text-blue-500   p-2  "
                      onClick={() => closeDropdowns()}
                    >
                      ProfessionalTraining
                    </Link>
                  </p>

                  <p className="border-b-2 p-3">
                    <Link
                      to="/certificationProgram"
                      className="text-black hover:text-blue-500  "
                      onClick={() => closeDropdowns()}
                    >
                      Certification Program
                    </Link>
                  </p>

                  <p className="border-b-2 p-3">
                    <Link
                      to="/placementAssuranceProgram"
                      className="text-black hover:text-blue-500"
                      onClick={() => closeDropdowns()}
                    >
                      Placement Assurance Program
                    </Link>
                  </p>

                  <p className=" p-3">
                    <Link
                      to="/aiAccelerate"
                      className="text-black hover:text-blue-500"
                      onClick={() => closeDropdowns()}
                    >
                      AI Accelerate
                    </Link>
                  </p>
                </ul>
              )}
            </li>

            <li>
              <Link
                to="/contact"
                className="hover:text-blue-500"
                onClick={() => closeDropdowns()}
              >
                Contact
              </Link>
            </li>
          </ul>

          <div className="hidden lg:flex justify-center text-white ">
          <Link
                    to="/contact">
            <button
              className="rounded-[4px] px-5 py-2 font-semibold"
              style={{
                background:
                  "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
              }}
            >
              Get Started
            </button>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavigationBar;
