import React from "react";
import { Link } from "react-router-dom";
import NavigationBar from "../../common/NavigationBar";
import TalentgravityPdf from "../../assets/Images/talentgravityimg/Talentgravitypath 1.pdf";
import "./Talentgravity.css";


const Talentgravity = () => {
  return (
    <>
      <div className="talent-banner-section">
        <NavigationBar />
      </div>

      <div className="mt-5 md:mt-28 ">
        <h1 className="text-center text-xl md:text-3xl lg:text-4xl font-bold ">
          What We <span className="text-[#14b1bb]">Provided ?</span>
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3   gap-5 md:gap-10  mx-10 md:mx-20 lg:mx-24  mt-5 md:mt-10">
        <div
          className="  rounded-tl-2xl rounded-bl-2xl rounded-br-2xl
                shadow-2xl p-5 md:p-8"
        >
          <h1 class="font-bold text-3xl text-[#05BFDB]">01</h1>
          <h1 class="text-[#262566] text-2xl font-semibold mt-4 ">
            PROFESSIONAL TRAINING
          </h1>

          <p className="mt-3 text-lg text-[#525252] text-justify">
            Professional training provides crucial skills, knowledge, and
            networking opportunities, enabling career growth. It equips
            individuals with industry-specific expertise, enhancing
            employability and adaptability in today's competitive job
            market.....
            <Link to="/professionalTraining" className="text-blue-500">
              read more
            </Link>
          </p>
        </div>

        <div
          className=" rounded-tl-2xl rounded-bl-2xl rounded-br-2xl
                shadow-2xl p-8"
        >
          <h1 class="font-bold text-3xl text-[#05BFDB]">02</h1>
          <h1 class="text-[#262566] text-2xl font-semibold mt-4 ">
            LOGICAL PRACTICE
          </h1>

          <p className="mt-3 text-lg text-[#525252] text-justify">
            Logical practice, a vital component of professional training, hones
            critical thinking, problem-solving, and decision-making abilities.
            It fosters a methodical and analytical approach, making individuals
            more effective and efficient in their careers.
          </p>
        </div>

        <div
          className=" rounded-tl-2xl rounded-bl-2xl rounded-br-2xl
                shadow-2xl p-8"
        >
          <h1 class="font-bold text-3xl text-[#05BFDB]">03</h1>
          <h1 class="text-[#262566] text-2xl font-semibold mt-4 ">
            TRAINING SUPPORT
          </h1>

          <p className="mt-3 text-lg text-[#525252] text-justify">
            Training support is crucial, offering guidance, resources, and
            mentorship, ensuring that individuals receive comprehensive
            assistance throughout their professional development journey,
            maximizing their chances for success....
            <Link to="/training" className="text-blue-500">
              read more
            </Link>
          </p>
        </div>

        <div
          className="  rounded-tl-2xl rounded-bl-2xl rounded-br-2xl
                shadow-2xl p-5 md:p-8"
        >
          <h1 class="font-bold text-3xl text-[#05BFDB]">04</h1>
          <h1 class="text-[#262566] text-2xl font-semibold mt-4 ">
            LIVE PROJECT WORKSHOP
          </h1>

          <p className="mt-3 text-lg text-[#525252] text-justify">
            Live projects, integrated into training, provide hands-on
            experience, allowing individuals to apply their learning to
            real-world scenarios. They bridge the gap between theory and
            practical application, enhancing skills and readiness for the
            professional world.
          </p>
        </div>

        <div
          className="  rounded-tl-2xl rounded-bl-2xl rounded-br-2xl
                shadow-2xl p-5 md:p-8"
        >
          <h1 class="font-bold text-3xl text-[#05BFDB]">05</h1>
          <h1 class="text-[#262566] text-2xl font-semibold mt-4 ">
            AI ACCELERATE
          </h1>

          <p className="mt-3 text-lg text-[#525252] text-justify">
            AI acceleration, a cutting-edge aspect of training, leverages
            artificial intelligence to enhance learning outcomes and deliver
            personalized, efficient education, enabling rapid skill acquisition
            and professional growth....
            <Link to="/aiAccelerate" className="text-blue-500">
              read more
            </Link>
          </p>
        </div>

        <div
          className="  rounded-tl-2xl rounded-bl-2xl rounded-br-2xl
                shadow-2xl p-5 md:p-8"
        >
          <h1 class="font-bold text-3xl text-[#05BFDB]">06</h1>
          <h1 class="text-[#262566] text-2xl font-semibold mt-4 ">
            PLACEMENT ASSUARANCE
          </h1>

          <p className="mt-3 text-lg text-[#525252] text-justify">
            Placement assurance, a key feature of training, guarantees job
            opportunities upon successful program completion. It provides
            confidence and security in a candidate's career prospects, ensuring
            a smooth transition into the workforce....
            <Link to="/placementAssuranceProgram" className="text-blue-500">
              read more
            </Link>
          </p>
        </div>
      </div>
           {/* download-button */}
           <div className="mt-10 mb-10 text-center  ">
        <a
          href={TalentgravityPdf}
          download="TalentgravityBouchers.pdf"
          className="bg-gradient-to-r from-teal-800 to-blue-500 px-5 py-2 md:px-10 md:py-3
        rounded-[8px] text-white font-bold text-xl md:text-2xl"
        >
          <button>Download PDF</button>
        </a>
      </div>
    </>
  );
};

export default Talentgravity;
