import React from "react";
import { Link } from "react-router-dom";
import NavigationBar from "../../common/NavigationBar";
import business2 from "../../assets/Images/feedbackImgs/business2.png";
import { BiSend } from "react-icons/bi";
import top from "../../assets/Images/feedbackImgs/top.png";
import bottom from "../../assets/Images/feedbackImgs/bottom.svg";

export default function Feedback2() {
  return (
    <>
      <div class="relative">
        <NavigationBar />
        <img
          src={top}
          alt="Background"
          className="w-full h-auto fixed top-0 left-0"
        />
        <img
          src={bottom}
          alt=""
          className="w-full h-auto fixed bottom-0 left-0 object-cover"
        />

        <div className="absolute inset-0 gap-0 lg:gap-28 grid grid-cols-1 md:grid-cols-2 mx-5 md:mx-20 lg:mx-48 mt-24 md:mt-28">
          <div className="shadow-lg rounded-lg bg-white py-10 px-10">
            <h2 className="text-xl md:text-2xl text-center font-medium text-[#05BFDB]">
              Your Feedback is important to us
            </h2>
            <p className=" text-center">We are happy to hear your feedback</p>
            <form className="">
              <div className="">
                <h3 class="mt-2 font-semibold text-gray-900 text-center">
                  What do you think of the course content?
                </h3>
                <ul class="items-center sm:flex mt-3">
                  <li class="w-full">
                    <div class="flex items-center mr-2  pl-3 bg-gray-200">
                      <input
                        id="poor-checkbox-list"
                        type="checkbox"
                        value=""
                        class="w-4 h-4 "
                      />
                      <label
                        for="poor-checkbox-list"
                        class="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        Poor
                      </label>
                    </div>
                  </li>
                  
                  <li class="w-full">
                    <div class="flex items-center pl-3 bg-gray-200 mr-2">
                      <input
                        id="Average-checkbox-list"
                        type="checkbox"
                        value=""
                        class="w-4 h-4"
                      />
                      <label
                        for="Average-checkbox-list"
                        class="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        Average
                      </label>
                    </div>
                  </li>

                  <li class="w-full">
                    <div class="flex items-center pl-3 bg-green-500 mr-2">
                      <input
                        id="Excellent-checkbox-list"
                        type="checkbox"
                        value=""
                        class="w-4 h-4"
                      />
                      <label
                        for="Excellent-checkbox-list"
                        class="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        Excellent
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="mt-4">
                <h3 class="mt-2 font-semibold text-gray-900 text-center">
                  Are you satisfied with the Interface?
                </h3>
                <ul class="items-center sm:flex mt-3">
                  <li class="w-full">
                    <div class="flex items-center mr-2  pl-3 bg-gray-200">
                      <input
                        id="poor-checkbox-list"
                        type="checkbox"
                        value=""
                        class="w-4 h-4"
                      />
                      <label
                        for="poor-checkbox-list"
                        class="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        Poor
                      </label>
                    </div>
                  </li>

                  <li class="w-full ">
                    <div class="flex items-center pl-3 bg-yellow-500 mr-2">
                      <input
                        id="Average-checkbox-list"
                        type="checkbox"
                        value=""
                        class="w-4 h-4"
                      />
                      <label
                        for="Average-checkbox-list"
                        class="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        Average
                      </label>
                    </div>
                  </li>

                  <li class="w-full">
                    <div class="flex items-center pl-3 bg-gray-200 mr-2">
                      <input
                        id="Excellent-checkbox-list"
                        type="checkbox"
                        value=""
                        class="w-4 h-4"
                      />
                      <label
                        for="Excellent-checkbox-list"
                        class="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        Excellent
                      </label>
                    </div>
                  </li>

                </ul>
              </div>


              <div className="mt-4">
                <h3 class="mt-2 font-semibold text-gray-900 text-center">
                  &lt;&lt; &nbsp;&nbsp;Question 3&nbsp; &nbsp;&gt;&gt;
                </h3>
                <ul class="items-center sm:flex mt-3">
                  <li class="w-full">
                    <div class="flex items-center mr-2 pl-3 bg-red-500">
                      <input
                        id="poor-checkbox-list"
                        type="checkbox"
                        value=""
                        class="w-4 h-4"
                      />
                      <label
                        for="poor-checkbox-list"
                        class="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        Poor
                      </label>
                    </div>
                  </li>

                  <li class="w-full">
                    <div class="flex items-center pl-3 bg-gray-200 mr-2">
                      <input
                        id="Average-checkbox-list"
                        type="checkbox"
                        value=""
                        class="w-4 h-4"
                      />
                      <label
                        for="Average-checkbox-list"
                        class="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        Average
                      </label>
                    </div>
                  </li>

                  <li class="w-full">
                    <div class="flex items-center pl-3 bg-gray-200 mr-2">
                      <input
                        id="Excellent-checkbox-list"
                        type="checkbox"
                        value=""
                        class="w-4 h-4"
                      />
                      <label
                        for="Excellent-checkbox-list"
                        class="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                      >
                        Excellent
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="mt-4">
                <textarea
                  name="message"
                  placeholder="Write your message..."
                  className="w-full px-3 py-2 border mt-[12px] rounded bg-[#F5F5F5]"
                  rows="3"
                />
              </div>

              <div className="text-center">
                <Link to="/feedback3">
                <button
                  style={{
                    background:
                    "linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%)",
                  color: "#ffffff",
                  borderRadius: "4px",
                  padding: "10px 0",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  }}
                  className="text-white w-full text-2xl hover:shadow-md mt-4"
                >
                  Send
                  <BiSend className="w-8 h-8 ml-2" />
                </button>
                </Link>
              </div>
            </form>
          </div>

          <div className="hidden md:block ">
              <img src={business2}  className="w-[75%] h-auto " alt="" />
            </div>
        </div>
      </div>
    </>
  );
}
