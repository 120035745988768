import React from "react";
import NavigationBar from "../../common/NavigationBar";
import img02 from "../../assets/Images/AboutImage/hero-bg.jpg";

export default function Faqs() {
  return (
    <>
    {/* banner start */}
    <div className=" w-full h-64 md:h-96 lg:h-96 relative">
    <img
      className="object-cover w-full h-64 md:h-96 lg:h-96"
      src={img02}
      alt=""
    />

    <div className="absolute inset-0 bg-[#2c2927]  bg-opacity-75" />
    <NavigationBar />
    <div className="absolute inset-0 flex flex-col justify-center items-center">
      <p className="text-xl md:text-2xl lg:text-5xl font-semibold text-white">
        Faq's
      </p>
    </div>
  </div>

     {/* banner end */}
      <h1 className="text-xl md:text-2xl lg:text-4xl mx-10 md:mx-16 lg:mx-20 font-bold mt-10 text-[#262566]">
        Frequently Asked Questions (FAQ)
      </h1>

      <div className="mx-10 md:mx-16 lg:mx-20 mt-10">
        <h1 className=" text-xl font-medium">
          1. What is GravitonWeb Technology?
        </h1>

        <p className="text-medium mt-3">
          GravitonWeb Technology is a specialized training provider that offers
          courses and resources to help individuals and professionals learn and
          master frontend development and backend development skills, including
          HTML, CSS, JavaScript,ReactJs, NodeJs, ExpressJs and more.
        </p>
      </div>

      <div className="mx-10 md:mx-16 lg:mx-20 mt-5">
        <h1 className=" text-xl font-medium">
          2. Who can benefit from your courses?
        </h1>

        <p className="text-medium mt-3">
          Our courses are designed for beginners as well as those with some
          prior experience in frontend development. Whether you're looking to
          start a career in web development or enhance your existing skills, our
          courses can be tailored to meet your needs.
        </p>
      </div>

      <div className="mx-10 md:mx-16 lg:mx-20 mt-5">
        <h1 className=" text-xl font-medium mt-3">
          3. What courses do you offer?
        </h1>

        <h1 className="text-xl mt-3 font-normal">
          We offer a range of courses, including:
        </h1>

        <ul>
          <li className="mt-3 text-medium ml-4 list-disc">
            HTML and CSS Fundamentals.
          </li>

          <li className="text-medium ml-4 list-disc">
            JavaScript for Frontend Development
          </li>

          <li className="text-medium ml-4 list-disc">Responsive Web Design.</li>

          <li className="text-medium ml-4 list-disc">
            Frameworks and Libraries (e.g., React, Angular, Vue.js)
          </li>

          <li className="text-medium ml-4 list-disc">Advanced Frontend Topics.</li>
        </ul>
      </div>

      <div className="mx-10 md:mx-16 lg:mx-20 mt-5">
        <h1 className=" text-xl font-medium">
          4. How do I enroll in a course?
        </h1>

        <p className="text-medium mt-3">
          To enroll in a course, simply visit our website, select the course
          you're interested in, and follow the enrollment instructions. You'll
          need to create an account if you haven't already.
        </p>
      </div>

      <div className="mx-10 md:mx-16 lg:mx-20 mt-5">
        <h1 className=" text-xl font-medium">
          5. Can I access course materials anytime, or are there specific
          schedules?
        </h1>

        <p className="text-medium mt-3">
          Most of our courses are self-paced, allowing you to access course
          materials and complete assignments at your own convenience. However,
          some courses may have set start and end dates, so be sure to check the
          course details before enrolling.
        </p>
      </div>

      <div className="mx-10 md:mx-16 lg:mx-20 mt-5">
        <h1 className=" text-xl font-medium">
          6. Do you offer certification upon course completion?
        </h1>

        <p className="text-medium mt-3">
          Yes, we provide certificates of completion for many of our courses.
          These certificates can be a valuable addition to your resume and
          showcase your skills to potential employers.
        </p>
      </div>

      <div className="mx-10 md:mx-16 lg:mx-20 mt-5">
        <h1 className=" text-xl font-medium">
          7. How can I contact your support team?
        </h1>

        <p className="text-medium mt-3">
          You can reach our support team by visiting the info@gravitonweb.com
          page on our website. We're here to help with any questions or concerns
          you may have.
        </p>
      </div>
    </>
  );
}
