import React from 'react';
import "./Technologies.css";

export default function Technologies() {
  return (
    <>
    
      <div className="services-technologies">
        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/bootstrap.jpg"
            alt="bootstrap"
            className="services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title pt-2">Bootstrap</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/wordpress.png"
            alt="WordPress"
            className=" services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title pt-2">WordPress</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/woo-commerce.png"
            alt="wooCommerce"
            className=" services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title">WooCommerce</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/codeigniter.png"
            alt="codeihniter"
            className=" services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title pt-1">CodeIgniter</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/magento.png"
            alt="magento"
            className=" services-technologies-image"
            loading="lazy"
          />
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/python.jpg"
            alt="python"
            className=" services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title">Python</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/backbone.png"
            alt="backbone"
            className=" services-technologies-image"
            loading="lazy"
          />
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/materlize.png"
            alt="materialize css"
            className=" services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title">Materialize CSS</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/vue-js.png"
            alt="vue js"
            className=" services-technologies-image"
            loading="lazy"
          />
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/angular.png"
            alt="angular js"
            className=" services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title pt-1">Angular JS</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/joomla.png"
            alt="joomla"
            className="services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title pt-1">Joomla</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/react.png"
            alt="react js"
            className=" services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title pt-2">React JS</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/php.png"
            alt="php"
            className=" services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title pt-1">PHP</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/tailwind.png"
            alt="tailwind"
            className="services-technologies-image"
            loading="lazy"
          />

        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/sql.png"
            alt="mysql"
            className=" ls-is-cached services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title">MySQL</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/jquery.png"
            alt="jQuery"
            className=" services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title">jQery</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/ajax.jpg"
            alt="ajax"
            className="services-technologies-image"
            loading="lazy"
          />

          
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/javascript.png"
            alt="javascript"
            className=" services-technologies-image"
            loading="lazy"
          />

        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/css3.png"
            alt="cs3"
            className=" ls-is-cached services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title pt-2">CSS3</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/shopify.png"
            alt="shopify"
            className=" ls-is-cached services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title pt-2">Shopify</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/opencart.png"
            alt="opencart"
            className=" services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title pt-1">OpenCart</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/node-js.png"
            alt="node js"
            className=" services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title">Node JS</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/semantic.png"
            alt="semantic UI"
            className="services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title">Semantic UI</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/amp.png"
            alt="amp"
            className="services-technologies-image"
            loading="lazy"
          />

        </div>

        <div className="services-technologies-item">
          <img
            style={{ maxHeight: 70 }}
            src="https://www.webomindapps.com/images/technologies/bulma.png"
            alt="bulma"
            className="services-technologies-image"
            loading="lazy"
          />
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/illustrator.png"
            alt="illustrator"
            className="services-technologies-image"
            loading="lazy"
          />
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/uikit.png"
            alt="uikit"
            className="services-technologies-image"
            loading="lazy"
          />
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/g-apis.png"
            alt="google api"
            className=" ls-is-cached services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title pt-1">Google API's</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/xml.png"
            alt="xml"
            className="services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title">XML</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/json.png"
            alt="JSON"
            className="services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title pt-3">JSON</div>
        </div>

        <div className="services-technologies-item">
          <img
            src="https://www.webomindapps.com/images/technologies/web-service.png"
            alt="web services"
            className=" services-technologies-image"
            loading="lazy"
          />

          <div className="services-technologies-title">Web Services</div>
        </div>
      </div>

    </>
  )
}
