import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavigationBar from "../../common/NavigationBar";
import headingText from "../../assets/Images/TrainingImgs/secondtextheading.svg";
import arrow from "../../assets/Images/TrainingImgs/arrow.png";
import trainingmain from "../../assets/Images/TrainingImgs/secondrightimg.svg";
import trainingthirdImage from "../../assets/Images/TrainingImgs/trainingthirdImage.png";
import trainingblock from "../../assets/Images/TrainingImgs/trainingblockimage1.png";
import trainingblockimage2 from "../../assets/Images/TrainingImgs/trainingblockimage2.png";
import trainingblockimage3 from "../../assets/Images/TrainingImgs/trainingblockimage3.png";
import background from "../../assets/Images/TrainingImgs/trainingfiveimg.png";
import "./Training.css";
import { server } from "../../env/env";

const Training = () => {
  const [values, setValue] = useState({
    name: "",
    textarea: "",
    email: "",
    phone: "",
  });

  function inputHandle(event) {
    const newObj = { ...values, [event.target.name]: event.target.value };
    setValue(newObj);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    var formdata = new FormData();
    formdata.append("firstname", values.name);
    formdata.append("email", values.email);
    formdata.append("phone", values.phone);
    formdata.append("message", values.textarea);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(server.prod + "main/AiAccelerateContactData/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert("success Fully Regsiterd ");
      })
      .catch((error) => console.log("error", error));

    setValue({
      name: "",
      email: "",
      phone: "",
      textarea: "",
    });
  };

  //

  useEffect(() => {
    const parallax = document.getElementById("training-banner-parallax");

    const handleScroll = () => {
      let offset = window.pageYOffset;

      parallax.style.backgroundPositionY = offset * -0.4 + "px";
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* banner start */}

      <div className="training-banner-section" id="training-banner-parallax">
        <NavigationBar />

        <div className="flex flex-col justify-center items-center h-screen">
          <div className="text-white">
            <h1 className="leading-[60px] text-xl sm:text-2xl md:text-4xl lg:text-5xl font-semibold  text-center">
              Transforming Ambitions Into
            </h1>

            <h1 className="text-xl sm:text-2xl md:text-4xl lg:text-5xl font-semibold mt-2 text-center">
              Expertise:{" "}
              <span className="text-[#05BFDB] text-xl sm:text-2xl md:text-2xl lg:text-5xl text-center">
                GravitonWeb Tech Training
              </span>
            </h1>

            <p className=" mt-4 text-center w-[300px] sm:w-auto">
              Empowering Futures through Cutting-Edge Technology Training
            </p>

            <div className="flex justify-center items-center mt-8">
              <Link to='/digitalwebinar'>
              <button
                style={{
                  background:
                    "linear-gradient(0deg, #0A4D68 8.68%, #05BFDB 140.45%)",

                  transition: "all 0.5s",
                }}
                className="text-white justify-center rounded-[4px] px-10 py-2 home-hover-button1"
              >
                Explore
              </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* banner end */}

      {/* second */}

      <div className=" flex justify-center">
        <img src={headingText} alt="" className="w-3/4" />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mx-5 md:mx-14 lg:mx-20 mt-10 ">
        <div className=" ">
          <p>
            Join our comprehensive Frontend, Backend, and Full Stack Web
            Development Program at Graviton Web Technologies. This program
            equips you with the skills and knowledge to excel in web
            development.
          </p>

          <div className="flex items-center mt-10 ">
            <img src={arrow} alt="" className="w-20 mr-2" />

            <span className="font-bold">HTML</span>
          </div>

          <div className="flex items-center">
            <img src={arrow} alt="" className="w-20 mr-2 mt-1" />

            <span className="font-bold">CSS</span>
          </div>

          <div className="flex items-center">
            <img src={arrow} alt="" className="w-20 mr-2 mt-1" />

            <span className="font-bold">JavaScript</span>
          </div>

          <div className="flex items-center">
            <img src={arrow} alt="" className="w-20 mr-2 mt-1" />

            <span className="font-bold">React</span>
          </div>

          <div className="flex items-center">
            <img src={arrow} alt="" className="w-20 mr-2 mt-1" />

            <span className="font-bold">Redux</span>
          </div>

          <div className="flex items-center">
            <img src={arrow} alt="" className="w-20 mr-2 mt-1" />

            <span className="font-bold">Ant Design</span>
          </div>

          <div className="flex items-center">
            <img src={arrow} alt="" className="w-20 mr-2 mt-1" />

            <span className="font-bold">Libraries</span>
          </div>

          <div className="flex items-center">
            <img src={arrow} alt="" className="w-20 mr-2 mt-1 " />

            <span className="font-bold">VCS</span>
          </div>

          <div className="flex items-center">
            <img src={arrow} alt="" className="w-20 mr-2 mt-1" />

            <span className="font-bold">Deployment</span>
          </div>
        </div>

        <div>
          <img
            src={trainingmain}
            alt=""
            className=" w-[100%] md:w-[400px]  lg:ml-[215px] "
          />
        </div>
      </div>

      {/* second */}

      {/* third */}

      <div class="min-w-screen min-h-screen bg-[#05BFDB4A] flex items-center justify-center px-5 py-5 mt-10">
        <div
          class=" bg-white rounded-3xl shadow-xl w-full overflow-hidden"
          style={{ maxWidth: "1000px" }}
        >
          <div class=" w-full md:flex ">
            <div class="hidden md:block w-1/2 bg-[#0A4D68] py-10 px-10 ">
              <img
                src={trainingthirdImage}
                alt=""
                class="w-full h-auto mt-20"
              />
            </div>

            <div class="w-full md:w-1/2 p-5 ">
              <form>
              <h1 className="font-bold text-xl">
                QUERY ? HAVE A CONSULATION WITH US ?
              </h1>

              <div class="mt-5">
                <input
                  type="text"
                  id="name"
                  className=" w-full  px-5 py-2  border rounded-[4px] border-gray-400 "
                  placeholder="Name"
                  onChange={inputHandle}
                  name="name"
                  value={values.name}
                />
              </div>

              <div class="mt-5">
                <input
                  type="text"
                  id="email"
                  className="w-full  px-5 py-2 border rounded-[4px] border-gray-400 "
                  placeholder="Email"
                  onChange={inputHandle}
                  name="email"
                  value={values.email}
                />
              </div>

              <div class="mt-5">
                <input
                  type="text"
                  id="pnum"
                  className=" w-full  px-5 py-2 border rounded-[4px] border-gray-400 "
                  placeholder="Phone"
                  onChange={inputHandle}
                  name="phone"
                  value={values.phone}
                />
              </div>

              <div class="mt-5">
                <textarea
                  placeholder="Message"
                  rows="6"
                  className="w-full  px-5 py-2  border rounded-[4px] border-gray-400"
                  onChange={inputHandle}
                  name="textarea"
                  value={values.textarea}
                />
              </div>

              <div class="mt-5 flex justify-center">
                <button
                  type="submit"
                  className=" px-5 py-2 text-black  border rounded-[4px] border-gray-400"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* third */}

      {/* fourth */}

      <div className="mt-5 md:mt-10">
        <h1 className="text-center text-xl md:text-4xl font-bold  ">
          COURSES <span className="text-[#0A4D68] font-bold">WE OFFER</span>
        </h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3  gap-10  mx-10 md:mx-20 lg:mx-28 mt-5 md:mt-10">
        <div className="p-8 border border-[#05BFDB] rounded-lg cursor-pointer hover:bg-[#B5EDF4] hover:bg-opacity-16">
         <div className="flex justify-center">
         <img src={trainingblock} alt="" />
         </div>

          <h3 className="font-bold text-xl mt-4 text-center text-gray-600  ">
            Front-end Development
          </h3>

          <p className="text-gray-600 mt-3 text-center">
            Create stunning and interactive card grids with our Frontend
            Development program. Master HTML, CSS, and JavaScript for visually
            appealing web designs
          </p>
        </div>

        <div className="p-8 border border-[#05BFDB] rounded-lg cursor-pointer hover:bg-[#B5EDF4]  hover:bg-opacity-16">
         
        <div className="flex justify-center">
          <img src={trainingblockimage2} alt="" />
        </div>

          <h3 className="font-bold text-xl mt-4 text-center text-gray-600">
            Back-end Development
          </h3>

          <p className="text-gray-600 text-center mt-3">
            Master Python as a server-side programming language and databases to
            build robust and scalable web or server-side applications.
          </p>
        </div>

        <div className="p-8 border border-[#05BFDB] rounded-lg cursor-pointer hover:bg-[#B5EDF4]  hover:bg-opacity-16">
          
        <div className="flex justify-center">
          <img src={trainingblockimage3} alt="" />
        </div>

          <h3 className="font-bold text-xl mt-5 text-gray-600 text-center mt-12">
            Full-Stack Development
          </h3>

          <p className="text-gray-600 text-center mt-4">
            Become a versatile developer skilled in both frontend and backend
            technologies, capable of creating end-to-end web solutions.
          </p>
        </div>
      </div>
      {/* fourth*/}

      <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-6 bg-[#14b1bb] text-white mt-5 md:mt-32 ">
        <div className="col-span-1 flex justify-center">
          <img
            src={background}
            className="w-[50%] md:w-96 md:mt-[-100px] lg:mt-[-150px] sm:p-[47px]"
            alt=""
          />
        </div>

        <div className="">
          <h1 className="text-xl md:text-2xl lg:text-3xl font-semibold text-black pl-5 pr-5 lg:pr-5 lg:pl-0 pt-5 pb-10 lg:mt-[-70px] ">
            Get Enrolled And Secure Your Career Today
          </h1>

            <p className="pl-5 pr-5  lg:pr-5 lg:pl-0 mb-3">
              Our training program covers the essentials of full-stack
              development. In the Frontend module, you'll master HTML, CSS, and
              JavaScript for visually appealing and user-friendly websites with
              responsive layouts, dynamic content, and optimized performance.
              The Backend module focuses on server-side programming (Python,
              Ruby, or PHP) for building secure, scalable server applications
              and working with databases. In the Full Stack module, you'll
              integrate frontend and backend technologies to architect complete
              web applications, becoming a versatile developer capable of
              end-to-end solutions.
            </p>
      
        </div>
      </div>
    </>
  );
};

export default Training;
